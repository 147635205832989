/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {get, immutable_set} from "../../../utils";
import {getRelatedResultsData} from "../../../services/relatedResultsService";
import {handleError, handleErrorStatuses} from "../../../utils/internal-error-handler";
import {GENERIC_ERROR_MESSAGE, RELATED_RESULTS_ERROR_TYPE, UNEXPECTED_ERROR_MESSAGE} from "../../common/constants";
import {
  ACTIVE_STATUS, ERROR_STATUS, LOADING_DESCRIPTION,
  makeResponseLoadingAction, NONE_STATUS, RELATED_RESULTS_NAME
} from "../../response-loading/redux/response-loading-redux";

// Action constants
export const A = {
  SAVE_RELATED_RESULTS_DATA: 'SAVE_RELATED_RESULTS_DATA',
  SAVE_ACCORDION_STATE: 'SAVE_ACCORDION_STATE',
  CLEAR_RELATED_RESULTS_STATE: 'CLEAR_RELATED_RESULTS_STATE'
}

export function makeSaveRelatedResultsDataAction(data) {
  return {
    type: A.SAVE_RELATED_RESULTS_DATA,
    data
  }
}

export function makeRelatedResultsClearStateAction() {
  return {
    type: A.CLEAR_RELATED_RESULTS_STATE
  }
}

export function makeSetRelatedResultsAccordionOpenState(isRelatedResultsAccordionOpen) {
  return {
    type: A.SAVE_ACCORDION_STATE,
    isRelatedResultsAccordionOpen
  }
}

export function saveRelatedResultsDataReducer(current_state = {}, action = {}) {
  return immutable_set(
    current_state,
    'relatedResults',
    action.data
  )
}

export function saveRelatedResultsAccordionOpenStateReducer(current_state = {}, action = {}) {
  return immutable_set(
    current_state,
    'isRelatedResultsAccordionOpen',
    action.isRelatedResultsAccordionOpen
  )
}

export function clearRelatedResultsStateReducer(current_state = {}, action = {}) {
  return {};
}

export function getRelatedResultsThunk(props) {
  return (dispatch, getState) => {
    let current_state = getState();
    let login_page = {};
    let drugName = '';
    const existingRelatedResults = get(current_state, 'related_results', '');
    const current_location = get(props, 'location.pathname');
    switch (current_location) {
      case "/inDepth":
        drugName = get(current_state, 'in_depth_page.drugName', '');
        break
      case "/drugPoints":
        drugName = get(current_state, 'drug_points_page.drugName', '');
        break
      default:
        break
    }
    if (current_state.login_page) {
      login_page = current_state.login_page;
    }
    if (existingRelatedResults) {
      const relatedResultsData = get(existingRelatedResults, 'relatedResults', '');
      if (relatedResultsData.drugName === drugName)
      {
        return current_state;
      }
    }
    dispatch(makeResponseLoadingAction(RELATED_RESULTS_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
    return getRelatedResultsData({drugName}, login_page)
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch(makeResponseLoadingAction(RELATED_RESULTS_NAME, ERROR_STATUS, UNEXPECTED_ERROR_MESSAGE));
          if (response.status === 401) {
            let mutable_props = {...props};
            mutable_props.error_type = RELATED_RESULTS_ERROR_TYPE
            handleErrorStatuses(response.status, mutable_props, dispatch, getState);
          }
          return {isError: true};
        }
      })
      .then(result => {
        dispatch(makeSaveRelatedResultsDataAction(result))
        dispatch(makeResponseLoadingAction(RELATED_RESULTS_NAME, NONE_STATUS, LOADING_DESCRIPTION));
      })
      .catch(error => {
        dispatch(makeResponseLoadingAction(RELATED_RESULTS_NAME, ERROR_STATUS, UNEXPECTED_ERROR_MESSAGE));
        handleError({props, message: GENERIC_ERROR_MESSAGE});
        console.error("An error occurred : %s", error);
      })
  }
}

export function related_results(
  current_state = {},
  action = {}
) {
  switch (action.type) {
    case A.SAVE_RELATED_RESULTS_DATA:
      return saveRelatedResultsDataReducer(current_state, action);
    case A.SAVE_ACCORDION_STATE:
      return saveRelatedResultsAccordionOpenStateReducer(current_state, action);
    case A.CLEAR_RELATED_RESULTS_STATE:
      return clearRelatedResultsStateReducer(current_state, action);
    default:
      return current_state;
  }
}
