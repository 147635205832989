/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { createRef,Component } from 'react';
import { connect } from "react-redux";
import { Button } from "@carbon/react";
import { enhanced_get, getArray, get, util_alert, getDecodedURIComponents } from "../../../utils";

import {
    makeSetSearchTextAction,
    makeClearSelectedItemsAction,
    get_selected_items,
    makeSetInitalSelectionsThunk
} from "../../../components/ivpicklist/iv-picklist-redux";

import { IVFooter } from "../../../components/common/footers";

import {
    makeIvCompatSearchClearStateAction,
    makeIvCompatSearchGoToChatbotActionThunk,
    wordWheelIvDrugPicklistFetcher,
    wordWheeIvSolutionPicklistFetcher,
    IV_COMPATIBILITY_SEARCH_PAGE,
    IV_DRUG_PICKLIST_ID,
    IV_SOLUTION_PICKLIST_ID
} from './iv-compatibility-search-utils'


import IVPickList from "../../../components/ivpicklist/IVPickList";
import queryString from "query-string";
import CommonOverflowModalPopups from "../../../components/common-overflow-menu/CommonOverflowModalPopups";
import { handleError } from "../../../utils/internal-error-handler";
import { makeChatClearStateAction } from "../../ChatbotPage/chatbot-utils";
import { resetFiltersState } from "../../../components/checkbox-filter/checkbox-filters-state";
import { CommonFooter } from "../../../components/common/footers/Footer";
import { GENERIC_ERROR_MESSAGE, IV_COMPATIBILITY_ERROR_TYPE } from "../../../components/common/constants";
import { isEmpty } from "lodash";
import { makeSaveSelectedItemsAction } from "../../../components/ivpicklist/iv-picklist-redux/iv-picklist-redux";
import {
  IV_DRUGS_WORD_WHEEL_NAME, IV_SOLUTIONS_WORD_WHEEL_NAME, WORD_WHEEL_GENERAL_NAME
} from "../../../components/response-loading/redux/response-loading-redux";
import { IV_COMPATIBILITY_DRUGS_SEARCH, IV_COMPATIBILITY_SOLUTIONS_SEARCH } from "../../../services/wordWheelService";

const mapStateToProps = state => {
    const state_results = {
        current_state: state.iv_compatibility_search_page,
        subscription_state: get(state, 'login_page.mdxAuthenticationJson', {}),
        iv_drug_picklist_state: get(
            state,
            IV_COMPATIBILITY_SEARCH_PAGE+'.'+IV_DRUG_PICKLIST_ID,
            {}
            ),
        iv_solution_picklist_state: get(
            state,
            IV_COMPATIBILITY_SEARCH_PAGE+'.'+IV_SOLUTION_PICKLIST_ID,
            {}
            ),
        iv_solution_data_cache: enhanced_get(
            state,
            [IV_COMPATIBILITY_SEARCH_PAGE,IV_SOLUTION_PICKLIST_ID,'iv_solution_data_cache']
        ),
        ivDrugsResponseLoading: get(state, `inline_loading.${IV_DRUGS_WORD_WHEEL_NAME}`, {}),
        ivSolutionsResponseLoading: get(state, `inline_loading.${IV_SOLUTIONS_WORD_WHEEL_NAME}`, {}),
        login_page: state.login_page
    }
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onDisplayIvCompatibility: (initial_url_params={}, props) => {
            let iv_drug_names = getDecodedURIComponents(getArray(initial_url_params, 'iv_drug_names'));
            let iv_solution_names = getDecodedURIComponents(getArray(initial_url_params, 'iv_solution_names'));
            let selected_iv_drugs = [];

            for (let i = 0; i < iv_drug_names.length; i++) {
                let drugObj = [iv_drug_names[i], '0'];
                selected_iv_drugs.push(drugObj);
            }
            let login_page = props.login_page;

            dispatch(
                makeSetInitalSelectionsThunk(
                    selected_iv_drugs,
                    () => wordWheelIvDrugPicklistFetcher('', login_page, dispatch, props),
                    IV_COMPATIBILITY_SEARCH_PAGE,
                    IV_DRUG_PICKLIST_ID,
                    IV_COMPATIBILITY_DRUGS_SEARCH + WORD_WHEEL_GENERAL_NAME,
                    props
                )).catch(
                error => {
                    dispatch(makeChatClearStateAction());
                    dispatch(makeIvCompatSearchClearStateAction());
                    util_alert(
                        "An error occurred. Please try again.",
                        IV_COMPATIBILITY_ERROR_TYPE,
                        dispatch
                    );
                })

            let selected_iv_solutions = [];

            for(let i = 0; i < iv_solution_names.length; i++) {
                let iv_solution_obj = [iv_solution_names[i],'0'];
                selected_iv_solutions.push(iv_solution_obj);
            }
            dispatch(
                makeSetInitalSelectionsThunk(
                    selected_iv_solutions,
                    () => wordWheeIvSolutionPicklistFetcher('', login_page, dispatch, props),
                    IV_COMPATIBILITY_SEARCH_PAGE,
                    IV_SOLUTION_PICKLIST_ID,
                    IV_COMPATIBILITY_SOLUTIONS_SEARCH + WORD_WHEEL_GENERAL_NAME,
                    props
                )).catch(
                error => {
                    dispatch(makeChatClearStateAction());
                    dispatch(makeIvCompatSearchClearStateAction());
                    handleError({props, message: GENERIC_ERROR_MESSAGE});
                    util_alert(
                        "An error occurred. Please try again.",
                        IV_COMPATIBILITY_ERROR_TYPE,
                        dispatch
                    );
                })
        },
        onGoToChatbotPage: (props) => {
            dispatch(makeIvCompatSearchGoToChatbotActionThunk(props));
        },
        onIvDrugPicklistSearchTextOnchange: (event,props) => {

            console.debug("$$$$$$$$$$$$$$$$$$$$$$$$$$$ event.target.value=",event.target.value)
            dispatch(
                makeSetSearchTextAction(
                    event.target.value, // current input text
                    IV_DRUG_PICKLIST_ID,
                    IV_COMPATIBILITY_SEARCH_PAGE
                ))
        },
        onIvSolutionPicklistSearchTextOnchange: (event, iv_solution_data_cache) => {

                dispatch(
                    makeSetSearchTextAction(
                        event.target.value, // current input text
                        IV_SOLUTION_PICKLIST_ID,
                        IV_COMPATIBILITY_SEARCH_PAGE
                    ))
        },
        onClearFilterItemsOnClick: () => {
            dispatch(makeClearSelectedItemsAction(IV_DRUG_PICKLIST_ID, IV_COMPATIBILITY_SEARCH_PAGE))
            dispatch(makeClearSelectedItemsAction(IV_SOLUTION_PICKLIST_ID, IV_COMPATIBILITY_SEARCH_PAGE))
        },
        onClearPicklists: () => {
            //call dispatch on the reducers which empty the search text for both dropdowns here
            dispatch(makeSetSearchTextAction('', "iv_drug_picklist", "iv_compatibility_search_page"));
            dispatch(makeSetSearchTextAction('', "iv_solution_picklist", "iv_compatibility_search_page"));
        },
        onSaveSelectedItems: (saved_url_params, picklist_id, page_name, props) => {
          const drug_ids = getArray(saved_url_params, 'iv_drug_ids');
          if (!isEmpty(drug_ids)) {
            saveSelectedItems(saved_url_params, 'iv_drug_names', drug_ids, picklist_id, page_name, dispatch)
          }
          const solution_ids = getArray(saved_url_params, 'iv_solution_ids');
          if (!isEmpty(solution_ids)) {
            saveSelectedItems(saved_url_params, 'iv_solution_names', solution_ids, picklist_id, page_name, dispatch)
          }
          if (isEmpty(drug_ids) && isEmpty(solution_ids)) {
            props.onClearFilterItemsOnClick();
          }
        },
        redux_dispatch_function: dispatch,
    }
}


export class IVCompatibilitySearchPagePresentation extends Component{
    initial_url_params = {}
    constructor(props) {
        super(props);
        console.debug('IVCompatibilitySearchPagePresentation CTOR props=', props)
        const loc_search = get(this.props,'location.search')
        if (loc_search) {
            this.initial_url_params = queryString.parse(loc_search)
            console.debug('CTOR initial_url_params=',this.initial_url_params)
        }
        this.scrollingDivRef = createRef()
        this.gotoChatbotPage = this.gotoChatbotPage.bind(this)
    }

    gotoChatbotPage() {
        this.props.onGoToChatbotPage(this.props);
    }
    ivCompatibilityMethod(
        iv_drug_picklist_state,
        iv_solution_picklist_state,
        props
    ){
        const iv_drug_items = get_selected_items(iv_drug_picklist_state)
        const iv_solution_items = get_selected_items(iv_solution_picklist_state)
        var s="";
        for (var i=0;i< iv_drug_items.length;i++)
        {
            const[item_name,item_id]=iv_drug_items[i];
            console.debug(iv_drug_items[i]);
            s+="&drug_ids="+item_id;
            s+="&iv_drug_names="+encodeURIComponent(item_name);
        }

        for (var j=0; j< iv_solution_items.length; j++) {
            const[item_name,item_id]=iv_solution_items[j];
            console.debug(iv_solution_items[j]);
            s+="&iv_solution_ids="+item_id;
            s+="&iv_solution_names="+encodeURIComponent(item_name);
        }

        console.debug(s);
        let linkUrl = `/ivCompatibility?${s}`;
        const linkURL_encoded = encodeURI(linkUrl);
        console.debug(linkURL_encoded);
        props.history.push(linkURL_encoded);
        resetFiltersState();
    }

    /**
     * When the react gui is displayed,
     * send the first drug points page information request
     */
    componentDidMount() {
        // if (!isEmptyObject(this.initial_url_params)) {
            const saved_url_params = this.initial_url_params
            this.initial_url_params = {}
            this.props.onSaveSelectedItems(saved_url_params, IV_DRUG_PICKLIST_ID,
              IV_COMPATIBILITY_SEARCH_PAGE, this.props)
            this.props.onDisplayIvCompatibility(
                saved_url_params,
                this.props
            )
        // }
        this.props.onClearPicklists();
    }

    render() {
        let selected_iv_drugs=get(this.props.iv_drug_picklist_state, 'selected_items',[]);
        let selected_iv_solutions=get(this.props.iv_solution_picklist_state, 'selected_items',[]);
        let isViewCompatibilityDisabled=false;
        if(selected_iv_drugs.length === 0){
            isViewCompatibilityDisabled=true;
        }
        let isClearAllDisabled = false;
        if (selected_iv_drugs.length === 0 && selected_iv_solutions.length === 0) {
          isClearAllDisabled = true;
        }
        return <div  ref={this.scrollingDivRef} className="main-container">
            <div  className="empty-container"></div>
            <div className="main-content">
            <div className="mdx-scroll-area iv-compatibility-search-page-container mdx-scroll-area-ie">
                <div className="mdx-header border-line">
                    <h1 className="search-and-results-page-titles iv-search-page-header heading-color heading-font">IV Compatibility Search</h1>
                </div>
                <p className="iv-search-page-info-message-top">Add at least one IV drug and tap View Compatibility.</p>
                <div className="mdx-inner-content">
                    <IVPickList
                        input_label= 'Drugs'
                        input_placeholder= "Find a Drug"
                        info_help_html= { `<div>
                            <ul>
                                <li>Tap on the IV Drugs search field.</li>
                                <li>Enter the first few letters of the generic or brand drug name.</li>
                                <li>Tap to add item.</li>
                                <li>Tap the Done button close selected list.</li>
                            </ul>
                    </div>`}
                        picklist_id= {IV_DRUG_PICKLIST_ID}
                        page_name={IV_COMPATIBILITY_SEARCH_PAGE}
                        search_text_onchange={(event) =>this.props.onIvDrugPicklistSearchTextOnchange(event, this.props)}
                        iv_picklist_state = {this.props.iv_drug_picklist_state}
                        redux_dispatch_function = {this.props.redux_dispatch_function}
                        test_for_duplicates_flag={false}
                        only_single_fetch_required={true}
                        responseLoading = {this.props.ivDrugsResponseLoading}
                    />
                    <IVPickList
                        input_label= 'Solutions *optional'
                        input_placeholder= "Find a Solution"
                        info_help_html= { `<div>
                            <ul>
                                <li>Tap on the IV Solution search field.</li>
                                <li>Enter the first few letters of the solution.</li>
                                <li>Tap to add item.</li>
                                <li>Tap the Done button close selected list.</li>
                            </ul>
                    </div>`}
                        picklist_id= {IV_SOLUTION_PICKLIST_ID}
                        page_name={IV_COMPATIBILITY_SEARCH_PAGE}
                        search_text_onchange={
                            (event) => this.props.onIvSolutionPicklistSearchTextOnchange(event, this.props.iv_solution_data_cache)
                        }
                        iv_picklist_state = {this.props.iv_solution_picklist_state}
                        redux_dispatch_function = {this.props.redux_dispatch_function}
                        test_for_duplicates_flag={false}
                        only_single_fetch_required={true}
                        responseLoading = {this.props.ivSolutionsResponseLoading}
                    />
                <div className="bottom-aln">
                    <Button className="secondary-button bottom-clear"
                        kind="tertiary"
                        disabled={isClearAllDisabled}
                        onClick={()=>this.props.onClearFilterItemsOnClick()}
                    >
                        Clear All
                    </Button>
                    <Button className="primary-button bottom-view"
                        kind="primary"
                        id="view-iv-compatibility"
                        disabled={isViewCompatibilityDisabled}
                        onClick={
                            ()=>this.ivCompatibilityMethod(
                                this.props.iv_drug_picklist_state,
                                this.props.iv_solution_picklist_state,
                                this.props
                            )
                        }
                    >
                        View Compatibility
                    </Button>
                </div>
                    <div>
                        <p className="iv-search-page-info-message-bottom">
                        Compatibility results for more than one drug will display in drug-drug pairs.
                        </p>
                    </div>

                
                <IVFooter styleName='iv-compatibility-search-footer'/>
                </div>
                <CommonFooter/>
                </div>
                </div>
                <CommonOverflowModalPopups/>
               
        </div>
    }

}

const saveSelectedItems = (url_params, path, ids, picklist_id, page_name, dispatch) => {
  let names = getArray(url_params, path);
  let selected_entities = [];
  for (let i = 0; i < ids.length; i++) {
    const entity = [decodeURIComponent(names[i]), ids[i]];
    selected_entities.push(entity);
  }
  dispatch(makeSaveSelectedItemsAction(selected_entities, picklist_id, page_name));
}

const IVCompatibilitySearchPage = connect(
    mapStateToProps,
    mapDispatchToProps
) (IVCompatibilitySearchPagePresentation)

export default IVCompatibilitySearchPage
