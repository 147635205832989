/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {PureComponent} from 'react'

// import PropTypes from 'prop-types'
import { SettingsAdjust, Close, Checkmark } from '@carbon/react/icons';
import {DefinitionTooltip, Tooltip} from "@carbon/react";

import {
    addEllipses,
    get
} from "../../utils";
import {
    is_pulldown_open,
    makeDeselectSelectedItemAction,
    makeSelectItemAction,
    makeDisplayDuplicateItemWarningTextAction,
    makeSetSearchTextAction,
    DATA_ARRAY_INDEX_INDEX,
    makeDeselectSelectedItemWithoutWordWheelDataAction
} from "./iv-picklist-redux/iv-picklist-redux";
import { InlineLoading } from '@carbon/react';
import {ERROR_STATUS} from "../response-loading/redux/response-loading-redux";
import { Information } from '@carbon/react/icons';
import { PrefixContext } from '@carbon/react';

const MAX_MENU_ITEM_LENGTH = 30 // TODO: ask Robert how long text should be before doing "..."

/**
 * Split a mixed case item_name using the search text
 * @param upper_search_text upper cased search text
 * @param mixed_name mixed case item_name to be split
 * @param upper_name_arg OPTIONAL; defaults to mixed_name.toUpperCase()
 * @returns {string[]|[]} array of mixed cased name snippets; the even entries
 * are mixed case versions of the search text and should be rendered in
 * bold in the picklist pulldown menu
 */
export function split_iv_menu_text(
    upper_search_text='',
    mixed_name='',
    upper_name_arg
) {
    // no split necessary if search text is empty
    const search_text_length = upper_search_text.length
    if (search_text_length===0) {
        return [mixed_name]
    }
    const mixed_name_length = mixed_name.length

    // the upper_name arg is optional
    const upper_name = (upper_name_arg===undefined)?mixed_name.toUpperCase():upper_name_arg

    // perform case insensitive split of mixed_name
    const upper_split_array = upper_name.split(upper_search_text)

    let name_position = 0
    let split_text_array = [] // function return value

    // for each uppercase snippet of the split
    for (let i=0;i<upper_split_array.length;i++) {
        const upper_snippet = upper_split_array[i]
        const snippet_length = upper_snippet.length

        // get the mixed case snippet from mixed_name that corresponds to the upper_snippet
        const mixed_snippet = mixed_name.substr(name_position, snippet_length)
        split_text_array.push(mixed_snippet)

        // bump name_position past the mixed_snippet in mixed_name
        name_position += snippet_length

        // if we haven't reached the end of the mixed_name,
        // then we need to add a snippet of the mixed case
        // version of the search_text
        if (name_position<mixed_name_length) {

            // get the mixed case version of the search_text
            const search_snippet = mixed_name.substr(name_position, search_text_length)

            // if search_text occurs consecutive times in the mixed_name,
            // then we need to concatenate each instance of the mixed search text
            // NOTE: see __tests__/split_iv_menu_text.test.js test cases for clarification
            if (i>0 && snippet_length===0) {
                // pop off the empty snippet and append this search snippet to the previous one
                split_text_array.pop()
                split_text_array[split_text_array.length-1] += search_snippet
            } else {
                // no consecutive search text found ...
                // just append the mixed case search_snippet to the output array
                split_text_array.push(search_snippet)
            }

            // bump name_position past the search_texts in mixed_name
            name_position += search_text_length
        } //endif
    } //endfor i

    return split_text_array
}

export  const PulldownItem = React.memo((
    {
        // true if this pulldown item should have a checkmark
        checked_flag=false,

        // array of mixed case item_name snippet strings;
        // odd entries are search_text snippets and should be made bold
        text_snippets=[],

        // click handler to select or deselect this pulldown item
        click_handler=f=>f,

        // index of this pulldown item within the overall pulldown menu
        index=0
    }
) => {
    let final_text=[]; // array of React components

    // TODO: do we want ellipses for long pulldown items? how to handle bolding of search text?

    const snippet_count = text_snippets.length
    // item_name snippets interleaved with search_text snippets
    for (let i=0;i<snippet_count;i+=2) {
        // text that should not be bolded ...
        const snippet = text_snippets[i]
        if (snippet!=='') {
            final_text.push(<span>{snippet}</span>)
        }

        // search text that should be bolded ...
        if (i+1<snippet_count) {
            final_text.push(<span style={{'fontWeight': 'bold'}}>{text_snippets[i+1]}</span>)
        }
    } //endfor i

    // TODO: how to display long_text on mobile devices which have no hover help
    return <div className="picklist-pulldown-menu-item" onClick={() => click_handler(index)}>
        <span className="picklist-menu-item-checkmark" >
            { checked_flag && <Checkmark/> }
        </span>
        {final_text}
    </div>
})

export const IVPulldownMenu = (
    {
        iv_picklist_state={},
        responseLoading = {},
        item_click_handler=f=>f,
        done_click_handler=f=>f
    }
) => {
    const pulldown_is_open = is_pulldown_open(iv_picklist_state)
    if (!pulldown_is_open) {
        return <div className="picklist-pulldown-menu-closed"/>
    }

    const {
        picklist_display_array=[],
        no_results_found_flag=false,
        checked_entry_flags=[],
        search_text=''
    } = iv_picklist_state
    let menu_contents = [];
    if (responseLoading.status) {
        menu_contents.push(<InlineLoading className="picklist-pulldown-data-loading" description={responseLoading.description} status={responseLoading.status}/>)
    } else if (no_results_found_flag===true) {
        menu_contents.push(<div key='no-results' className="picklist-pulldown-no-results">No results found.<br/>Please modify your search term.</div>)
    } else {
        const upper_search_text=search_text.toUpperCase();

        for (let i=0;i<picklist_display_array.length;i++) {
            const [mixed_name, , upper_name, data_array_index] = picklist_display_array[i]
            const snippet_array = split_iv_menu_text(
                upper_search_text,
                mixed_name,
                upper_name
            )
            menu_contents.push(
                <PulldownItem
                    key={data_array_index}
                    text_snippets={snippet_array}
                    checked_flag={checked_entry_flags[data_array_index] ? true : false}
                    click_handler={item_click_handler}
                    index={i}
                />)
        } //endfor i
    } //endelse no_results_found
    return  <div className="picklist-pulldown-menu-container">
    <div
        className="picklist-pulldown-menu-open"
    >
        <div className='picklist-pulldown-menu-scroll'>
            {menu_contents}
        </div>
        <div className='picklist-done'  onClick={done_click_handler}>Done</div>
    </div>
</div>
}

export const SelectedItem = React.memo((
    {
        item: [long_name='', document_id='', upper_name='', data_array_index],
        wordWheelLoadingStatus = '',
        click_handler=f=>f
    }
    ) => {
    const short_name = addEllipses(long_name, MAX_MENU_ITEM_LENGTH)
    const selectedItem = short_name.length < long_name.length
      ? <DefinitionTooltip definition={long_name} openOnHover={true}>
            <span id="tooltip" className="picklist-selected-item-text picklist-selected-item-big-text">{short_name}</span>
        </DefinitionTooltip>
      : <span className="picklist-selected-item-text">{short_name}</span>

    // TODO: how to display long_text on mobile devices which have no hover help
    return <div
        className="picklist-selected-item"
    >
      {selectedItem}

        <Close
            className="picklist-selected-item-image"
            onClick={() => click_handler(data_array_index, document_id, wordWheelLoadingStatus)}
        />
    </div>
})

export const SelectedItemsContainer = (
    {iv_picklist_state={}, item_click_handler=f=>f, wordWheelLoadingStatus = ''}
    ) => {
    const { selected_items =[]} = iv_picklist_state

    return <div className="iv-search-page-selected-items-container picklist-selected-item-container">
        {
            selected_items.map((item, index) => {
                return <SelectedItem
                    key={item[DATA_ARRAY_INDEX_INDEX]}
                    item={item}
                    click_handler={item_click_handler}
                    index={index}
                    wordWheelLoadingStatus={wordWheelLoadingStatus}
                    />
            })
        }

    </div>
}

const IVToolTip =({text,prefix})=>{
    return <Tooltip
        align="bottom-right"
        label={text}
        className="tooltip-container"
    >
        <button className={`${prefix}-tooltip-trigger`}>
            <Information />
        </button>
    </Tooltip>
}
export default class IVPickList extends PureComponent {
    input_label = 'Drugs'
    input_placeholder = 'Find a drug'
    info_help_html = `
    <div>
                            <ul>
                                <li>Tap on the Drug search field.</li>
                                <li>Enter the first few letters of the generic or brand drug name.</li>
                                <li>Tap to add item.</li>
                                <li>Tap Done.</li>
                            </ul>
                        </div>
    `
    word_wheel_fetch = f=>Promise.resolve()
    search_text_onchange=f=>f
    redux_dispatch_function=f=>f
    
    picklist_id = 'drug_picklist'
    page_name = ''

    clear_button_onclick = f=>f
    done_button_onclick = f=>f
    pulldown_onselect=f=>f
    deselect_item_onclick=f=>f
    test_for_duplicates_flag = false

    constructor(props) {
        super(props);
        this.input_label = props.input_label || this.input_label
        this.input_placeholder = props.input_placeholder || this.input_placeholder
        this.info_help_html = props.info_help_html || this.info_help_html
        this.word_wheel_fetch = props.word_wheel_fetch || this.word_wheel_fetch
        this.picklist_id = props.picklist_id || this.picklist_id
        this.page_name = props.page_name || this.page_name
        this.search_text_onchange = props.search_text_onchange || this.search_text_onchange
        this.redux_dispatch_function = props.redux_dispatch_function || this.redux_dispatch_function
        this.clear_button_onclick = props.clear_buttton_onclick || (() => this.redux_dispatch_function(makeSetSearchTextAction(
            '',
            this.picklist_id,
            this.page_name
        )))
        this.done_button_onclick = props.done_buttton_onclick || (() => {
            this.redux_dispatch_function(makeSetSearchTextAction(
                '',
                this.picklist_id,
                this.page_name
            ))
            // this.redux_dispatch_function(makeSetDataArrayAction(
            //     [],
            //     this.picklist_id,
            //     this.page_name
            // ))
        })

        this.pulldown_onselect = item_index => this.validateAndAddItem(item_index)
        this.deselect_item_onclick = (data_array_index, drug_id, wordWheelLoadingStatus) => {
            if (!data_array_index
                && drug_id
                && wordWheelLoadingStatus === ERROR_STATUS) {
                this.redux_dispatch_function(makeDeselectSelectedItemWithoutWordWheelDataAction(
                    drug_id,
                    this.picklist_id,
                    this.page_name
                ))
            } else {
                this.redux_dispatch_function(makeDeselectSelectedItemAction(
                    data_array_index,
                    this.picklist_id,
                    this.page_name
                ))
            }
        }
        this.test_for_duplicates_flag = props.test_for_duplicates_flag || this.test_for_duplicates_flag

        this.validateAndAddItem = this.validateAndAddItem.bind(this)
    }


    validateAndAddItem(item_index) {

        //TODO: Do we want to abstract validateAndAddItem such that it can vary between item types!?

        let selectedItems = this.props.iv_picklist_state.selected_items;
        let itemToAdd = this.props.iv_picklist_state.picklist_display_array[item_index]

        // for Drug Interactions, we want to detect drugs with different names but same drug_ids
        // for IV Compatibility drugs, we do not want to detect duplicates because only drug names are used
        if(this.test_for_duplicates_flag && this.itemIsInList(selectedItems, itemToAdd)) {
            //Add warning to renderer...
            let warningText = this.getWarningText(selectedItems, itemToAdd);
            this.redux_dispatch_function(makeDisplayDuplicateItemWarningTextAction(warningText, this.picklist_id, this.page_name));
        } else {
            this.redux_dispatch_function(makeSelectItemAction(
                item_index,
                this.picklist_id,
                this.page_name
            ))
        } //endelse
    }

    getWarningText(selectedItems = [], itemToAdd = []) {
        let warningText = '';

        for(let i = 0; i < selectedItems.length; i++) {
            let item = selectedItems[i];
            if(itemToAdd[1] === item[1] && itemToAdd[0] !== item[0]) {
                warningText += item[0];
            }
        }
        return warningText;
    }

    itemIsInList(selectedItems = [], itemToAdd = []) {
        for(let i = 0; i < selectedItems.length; i++) {
            let item = selectedItems[i];
            if(itemToAdd[1] === item[1] && itemToAdd[0] !== item[0])
                return true;
        }
        return false;
    }

    render() {
        // Note this is  the nested picklist state for the picklist instance on the page
        const iv_picklist_state = this.props.iv_picklist_state
        const pulldown_is_open = is_pulldown_open(iv_picklist_state)
        const {
            picklist_display_array
        } = iv_picklist_state

        const display_count = (!pulldown_is_open)?'':'('+picklist_display_array.length+')'
        let display_label = this.input_label
        const percent_index = display_label.indexOf('%')
        if (percent_index >=0 ) {
            display_label = display_label.replace('%', display_count)
        } else {
            display_label = display_label+' '+display_count
        }

        const tooltip_content = <div dangerouslySetInnerHTML={{__html: this.info_help_html}} />;
        const prefix = this.context;
        return (
            <div className="picklist-column-div" id={this.picklist_id}>
                <div key='input-label' className="picklist-label">{display_label}</div>
                <div data-floating-menu-container key='picklist-row' className='picklist-row-div tooltip-container iv-tooltip-container'>
                    <div key='input-wrapper' className="picklist-input-field-wrapper picklist-bg-color">
                        <SettingsAdjust
                            key='filter-icon'
                            className="picklist-settings-adjust-icon"
                        />
                        <input
                            key='input-field'
                            type="text"
                            spellCheck="false"
                            className="picklist-input-field picklist-bg-color"
                            placeholder={this.input_placeholder}
                            onChange={this.search_text_onchange}
                            value={get(iv_picklist_state,'search_text','')}
                        />
                        <button
                            key='clear-button'
                            className="picklist-close-button picklist-bg-color"
                            onClick={this.clear_button_onclick}
                        >
                            <Close
                                className="picklist-close-icon picklist-bg-color"
                            />
                        </button>
                    </div>
                    <div data-floating-menu-container className="picklist-tooltip-container tooltip-container">
                        <IVToolTip text={tooltip_content} prefix={prefix} />
                    </div>
                </div>
                <IVPulldownMenu
                    key='pulldown-menu'
                    iv_picklist_state={iv_picklist_state}
                    responseLoading={this.props.responseLoading}
                    item_click_handler={this.pulldown_onselect}
                    done_click_handler={ this.done_button_onclick }
                />
                <SelectedItemsContainer
                    key='selected-items'
                    iv_picklist_state={iv_picklist_state}
                    item_click_handler={this.deselect_item_onclick}
                    wordWheelLoadingStatus={this.props.responseLoading.status}
                />
            </div>
        );
    }
}
IVPickList.contextType = PrefixContext;
