/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import { get, set } from "./getset";
import queryString from 'query-string'
import { IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE } from "./commonIvDiUtils";

const PATHS_TO_APPEND_PARAMS = ['/drugInteraction', '/ivCompatibility'];
const DI_QUICK_ANSWERS = 'diQuickAnswers';

export const getQueryParameters = (queryParameters, newParameters = {}) => {
  const parsedQueryParameters = queryString.parse(queryParameters);
  Object.keys(newParameters).forEach(key => set(parsedQueryParameters, key, newParameters[key]));
  return queryString.stringify(parsedQueryParameters);
}

export const getUrlParametersNavigatingFromDrugMonograph = (topicId, props) => {
  return '&' + queryString.stringify({
    returnPage: DI_QUICK_ANSWERS,
    documentId: props.drugMonographDocumentId,
    drugName: props.drugMonographDrugName,
    topicId: topicId,
    inDepthLinkDocumentId: props.drugMonographInDepthId
  });
}

export const getUrlParameterFromSummaryPage = (currentLocationSearch, returnPage) => {
  const urlParameters = currentLocationSearch.includes("?&")
                            ? currentLocationSearch.replace("?", "")
                            : currentLocationSearch.replace("?", "&");
  
  return urlParameters + '&' + queryString.stringify({
                                  returnPage: returnPage
                                });
}

export const getPreviousPageLink = (currentUrlParameters, defaultUrl, props) => {
  const returnPage = get(currentUrlParameters, "returnPage", "");
  let savedUrlParameters = props.url_parameters;

  if (!savedUrlParameters) {
    const startParametersChar = '?'; 
    savedUrlParameters = startParametersChar.concat(
                            (returnPage === DI_QUICK_ANSWERS)
                                ? queryString.stringify(getDrugMonographReturnUrlParameters(currentUrlParameters, props))
                                : (returnPage === IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE)
                                    ? queryString.stringify(getIVSummaryPageReturnUrlParameters(currentUrlParameters))
                                    : queryString.stringify(getDISummaryPageReturnUrlParameters(currentUrlParameters)));
  }
  
  return returnPage === DI_QUICK_ANSWERS
    ? `/drugPoints${savedUrlParameters}`
    : PATHS_TO_APPEND_PARAMS.indexOf(defaultUrl) !== -1
      ? defaultUrl + savedUrlParameters
      : defaultUrl;
}

const getDrugMonographReturnUrlParameters = (currentUrlParameters, props) => {
  return {
    contentSetId: 100,
    documentId: get(currentUrlParameters, 'documentId', props.current_document_id),
    drugName: get(currentUrlParameters, 'drugName', props.drug_name),
    topicId: get(currentUrlParameters, 'topicId', props.topic_id),
    drugEvalsId: get(currentUrlParameters, 'inDepthLinkDocumentId', props.current_drugEvals_id)
  };
}

const getIVSummaryPageReturnUrlParameters = (currentUrlParameters) => {
  return {
    drug_ids: get(currentUrlParameters, 'drug_ids'),
    iv_drug_names: get(currentUrlParameters, 'iv_drug_names'),
    iv_solution_ids: get(currentUrlParameters, 'iv_solution_ids'),
    iv_solution_names: get(currentUrlParameters, 'iv_solution_names')
  };
}

const getDISummaryPageReturnUrlParameters = (currentUrlParameters) => {
  return {
    drug_ids: get(currentUrlParameters, 'drug_ids'),
    drug_names: get(currentUrlParameters, 'drug_names'),
    allergy_ids: get(currentUrlParameters, 'allergy_ids'),
    allergy_names: get(currentUrlParameters, 'allergy_names')
  };
}
