/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment,createRef,Component} from 'react';
import {connect} from "react-redux";
import { Button, ToastNotification } from "@carbon/react";
import {getArray, get, util_alert, getDecodedURIComponents} from "../../utils";
import {
  makeDrugInteractionSearchGoToChatbotActionThunk,
  makeChatClearStateAction,
  makeDrugInteractionSearchClearStateAction,
  makeDrugInteractionActionThunk,
} from "../../app-state-redux";
import {
  makePicklistInputThunk,
  makeManualSetSelectedThunk,
  makeClearSelectedItemsAction,
  get_selected_items,
  DRUGS_WORDWHEEL_ERROR,
  makeDisplayDuplicateItemWarningTextAction, makeSetSearchTextAction, makeSetDataArrayAction, makeSetFirstCharOfCurrentDrugAction
} from "../../components/picklist/picklist-redux";
import {
  wordWheelDrugPicklistFetcher,
  wordWheelAllergyPicklistFetcher,
  makeApplyNewFilterAction,
  makeShowFilterPanelAction,
  makeChangeInteractionObjectsAction
} from "./drug-interaction-utils";

import PickList from "../../components/picklist/PickList";
import {
  ALLERGY_PICKLIST,
  DRUG_INTERACTION_PAGE_NAME,
  DRUG_PICKLIST
} from "./drug-interaction-utils/drug-interaction-search-page-redux";
import queryString from "query-string";
import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";
import {handleError} from "../../utils/internal-error-handler";
import {getFilterObjectForDefinedDrugsAndAllergies} from "../../utils/commonIvDiUtils";
import {CommonFooter} from "../../components/common/footers/Footer";
import {makeSetInitalAllergiesPrefetchThunk} from "../../components/picklist/picklist-redux/picklist-redux";
import {makeDiShowUnableToDeleteMessageAction} from "./drug-interaction-utils/drug-interaction-page-redux";
import {GENERIC_ERROR_MESSAGE, DRUG_INTERACTION_ERROR_TYPE} from "../../components/common/constants";
import {
  DI_ALLERGIES_WORD_WHEEL_NAME,
  DI_DRUGS_WORD_WHEEL_NAME, WORD_WHEEL_GENERAL_NAME
} from "../../components/response-loading/redux/response-loading-redux";
import {DRUG_INTERACTION_ALLERGIES_SEARCH, DRUG_INTERACTION_DRUGS_SEARCH} from "../../services/wordWheelService";

const mapStateToProps = state => {
    const state_results = {
        current_state: state.drug_interaction_search_page,
        drug_picklist_state: get(state.drug_interaction_search_page, DRUG_PICKLIST,{}),
        allergy_picklist_state: get(state.drug_interaction_search_page, ALLERGY_PICKLIST,{}),
        login_page: state.login_page,
        filter_state: get(state, 'drug_interaction_page.filter_status', {}),
        diDrugsResponseLoaded: get(state, `inline_loading.${DI_DRUGS_WORD_WHEEL_NAME}`, {}),
        diAllergiesResponseLoaded: get(state, `inline_loading.${DI_ALLERGIES_WORD_WHEEL_NAME}`, {}),
    }
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onFixDupes: (new_drug_list) => {
            dispatch(makeManualSetSelectedThunk(new_drug_list, DRUG_INTERACTION_PAGE_NAME, DRUG_PICKLIST));
        },
        onDisplayInteraction: (initial_url_params={}, props) => {
            let drug_ids = getArray(initial_url_params, 'drug_ids');
            let drug_names = getDecodedURIComponents(getArray(initial_url_params, 'drug_names'));

            let allergy_ids = getArray(initial_url_params, 'allergy_ids');
            let allergy_names = getDecodedURIComponents(getArray(initial_url_params, 'allergy_names'));

            let selected_drugs = [];

            for(let i = 0; i < drug_ids.length; i++) {
                let drugObj = [drug_names[i], drug_ids[i]];
                selected_drugs.push(drugObj);
            }

            dispatch(makeManualSetSelectedThunk(selected_drugs, DRUG_INTERACTION_PAGE_NAME, DRUG_PICKLIST));

            let selected_allergies = [];

            for(let i = 0; i < allergy_ids.length; i++) {
                let allergyObj = [allergy_names[i], allergy_ids[i]];
                selected_allergies.push(allergyObj);
            }
            
            dispatch(makeManualSetSelectedThunk(selected_allergies, DRUG_INTERACTION_PAGE_NAME, ALLERGY_PICKLIST));

            dispatch(
              makeSetInitalAllergiesPrefetchThunk(
                [],
                () => wordWheelAllergyPicklistFetcher('', dispatch, props.login_page, props),
                DRUG_INTERACTION_PAGE_NAME,
                ALLERGY_PICKLIST,
                DRUG_INTERACTION_ALLERGIES_SEARCH + WORD_WHEEL_GENERAL_NAME,
                props
              )
            ).catch(error => {
              handleError({props, message: GENERIC_ERROR_MESSAGE, dispatch},
                makeChatClearStateAction(), makeDrugInteractionSearchClearStateAction());
                util_alert(
                    "An error occurred. Please try again.",
                    DRUG_INTERACTION_ERROR_TYPE,
                    dispatch
                )
            })
        },
        onGoToChatbotPage: (props) => {
            dispatch(makeDrugInteractionSearchGoToChatbotActionThunk(props));
        },
        onDrugPicklistSearchTextOnchange: (event,props) => {
            dispatch(
                makePicklistInputThunk(
                    event.target.value, // current input text
                    searchText => wordWheelDrugPicklistFetcher(searchText, dispatch, props.login_page, props),
                    DRUG_INTERACTION_PAGE_NAME,
                    DRUG_PICKLIST,
                    DRUG_INTERACTION_DRUGS_SEARCH + WORD_WHEEL_GENERAL_NAME,
                    props
                )
            ).catch(error => {
                handleError({props, message: GENERIC_ERROR_MESSAGE, dispatch},
                  makeChatClearStateAction(), makeDrugInteractionSearchClearStateAction());
                  util_alert(
                      "An error occurred. Please try again.",
                      DRUG_INTERACTION_ERROR_TYPE,
                      dispatch
                  )
            })
        },
        onAllergyPicklistSearchTextOnchange: (event, props) => {
            console.debug('Inside onAllergyPicklistSearchTextOnchange',event)
            dispatch(makeSetSearchTextAction(event.target.value, ALLERGY_PICKLIST, DRUG_INTERACTION_PAGE_NAME));
            dispatch(makeSetDataArrayAction(
              props.allergy_picklist_state.data_array,
              ALLERGY_PICKLIST,
              DRUG_INTERACTION_PAGE_NAME
            ))
        },
        onClearFilterItemsOnClick: () => {
            dispatch(makeClearSelectedItemsAction(DRUG_PICKLIST, DRUG_INTERACTION_PAGE_NAME))
            dispatch(makeClearSelectedItemsAction(ALLERGY_PICKLIST, DRUG_INTERACTION_PAGE_NAME))
            dispatch(makeDisplayDuplicateItemWarningTextAction('', DRUG_PICKLIST, DRUG_INTERACTION_PAGE_NAME));
        },
        onClearPicklists: () => {
            //call dispatch on the reducers which empty the search text for both dropdowns here
            dispatch(makeSetSearchTextAction('', DRUG_PICKLIST, DRUG_INTERACTION_PAGE_NAME));
            dispatch(makeSetSearchTextAction('', ALLERGY_PICKLIST, DRUG_INTERACTION_PAGE_NAME));

            if (localStorage.getItem(DRUGS_WORDWHEEL_ERROR) === 'true') {
                dispatch(makeSetFirstCharOfCurrentDrugAction('', DRUG_PICKLIST, DRUG_INTERACTION_PAGE_NAME))
            }
            localStorage.removeItem(DRUGS_WORDWHEEL_ERROR)
        },
        onClearDuplicateItemWarning: () => {
            dispatch(makeDisplayDuplicateItemWarningTextAction('', DRUG_PICKLIST, DRUG_INTERACTION_PAGE_NAME));
        },
        onDisplayDrugInteractionsWithDefaultState: (props, oldDrugs, oldAllergies) => {
          const drugIds = oldDrugs.map(drug => drug.id);
          const allergiesIds = oldAllergies.map(allergy => allergy.id);
          const drugInteractionsObject = { drugs: oldDrugs, allergies: oldAllergies };
          const drugInteractionsFilterObject = getFilterObjectForDefinedDrugsAndAllergies(drugIds, allergiesIds);
          dispatch(makeChangeInteractionObjectsAction(drugInteractionsObject));
          dispatch(makeShowFilterPanelAction(false));
          dispatch(makeDiShowUnableToDeleteMessageAction(false));
          dispatch(makeApplyNewFilterAction(drugInteractionsFilterObject));
          dispatch(makeDrugInteractionActionThunk(drugIds, allergiesIds, props));
        },
        redux_dispatch_function: dispatch,
    }
}


export class DrugInteractionSearchPagePresentation extends Component{
    initial_url_params = {}
    constructor(props) {
        super(props);
        const loc_search = get(this.props,'location.search')
        if (loc_search) {
            this.initial_url_params = queryString.parse(loc_search)
            console.debug('CTOR initial_url_params=',this.initial_url_params)
        }
        this.scrollingDivRef = createRef()
        this.gotoChatbotPage = this.gotoChatbotPage.bind(this)
        this.hasDuplicateRequests = this.hasDuplicateRequests.bind(this);
        this.drugInteractionsMethod = this.drugInteractionsMethod.bind(this);
        this.props.onClearDuplicateItemWarning();
    }

    gotoChatbotPage() {
        this.props.onGoToChatbotPage(this.props);
    }
    drugInteractionsMethod(drug_picklist_state, allergy_picklist_state,props) {
        const drugItems = get_selected_items(drug_picklist_state)
        const allergyItems = get_selected_items(allergy_picklist_state)
        let parameters = "";
        let oldDrugs = [];
        let oldAllergies = [];

        for (let i = 0; i< drugItems.length; i++) {
            const [item_name, item_id] = drugItems[i];
            oldDrugs.push({ name: item_name, id: item_id });
            console.debug(drugItems[i]);
            parameters += "&drug_ids="+ item_id;
            parameters += "&drug_names="+ encodeURIComponent(item_name);
        }

        for (let j = 0; j < allergyItems.length; j++) {
            const [item_name, item_id] = allergyItems[j];
            oldAllergies.push({ name: item_name, id: item_id });
            console.debug(allergyItems[j]);
            parameters += "&allergy_ids="+ item_id;
            parameters += "&allergy_names="+ encodeURIComponent(item_name);
        }

        this.props.onDisplayDrugInteractionsWithDefaultState(this.props, oldDrugs, oldAllergies);

        console.debug(parameters);
        let linkUrl = `/drugInteraction?${parameters}`;
        const linkURL_encoded = encodeURI(linkUrl);
        console.debug(linkURL_encoded);
        props.history.push(linkURL_encoded);
    }

    /**
     * When the react gui is displayed,
     * send the first drug points page information request
     */
    componentDidMount() {
        // if (!isEmptyObject(this.initial_url_params)) {
            const saved_url_params = this.initial_url_params
            this.initial_url_params = {}
            this.props.onDisplayInteraction(
                saved_url_params,
                this.props
            )
        // }
        this.props.onClearPicklists();
    }

    hasDuplicateRequests() {
        let warning_text = get(this.props.drug_picklist_state, 'warning_text', '');

        return warning_text;
    }

    clearDuplicateDrugWarning() {
        this.props.redux_dispatch_function(makeDisplayDuplicateItemWarningTextAction('', DRUG_PICKLIST, 'drug_interaction_search_page'))
    }

    render() {
     let duplicateText = this.hasDuplicateRequests();
     let failed_validation = duplicateText.length > 0;
     let selected_drugs=get(this.props.drug_picklist_state, 'selected_items',[]);
     let isViewInteractionsDisabled=false;
     let isClearAllDisabled=false;
     if(selected_drugs.length === 0){
         isViewInteractionsDisabled=true;
     }
     let selected_allergies=get(this.props.allergy_picklist_state, 'selected_items',[]);
     if(selected_drugs.length === 0 && selected_allergies.length === 0){
         isClearAllDisabled=true;
     }
     let validationFailedToastMessage;
     if(failed_validation) {
         validationFailedToastMessage = <ToastNotification
             lowContrast={false}
             title={"Duplicate Entries!"}
             //hideCloseButton={true}
             //timeout={4000}
             caption={duplicateText}
             onCloseButtonClick={()=>this.clearDuplicateDrugWarning()}
             subtitle={"The drug was not added to the list, as the following drugs are the same: "}
             kind={"error"}
         />;
     } else
         validationFailedToastMessage = <Fragment/>;

        return <div className="main-container">
                <div  className="empty-container"></div>
                <div className="main-content">
                    <div className="mdx-scroll-area mdx-scroll-area-ie" ref={this.scrollingDivRef}>
                        <div className="mdx-header border-line">
                            <h1 className="heading-color heading-font search-and-results-page-titles">Drug Interactions Search</h1>
                        </div>
                        <p className="instructions">Add at least one drug and tap view interactions.</p>
                        <div>{validationFailedToastMessage}</div>
                        <div className="mdx-inner-content">
                            <PickList
                                input_label= 'Drugs'
                                input_placeholder= "Find a Drug"
                                info_help_html= { `<div>
                                        <ul>
                                            <li>Tap on the Drugs search field.</li>
                                            <li>Enter the first few letters of the generic or brand drug name.</li>
                                            <li>Tap to add item.</li>
                                            <li>Tap the X button close selected list.</li>
                                        </ul>
                                </div>`}
                                picklist_id= {DRUG_PICKLIST}
                                page_name={DRUG_INTERACTION_PAGE_NAME}
                                search_text_onchange={(event) =>this.props.onDrugPicklistSearchTextOnchange(event, this.props)}
                                picklist_state = {this.props.drug_picklist_state}
                                redux_dispatch_function = {this.props.redux_dispatch_function}
                                responseLoading = {this.props.diDrugsResponseLoaded}
                            />
                            <PickList
                                input_label= 'Allergies % *optional'
                                input_placeholder= "Find an allergy"
                                info_help_html= { `<div>
                                        <ul>
                                            <li>Tap on the Allergies search field.</li>
                                            <li>Enter the first few letters of the allergy.</li>
                                            <li>Tap to add item.</li>
                                            <li>Tap the X button close selected list.</li>
                                        </ul>
                                </div>`}
                                picklist_id= {ALLERGY_PICKLIST}
                                page_name={DRUG_INTERACTION_PAGE_NAME}
                                search_text_onchange={(event) =>this.props.onAllergyPicklistSearchTextOnchange(event, this.props)}
                                picklist_state = {this.props.allergy_picklist_state}
                                redux_dispatch_function = {this.props.redux_dispatch_function}
                                only_single_fetch_required = {true}
                                responseLoading = {this.props.diAllergiesResponseLoaded}
                            />
                            <div className="bottom-aln" style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                }}>
                                <Button
                                    className="secondary-button bottom-clear"
                                    kind="tertiary"
                                    onClick={()=>this.props.onClearFilterItemsOnClick()}
                                    disabled={isClearAllDisabled}>
                                    Clear All
                                </Button>
                                <Button
                                    id="view-interaction"
                                    kind="primary"
                                    className="primary-button bottom-view"
                                    disabled={isViewInteractionsDisabled}
                                    onClick={
                                        ()=>this.drugInteractionsMethod(
                                            this.props.drug_picklist_state,
                                            this.props.allergy_picklist_state,
                                            this.props
                                        )
                                    }
                                >
                                    View Interactions
                                </Button>
                            </div>
                        </div>
                      <CommonFooter/>
                    </div>
                </div>
                <CommonOverflowModalPopups/>
            </div>
 }

}

const DrugInteractionSearchPage = connect(
    mapStateToProps,
    mapDispatchToProps
) (DrugInteractionSearchPagePresentation)

export default DrugInteractionSearchPage
