/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {makeSetChatbotReturnTarget} from "../../../ChatbotPage/chatbot-utils";
import {immutable_set, util_alert, get} from "../../../../utils";
import {getIVProductInfoPromise} from "../../../../services/ivCompatibilityService";
import {handleError, handleErrorStatuses} from "../../../../utils/internal-error-handler";
import {GENERIC_ERROR_MESSAGE, IV_COMPATIBILITY_ERROR_TYPE} from "../../../../components/common/constants";
import {
  ACTIVE_STATUS, ERROR_STATUS,
  IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME, LOADING_DESCRIPTION, LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction, NONE_STATUS
} from "../../../../components/response-loading/redux/response-loading-redux";

const PRODUCT_ID_PATH = 'ivproductdoc.document.informationSection.persistentDocumentId';

export const EMPTY_STATE = {
}

export const A = {
    CLEAR_INTERACTION_STATE: 'CLEAR_INTERACTION_STATE',
    IV_PRODUCT_INFO_RESPONSE: 'IV_PRODUCT_INFO_RESPONSE',
    SET_PRODUCT_DOCUMENT_ID: 'SET_PRODUCT_DOCUMENT_ID'
}


export function makeIVProductInfoClearStateAction() {
    return {
        type: A.CLEAR_INTERACTION_STATE
    }
}

export function makeIVProductInfoResponseAction(iv_product_info_response_json={}){
    return {
        type: A.IV_PRODUCT_INFO_RESPONSE,
        iv_product_info_response_json
    }
}

export function makeIVProductInfoDocumentIdAction(productDocumentId = ''){
    return {
        type: A.SET_PRODUCT_DOCUMENT_ID,
        productDocumentId
    }
}


// Reducers ...
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */

export function setProductInfoDocumentIdReducer(current_state = {}, action = {}){
    return immutable_set(current_state, 'productDocId', action.productDocumentId)
}

export function IVProductInfoResponseReducer(current_state={}, action={}){
    console.debug('inside IV product info reducer')
    return immutable_set(
        current_state,
        'IV_PRODUCT_INFO_RESPONSE' ,
        action.iv_product_info_response_json
    )
}
export function clearInteractionStateReducer(current_state={}, action={}){
    console.debug('inside iv product info reducer')
    return  {
        productDocId:"",
        IV_PRODUCT_INFO_RESPONSE:{}
    }
}


export function makeIVProductInfoGoToChatbotPageActionThunk(props={}){
    return (dispatch) => {
        dispatch(makeSetChatbotReturnTarget('IVProductInfoPage')); //need to hook up other end of this
        props.history.push('/chatbot');
    }
}

export function makeIVProductInfoActionThunk( productDocId="", props={}){
    return(dispatch, getState) => {
        console.debug(productDocId);
        console.debug('props inside IVProductInfo...', props);
        let current_state=getState();
        let login_page = {};
        if(current_state.iv_product_info_page) {
            login_page = current_state.login_page;
            current_state = current_state.iv_product_info_page
        }

        dispatch(makeResponseLoadingAction(IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
        return getIVProductInfoPromise(
            {
                productDocId
            }, login_page
        ).then(function (response){
            const status = response.status;
            if(status === 200) {
              return response.json();
            } else {
              dispatch(makeResponseLoadingAction(IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
              if (status === 403) {
                dispatch(makeIVProductInfoClearStateAction())
              }
              let mutable_props = {...props};
              mutable_props.error_type = IV_COMPATIBILITY_ERROR_TYPE
              handleErrorStatuses(status, mutable_props, dispatch, getState)
            }
        }).then(iv_product_info_response_json =>{
            if (iv_product_info_response_json) {
              const lastProductDocId = get(iv_product_info_response_json, PRODUCT_ID_PATH, '');
              dispatch(makeIVProductInfoResponseAction(iv_product_info_response_json));
              dispatch(makeIVProductInfoDocumentIdAction(lastProductDocId));
              dispatch(makeResponseLoadingAction(IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
            }
        }).catch(error => {
            console.error( "something went wrong ... Returning to Login page.");
            dispatch(makeResponseLoadingAction(IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
            // props.history.push("/");
            handleError({props, message: GENERIC_ERROR_MESSAGE, dispatch});
            util_alert(
                "An error occurred. Please try again.",
                IV_COMPATIBILITY_ERROR_TYPE,
                dispatch,
                getState
                );
        })
    }
}


export function iv_product_info_page(
    current_state = EMPTY_STATE,
    action = {}
) {
    switch(action.type){
        case A.IV_PRODUCT_INFO_RESPONSE:
            return IVProductInfoResponseReducer(current_state, action);
        case A.CLEAR_INTERACTION_STATE:
            return clearInteractionStateReducer(current_state, action);
        case A.SET_PRODUCT_DOCUMENT_ID:
            return setProductInfoDocumentIdReducer(current_state, action);
        default:
            return current_state
    }
}
