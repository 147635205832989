/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from "react";
import {connect} from "react-redux";
import {get} from "../../../../utils";
import { makeChangeExpandedAccordionsAction } from "../iv-details-utils";
const { ChevronDown, ChevronUp } = require('@carbon/react/icons');

//ToDo: Move this to common component.
const mapStateToProps = state => {
    const state_results = {
        expanded_accordions: get(state.iv_details_page, 'expanded_accordions', [])
    }
    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeExpandedAccordions: (expandedAccordionsState) => {
            dispatch(makeChangeExpandedAccordionsAction(
                expandedAccordionsState
            ))
        }
    }
}
export class AccordionCollapser extends Component {
    constructor(props) {
        super(props);
        this.collapseAll = this.collapseAll.bind(this);
        this.expandAll = this.expandAll.bind(this);
    }

    collapseAll() {
        this.props.onChangeExpandedAccordions([]);
    }

    expandAll() {
        let items=get(this.props,'accordions',[]);
        this.props.onChangeExpandedAccordions(items);
    }
    render() {
        if(this.props.expanded_accordions.length > 0) {
            return <div className = "collapseWrapperChevron">
                    <div className="clickableLink" onClick={()=>this.collapseAll()}>
                        <div className="expandCollapseLink">
                            <div className="expandCollapseChevron">
                                <ChevronUp className="drug_interaction_fill_sixteen"/>
                                <span className="expandCollapseText">Collapse All</span>
                            </div>
                        </div>
                    </div>
            </div>
        } else {
            return <div className = "collapseWrapperChevron">
                <div className="clickableLink" onClick={()=>this.expandAll()}>
                    <div className="expandCollapseLink">
                        <div className="expandCollapseChevron">
                            <ChevronDown className="drug_interaction_fill_sixteen"/>
                            <span className="expandCollapseText">Expand All</span>
                        </div>
                    </div>
                </div>
            </div>
        }
    }
}
const IVDetailsAccordionCollapser = connect(mapStateToProps, mapDispatchToProps)(AccordionCollapser);

export default IVDetailsAccordionCollapser;