/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from 'react';
import {connect} from "react-redux";
import {get, getArray, isEmptyObject} from "../../../utils";
import {
    makeIVProductInfoGoToChatbotPageActionThunk,
    makeIVProductInfoActionThunk
} from "./iv-product-info-utils";

import CommonOverflowModalPopups from "../../../components/common-overflow-menu/CommonOverflowModalPopups";
import queryString from "query-string";
import { Accordion, AccordionItem } from "@carbon/react";

import { Link } from 'react-router-dom';

import { IVFooter } from "../../../components/common/footers";
import { CommonFooter } from "../../../components/common/footers/Footer";
import {
  IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME
} from "../../../components/response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';
import { getPreviousPageLink } from "../../../utils";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { breakLongLinesForTooltip } from '../../../utils/tooltip-utils';
import { IV_COMPATIBILITY_ROOT_PATH } from '../../../utils/commonIvDiUtils';

const { ChevronLeft } = require('@carbon/react/icons');

const SECTIONS = ['Stability', 'References', 'Type', 'Description', 'ProductID','ReferencesSection'];
const PRODUCT_ID_PATH = 'productDocId';

const DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS = 30

const mapStateToProps = state => {
    console.debug('mapStateToProps: state=',state)
    const state_results = {
        current_state: state.iv_product_info_page,
        url_parameters: get(state.url_params, 'url_parameters', ''),
        current_document_id: get(state.drug_points_page, 'documentId'),
        topic_id: get(state.drug_points_page,'topicId'),
        current_drugEvals_id: get(state.in_depth_page,'documentId'),
        drug_name: get(state.drug_points_page,'drugName'),
        ivProductInfoPageResponseLoading: get(state, `inline_loading.${IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME}`, {})
    }
    console.debug(state_results);
    return state_results
}


const mapDispatchToProps = dispatch => {
    return {
        onDisplayProductInfoDetails: (initial_url_params={}, props) => {
            const newProductDocId =get(initial_url_params, PRODUCT_ID_PATH, '');
            const lastProductDocId = get(props.current_state, PRODUCT_ID_PATH, '');
            if (newProductDocId !== lastProductDocId) {
                dispatch(makeIVProductInfoActionThunk(newProductDocId, props));
            }
        },

        onGoToChatbotPage: (props) => {
            console.debug('Inside onGoToChatbotPage!');
            dispatch(makeIVProductInfoGoToChatbotPageActionThunk(props));
        }
    }
}


export class ivProductInfoPresentation extends Component {
    initial_url_params = {}
    constructor(props) {
        super(props);
        console.debug('IVProductInfoPagePresentation CTOR props=', props)
        const loc_search = get(this.props,'location.search')
        if (loc_search) { //what is this for? (Not sure who wrote this question ..., when you come from monograph you need to go back there
            this.initial_url_params = queryString.parse(loc_search)
            console.debug('CTOR initial_url_params=',this.initial_url_params)
        }
        this.getIVProductInfo = this.getIVProductInfo.bind(this);
        this.closeIVProductInfoAndReturn=this.closeIVProductInfoAndReturn.bind(this);
        this.showDocument=this.showDocument.bind(this);
    }

    getIVProductInfo() {
        this.props.onDisplayProductInfoDetails(this.initial_url_params, this.props)
    }

    closeIVProductInfoAndReturn() {
        return getPreviousPageLink(this.initial_url_params, IV_COMPATIBILITY_ROOT_PATH, this.props);
    }

    showDocument() {
        this.props.onShowDocumentation(true);
    }

    componentDidMount() {
        // if initial_url_params is not empty, then we are responding
        // to a quick links url
        if (!isEmptyObject(this.initial_url_params)) {
            const saved_url_params = this.initial_url_params
            this.props.onDisplayProductInfoDetails(
                saved_url_params,
                this.props
            )
        }
    }

    render() {
        let iv_product_info_json=get(this.props.current_state,'IV_PRODUCT_INFO_RESPONSE');
        console.debug(iv_product_info_json);
        let iv_product_info_text=get(iv_product_info_json,'ivproductdoc.document','');
        let isMultiformulation = get(iv_product_info_text,'contentTree.ProductData.MultiFormulation','');

        return <div  ref={this.scrollingDivRef} className="main-container">
            {/* Use same class as drug interaction details page for formatting consistency, defined in _drug-interaction-page.scss*/}
            <div  className="empty-container"></div>
            <div className="main-content">
            <div className="mdx-scroll-area mdx-scroll-area-ie">
                    <div className="mdx-header">
                        {/* Use same class as drug interaction details page for formatting consistency, defined in _drug-interaction-page.scss*/}
                        <Link className="drug-interaction-modify-link"  to={() => this.closeIVProductInfoAndReturn()}>
                            <ChevronLeft className="chevronleft16_fill-details" />
                            <span>Results summary</span>
                        </Link>
                        <h4 className="drug-interaction-title-1 search-and-results-page-titles">IV Compatibility Product</h4>
                    </div>
                    <div  className="mdx-inner-content drug-ivproduction-details-text drug-details-text">
                        {/* Use same class as drug interaction details page for formatting consistency, defined in _drug-interaction-page.scss*/}

                        {/*<Accordion id = "productInfoPageContentBXAccordion">*/}
                        <h5 className="drug-details-page-title">Trissel's Drugs and Solutions Data</h5>

                        {!this.props.ivProductInfoPageResponseLoading.status
                          ? parseContent(iv_product_info_text, this.onShowDocumentation, isMultiformulation)
                          : <InlineLoading className={"iv-compatibility-product-info-loading"}
                                           description={this.props.ivProductInfoPageResponseLoading.description}
                                           status={this.props.ivProductInfoPageResponseLoading.status}/>}
                        {/*</Accordion>*/}

                        {/* Consolidated referenceSections*/}
                        <div>
                            <Accordion className="mdx-accordion">
                                <AccordionItem title={
                                    <span className="accordion-item-title-font">
                                        Reference Section
                                    </span>
                                }
                                className="iv-product-info-accordion-item"
                                >
                                    {!this.props.ivProductInfoPageResponseLoading.status
                                        ? parseFormulationReferencesController(iv_product_info_text, this.onShowDocumentation)
                                        : <InlineLoading className={"iv-compatibility-product-info-accordion-loading"}
                                            description={this.props.ivProductInfoPageResponseLoading.description}
                                            status={this.props.ivProductInfoPageResponseLoading.status} />}
                                </AccordionItem>
                            </Accordion>
                        </div>

                        <IVFooter styleName='iv-compatibility-product-footer'/>
                    </div>
                  <CommonFooter/>
                </div>
            </div>
            <CommonOverflowModalPopups/>
        </div>
    }
}

export function parseContent(iv_product_info_text, onShowDocumentation, isMultiformulation){
    if (isMultiformulation === true) {
        return parseMultipleFormulationTextContent(iv_product_info_text,onShowDocumentation)
    } else {
        return parseTextContent(iv_product_info_text, onShowDocumentation)
    }
}

export function getReferenceIdsForEachSection(text, pathValue,sectionOrder){

    let referenceSections = (String(get(text,pathValue,'')));
    let referencesBySection={};
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(referenceSections, "application/xml");
    const paraElements = xmlDoc.getElementsByTagName("para");
    
    for (let para of paraElements) {
        const xrefElements = para.getElementsByTagName("xref");
        const hasOnlyXref = Array.from(para.childNodes).every(
            node => node.nodeName === "xref" || (node.nodeType === Node.TEXT_NODE && !node.nodeValue.trim())
        );
        
        if (xrefElements.length > 0 && hasOnlyXref) {
            for (let xref of xrefElements) {
                let field = sectionOrder;
                let code = xref.getAttribute("code").replace("cite","");
                if (field in referencesBySection) {
                    referencesBySection[field].push(code);
                } else {
                    referencesBySection[field] = [code];
                }
            }
        }
    }
    return referencesBySection;
}


export function checkOldContentOrNewContent(text){
    let isOldContent = false;
    let referenceSections = get(text, "contentTree.ProductData.References");
    let referenceArray = getArray(referenceSections, "Reference");
    if(referenceArray.length>0){
        isOldContent=true;
    }
    return isOldContent;
}

export function getReferenceIdsForEachSectionOld(text){
    //input will be 'document' from json
    let referencesBySection={};

    let referenceSections = get(text, "contentTree.ProductData.References");
    let referenceArray = getArray(referenceSections, "Reference");

    for (let i = 0; i < referenceArray.length;i++) {
        let field = get(referenceArray[i], "Field");
        let code = get(referenceArray[i], "Code");
        if (field in referencesBySection) {
            referencesBySection[field].push(code);
        } else {
            referencesBySection[field] = [code];
        }
    }

    return referencesBySection;
}

export function parseTextContent(text, showDocument=f=>f){
    //input will be 'document' from json

    //below is case for when page not fully mounted yet, deals with undefined/empty text
    console.debug(text)
    if((typeof text).localeCompare("string") === 0){
        return <div/>
    }

    let ivProductInfo=[];
    let isOldContent = checkOldContentOrNewContent(text);
    let referencesBySection;
        if(isOldContent === true){
            referencesBySection = getReferenceIdsForEachSectionOld(text);
        }
    let normalSections = Object.keys(get(text, "contentTree.ProductData", {})) //unordered list of sections
    //Remove sections that will not be handled by default case, as of now just "Stability", "Type", "Description", "ProductID", and "References":
    SECTIONS.forEach(section => {
        normalSections.splice(normalSections.indexOf(section), 1);
    });
    normalSections = new Set(normalSections);

    //do not include sections that will not be displayed nor handled by default case, as of now "Stability", "Type", "Description", "ProductID", and "References":
    let sectionOrderList = ["TradeNames", "OtherNames", "pHrange", "pHmin", "pHmax", "pHmean", "Formulation", "Reconstitution", "Osmolality", "SodiumContent", "Storage", "StabilityMax", "Stability", "pHeffects", "Sorption", "Filtration", "Freezing", "LightEffects", "Other"]
    //now get all sections unaccounted for by above, display after. For case when an unaccounted for section pops up
    let tempSetSectionsOrdered = new Set(sectionOrderList);
    let remainingSections = Array.from(new Set([...normalSections].filter(x => !tempSetSectionsOrdered.has(x))));
    sectionOrderList = sectionOrderList.concat(remainingSections);
    //TODO: UPDATE CSS CLASSNAMES, currently using same as interactionDetailsPage
    if(get(text,'informationSection.indexData.title',false)){
        ivProductInfo.push(<div key = "title">
            <h5 className="drug-details-page-title">{get(text,'informationSection.indexData.title','')}</h5>
        </div>)
    }
    
    for(var i = 0; i < sectionOrderList.length; i++){
        let pathToGetValue = "contentTree.ProductData." + sectionOrderList[i];
        if(!get(text,pathToGetValue,false)){
            //section does not exist in returned data, skip
            continue;
        }

        if(isOldContent === false){
            referencesBySection = getReferenceIdsForEachSection(text,pathToGetValue,sectionOrderList[i]);
        }

        if(!(sectionOrderList[i] in referencesBySection)){
            referencesBySection[sectionOrderList[i]] = [];
        }
        let sectionText = (String(get(text,pathToGetValue,'')));
        // eslint-disable-next-line
        if(isOldContent === true){
        sectionText = sectionText.replace(/ {4}/g, "INDENT"); //all leading and trailing whitespace is implicitly trimmed, removing all of the indents that are in the text, on the .split() call
        console.debug(sectionText);
        }else{
        sectionText = sectionText.replace(/ {4}/g, "INDENT").replaceAll("<subsection>","").replaceAll("</subsection>","")
                                .replaceAll("<title>","<strong>").replaceAll("</title>","</strong>")
                                .replaceAll("<para>","<p>").replaceAll("</para>","</p>").replaceAll("<list>","<ul>").replaceAll("</list>","</ul>").replaceAll("<item>","<li>").replaceAll("</item>","</li>"); 
        sectionText = extractContentFromParas(sectionText); 
        }
        ivProductInfo.push(<div key = {sectionOrderList[i]}>
			<div className="accordion-title-tpn" id="accordion-title-font" key={"span" + sectionOrderList[i]}>
				{sectionNameDetailer(sectionOrderList[i])} <span
					className="referenceIdsIVProductInfoTitles">
					<pre class="prespace-tooltip">
						{referencesBySection[sectionOrderList[i]].map(function(item, index, referencesArrayForSection) {
							if (index === 0 && index + 1 === referencesArrayForSection.length) {
								if(isOldContent === true){
                                    return <span>[<a href data-tooltip-id="tooltip" data-tooltip-float data-tooltip-html={breakLongLinesForTooltip(parseDataReferencesOld(text,item)[0], DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{item}</a>]</span>;
                                }else{
                                return <span>[<a href data-tooltip-id="tooltip" data-tooltip-float data-tooltip-html={breakLongLinesForTooltip(parseDataReferences(text,item)[0], DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{item}</a>]</span>;
                                }
                            } else if (index === 0) {
                                if(isOldContent === true){
                                    return <span>[<a href data-tooltip-id="tooltip" data-tooltip-float data-tooltip-html={breakLongLinesForTooltip(parseDataReferencesOld(text,item)[0], DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{item}</a></span>; 
                                }else{
								return <span>[<a href data-tooltip-id="tooltip" data-tooltip-float data-tooltip-html={breakLongLinesForTooltip(parseDataReferences(text,item)[0], DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{item}</a></span>;
                                }
                            } else if (index + 1 === referencesArrayForSection.length) {
								if(isOldContent === true){
                                    return <span>] [<a href data-tooltip-id="tooltip" data-tooltip-float data-tooltip-html={breakLongLinesForTooltip(parseDataReferencesOld(text,item)[0], DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{item}</a>]</span>;  
                                }else{
                                return <span>] [<a href data-tooltip-id="tooltip" data-tooltip-float data-tooltip-html={breakLongLinesForTooltip(parseDataReferences(text,item)[0], DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{item}</a>]</span>;
                                }
                            } else {
                                if(isOldContent === true){
                                    return <span>] [<a href data-tooltip-id="tooltip" data-tooltip-float data-tooltip-html={breakLongLinesForTooltip(parseDataReferencesOld(text,item)[0], DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{item}</a></span>;  
                                }else{
								return <span>] [<a href data-tooltip-id="tooltip" data-tooltip-float data-tooltip-html={breakLongLinesForTooltip(parseDataReferences(text,item)[0], DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{item}</a></span>;
                                }
                            }
						})}
                        <ReactTooltip id="tooltip" place='bottom'/>
					</pre>
				</span>
			</div>
            {sectionText.split("\n").map(function(item, index){
                if(item.endsWith(":")){
                    return (<div key={index} className="inline-header-iv-product-info accordion-content-tpn">
                            {item}
                        </div>
                    )
                }
                else if(item.includes("INDENT")){
                    item = item.replace(/INDENT/g, "");
                    return (<div key={index} className="full-indent-iv-product-info accordion-content-tpn">
                            {item}
                        </div>
                    )
                } else {

                if(isOldContent === true){
                    return (<div key={index} className="accordion-content-tpn" dangerouslySetInnerHTML={{__html:  item}}>
                        </div>
                    )
                }else{    
                const processedText = processText(item, text,"tooltip");
                return (
                  <div
                  key={index}
                  className="accordion-content-tpn"
                    dangerouslySetInnerHTML={{
                      __html: processedText
                    }}
                  ></div>
                );
            }
                }
            })}
        </div>)
    }

    return ivProductInfo.map( function (section){
        return section
    })
}

function sectionNameDetailer(sectionName){
    if (sectionName.localeCompare("Stability") === 0){
        return "Stability (Detailed)";
    }
    else if(sectionName.localeCompare("OtherNames") === 0){
        return "Other Name(s)";
    }
    else if(sectionName.localeCompare("TradeNames") === 0){
        return "Trade Name(s)";
    }
    else if(sectionName.localeCompare("pHeffects") === 0){
        return "pH Effects";
    }
    else if(sectionName.localeCompare("pHrange") === 0){
        return "pH Range";
    }
	else if(sectionName.localeCompare("pHmin") === 0){
        return "pH Min";
    }
	else if(sectionName.localeCompare("pHmax") === 0){
        return "pH Max";
    }
	else if(sectionName.localeCompare("pHmean") === 0){
        return "pH Mean";
    }
    else if(sectionName.localeCompare("Other") === 0){
        return "Other Information";
    }
    else if(sectionName.localeCompare("Sorption") === 0){
        return "Interaction with Plastics";
    }
    else if(sectionName.localeCompare("StabilityMax") === 0){
        return "Maximum Stability";
    }
    else{
        return sectionName;
    }
}

export function parseMultipleFormulationTextContent(text, showDocument=f=>f){

    //input will be 'document' from json
    //below is case for when page not fully mounted yet, deals with undefined/empty text
    console.debug(text)
    if((typeof text).localeCompare("string") === 0){
        return <div/>
    }

    let ivProductInfo=[];

    let normalSections = Object.keys(get(text, "contentTree.ProductData", {})) //unordered list of sections
    //Remove sections that will not be handled by default case, as of now just "Stability", "Type", "Description", "ProductID", and "References":
    SECTIONS.forEach(section => {
        normalSections.splice(normalSections.indexOf(section), 1);
    });
    normalSections = new Set(normalSections);

    //do not include sections that will not be displayed nor handled by default case, as of now "Stability", "Type", "Description", "ProductID", and "References":
    let sectionOrderList = ["TradeNames", "OtherNames", "pHrange", "pHmin", "pHmax", "pHmean", "Formulation", "Reconstitution", "Osmolality", "SodiumContent", "Storage", "StabilityMax", "Stability", "pHeffects", "Sorption", "Filtration", "Freezing", "LightEffects", "Other"]

    // Get all sections unaccounted for by above, display after. For case when an unaccounted for section pops up
    let tempSetSectionsOrdered = new Set(sectionOrderList);
    let remainingSections = Array.from(new Set([...normalSections].filter(x => !tempSetSectionsOrdered.has(x))));
    sectionOrderList = sectionOrderList.concat(remainingSections);
    //TODO: UPDATE CSS CLASSNAMES, currently using same as interactionDetailsPage
    if(get(text,'informationSection.indexData.title',false)){
        ivProductInfo.push(<div key = "title">
            <h5 className="drug-details-page-title">{get(text,'informationSection.indexData.title','')}</h5>
        </div>)
    }

    let productDataId=get(text, "contentTree.ProductData.ProductID");
    let tradenameRefs = get(text, "contentTree.ProductData.TradeNameFormulationRefs");
    let tradenameRefArray = getArray(tradenameRefs, "TradeNameFormulationRef");
    let tradeNames = get(text, "contentTree.ProductData.TradeNames", "");
    ivProductInfo.push(<div key = {"TradeNames"}>
        <div className="accordion-title-tpn" id="accordion-title-font">
            {sectionNameDetailer("TradeNames")}
                </div>
                {tradeNames && (
                 <div>
                   {tradeNames}
                 </div>
                )}
                </div>);
              let  tradenamesArray=[];
    for(var tnameCounter = 0;tnameCounter<tradenameRefArray.length;tnameCounter++){
        let tradeNameData = tradenameRefArray[tnameCounter];
        parseTradeNamesText(tradeNameData,ivProductInfo,productDataId,tradenamesArray);

    }
    let referencesBySection;
    let isOldContent = checkOldContentOrNewContent(text);
    let tradenameFormulations = get(text, "contentTree.ProductData.Formulations");
    let tradenameFormulationArray = getArray(tradenameFormulations, "TradeNameFormulation");
    console.debug("tradenameFormulationArray"+tradenameFormulationArray.length);
    for(var j = 0; j < tradenameFormulationArray.length; j++){
        let tradeNameText = tradenameFormulationArray[j];
        console.debug("tradename text"+JSON.stringify(tradeNameText));
        if(isOldContent === true){
         referencesBySection = getMultiformulationReferenceIdsForEachSection(tradeNameText);
        }
        let indexid=get(tradeNameText,"id",'');
        console.debug("indexid"+indexid);
        ivProductInfo.push(<div id={indexid+''+productDataId}><br/>
        <div className="accordion-title-tpn" id="accordion-title-font" >
           {tradenamesArray[j]}
                </div></div>);

    for(var i = 0; i < sectionOrderList.length; i++){
        let pathToGetValue = sectionOrderList[i];
        if(!get(tradeNameText,pathToGetValue,false)){
            //section does not exist in returned data, skip
            continue;
        }
        if(isOldContent === false){
         referencesBySection = getReferenceIdsForEachSection(tradeNameText,sectionOrderList[i],sectionOrderList[i]);
        }
         if(!(sectionOrderList[i] in referencesBySection)){
            referencesBySection[sectionOrderList[i]] = [];
        }

        let sectionText = (String(get(tradeNameText,pathToGetValue,'')));
        // eslint-disable-next-line
        if(isOldContent === true){
            sectionText = sectionText.replace(/ {4}/g, "INDENT"); //all leading and trailing whitespace is implicitly trimmed, removing all of the indents that are in the text, on the .split() call
        }else{
        sectionText = sectionText.replace(/ {4}/g, "").replaceAll("<subsection>","").replaceAll("</subsection>","")
        .replaceAll("<title>","<strong>").replaceAll("</title>","</strong>")
        .replaceAll("<para>","<p>").replaceAll("</para>","</p>").replaceAll("<list>","<ul>").replaceAll("</list>","</ul>").replaceAll("<item>","<li>").replaceAll("</item>","</li>"); 
        sectionText = extractContentFromParas(sectionText); 
        }
        console.debug(sectionText);
        ivProductInfo.push(<div key = {sectionOrderList[i]}>
			<div className="accordion-title-tpn" id="accordion-title-font" key={"span" + sectionOrderList[i]}>
				{sectionNameDetailer(sectionOrderList[i])} <span
					className="referenceIdsIVProductInfoTitles">
					<pre class="prespace-tooltip">
                        
						{referencesBySection[sectionOrderList[i]].map(function(item, index, referencesArrayForSection) {
							if (index === 0 && index + 1 === referencesArrayForSection.length) {
                                if(isOldContent === true){
                                    console.log(parseDataMutltipleReferences(text, item))
                                    return <span>[<a href data-tooltip-id="multi-tooltip" data-tooltip-float data-tooltip-html={parseDataMutltipleReferences(text, item)[0]}>{item}</a>]</span>;
                                }else{
                                console.log(parseDataReferences(text, item))
								return <span>[<a href data-tooltip-id="multi-tooltip" data-tooltip-float data-tooltip-html={parseDataReferences(text, item)[0]}>{item}</a>]</span>;
                                }
                            } else if (index === 0) {
                                if(isOldContent === true){
                                    return <span>[<a href data-tooltip-id="multi-tooltip" data-tooltip-float data-tooltip-html={parseDataMutltipleReferences(text, item)[0]}>{item}</a></span>;
                                }else{
								return <span>[<a href data-tooltip-id="multi-tooltip" data-tooltip-float data-tooltip-html={parseDataReferences(text, item)[0]}>{item}</a></span>;
                                }
                            } else if (index + 1 === referencesArrayForSection.length) {
                                if(isOldContent === true){
                                    return <span>] [<a href data-tooltip-id="multi-tooltip" data-tooltip-float data-tooltip-html={parseDataMutltipleReferences(text, item)[0]}>{item}</a>]</span>;
                                }else{
								return <span>] [<a href data-tooltip-id="multi-tooltip" data-tooltip-float data-tooltip-html={parseDataReferences(text, item)[0]}>{item}</a>]</span>;
                                }
                            } else {
                                if(isOldContent === true){
                                    return <span>] [<a href data-tooltip-id="multi-tooltip" data-tooltip-float data-tooltip-html={parseDataMutltipleReferences(text, item)[0]}>{item}</a></span>;
                                }else{
								return <span>] [<a href data-tooltip-id="multi-tooltip" data-tooltip-float data-tooltip-html={parseDataReferences(text, item)[0]}>{item}</a></span>;
                                }
                            }
						})
						}
                        <ReactTooltip id='multi-tooltip' />
					</pre>
				</span>
			</div>
            {sectionText.split("\n").map(function(item, index){
                if(item.endsWith(":")){
                    return (<div key={index} className="inline-header-iv-product-info accordion-content-tpn">
                            {item}
                        </div>
                    )
                }
                else if(item.includes("INDENT")){
                    item = item.replace(/INDENT/g, "");
                    return (<div key={index} className="full-indent-iv-product-info accordion-content-tpn">
                            {item}
                        </div>
                    )
                }else {
                    if(isOldContent === true){
                        return (<div key={index} className="accordion-content-tpn" dangerouslySetInnerHTML={{
                            __html:  item}}  >
                            </div>
                        )
                    }else{
                    const processedText = processText(item, text,"multi-tooltip");
                    return (
                      <div
                      key={index} className="accordion-content-tpn" 
                        dangerouslySetInnerHTML={{
                          __html: processedText
                        }}
                      ></div>
                    );
                }
                }
            })}
        </div>)
    }
}
    return ivProductInfo.map( function (section){
        return section
    })
}

export function scrollToTarget(clickid) {
  var divid = document.getElementById(clickid);
  divid.scrollIntoView( { behavior: 'smooth', block: 'start' } );
}

// Tooltip data for multi formulation
export function parseDataMutltipleReferences(text, refid){
    //below is case for when page not fully mounted yet, deals with undefined/empty text
    if((typeof text).localeCompare("string") === 0){
        return <div/>
    }

    let ivProductInfoReferences=[];
    let ivProductInfoCodes=new Set();
    let tradeNameSections = get(text, "contentTree.ProductData.Formulations");
    let tradeNameArray = getArray(tradeNameSections, "TradeNameFormulation");
    let referenceSections = get(text, "contentTree.ProductData.References")
    let referenceArrays = getArray(referenceSections, "Reference");
    let reference_info = "Reference: ";

    for (var i = 0; i < tradeNameArray.length;i++){
        let referenceArray = getArray(tradeNameArray[i], "References.Reference");
        for (var j = 0; j < referenceArray.length; j++) {
            let code = get(referenceArray[j], "Code");
            //loop for reference array at product data level
            for (var k = 0; k < referenceArrays.length; k++) {
            if( !ivProductInfoCodes.has(code) && code === get(referenceArrays[k], "Code")){
                ivProductInfoCodes.add(code)
                let author = get(referenceArrays[k], "Author");
                let title = get(referenceArrays[k], "Title");
                let journal = get(referenceArrays[k], "Journal");
                let year = get(referenceArrays[k], "Year");
                let volume = get(referenceArrays[k], "Volume");
                let pages = get(referenceArrays[k], "Pages");

                var obj={
                    reference_info:reference_info,
                    code:code,
                    author:author,
                    title:title,
                    journal:journal,
                    year:year,
                    volume:volume,
                    pages:pages
                };

                if(obj.code === refid){
                    ivProductInfoReferences.push(obj);
                    break;
                }
            }
            }
        }
    }

    return ivProductInfoReferences.map((referenceObject, index) => {
        return referenceObject.reference_info
        +"["+ (referenceObject.code ? referenceObject.code : "")
        + "] " + (referenceObject.author ? referenceObject.author : "")
        + ": " + (referenceObject.title ? referenceObject.title : "")
        + " " + (referenceObject.journal ? referenceObject.journal : "")
        + " " + (referenceObject.year ? referenceObject.year : "")
        + " " + (referenceObject.volume ? referenceObject.volume : "")
        + " " + (referenceObject.pages ? referenceObject.pages : "");
    })
}


// Tooltip data for single formulation
export function parseDataReferences(text, refid){
    //below is case for when page not fully mounted yet, deals with undefined/empty text
    if((typeof text).localeCompare("string") === 0){
        return <div/>
    }

    let ivProductInfoReferences=[];
    let ivProductInfoCodes=new Set();
    let referenceSections = get(text, "contentTree.ProductData.ReferencesSection");
    let citation = getArray(referenceSections,"citation");
    //let citationText = get(citation,"citationText");
    console.log(citation);
   // let referenceArray = getArray(referenceSections, "Reference");
    let reference_info = "Referenece: ";

    for (var i = 0; i < citation.length;i++){
        let citationText = get(citation[i], "citationText");
        let code =  citationText.substring(citationText.lastIndexOf("<code>"), citationText.indexOf("</code>")).replace("<code>", "");
        if (!ivProductInfoCodes.has(code)){
            ivProductInfoCodes.add(code);
            let author = citationText.replace("<code>","").replace("</code>","").replace("<pubmedID>","pubmedID : ").replace("</pubmedID>","");
            var obj={reference_info:reference_info,author:author              
            };

            if(code === refid){
                ivProductInfoReferences.push(obj);
            }
        }
    }

    return ivProductInfoReferences.map((referenceObject, index) => {
        return referenceObject.reference_info
        + (referenceObject.author ? referenceObject.author : "");
    });
}

// Tooltip data for single formulation
export function parseDataReferencesOld(text, refid){
    //below is case for when page not fully mounted yet, deals with undefined/empty text
    if((typeof text).localeCompare("string") === 0){
        return <div/>
    }

    let ivProductInfoReferences=[];
    let ivProductInfoCodes=new Set();
    let referenceSections = get(text, "contentTree.ProductData.References")
    let referenceArray = getArray(referenceSections, "Reference");
    let reference_info = "Referenece: ";

    for (var i = 0; i < referenceArray.length;i++){
        let code = get(referenceArray[i], "Code");
        if (!ivProductInfoCodes.has(code)){
            ivProductInfoCodes.add(code);
            let author = get(referenceArray[i], "Author");
            let title = get(referenceArray[i], "Title");
            let journal = get(referenceArray[i], "Journal");
            let year = get(referenceArray[i], "Year");
            let volume = get(referenceArray[i], "Volume");
            let pages = get(referenceArray[i], "Pages");
            var obj={reference_info:reference_info,
                code:code,
                author:author,
                title:title,
                journal:journal,
                year:year,
                volume:volume,
                pages:pages
            };

            if(obj.code === refid){
                ivProductInfoReferences.push(obj);
            }
        }
    }

    return ivProductInfoReferences.map((referenceObject, index) => {
        return referenceObject.reference_info
        +"["+ (referenceObject.code ? referenceObject.code : "")
        + "] " + (referenceObject.author ? referenceObject.author : "")
        + ": " + (referenceObject.title ? referenceObject.title : "")
        + " " + (referenceObject.journal ? referenceObject.journal : "")
        + " " + (referenceObject.year ? referenceObject.year : "")
        + " " + (referenceObject.volume ? referenceObject.volume : "")
        + " " + (referenceObject.pages ? referenceObject.pages : "");
    })
}

export function parseTradeNamesText(tradeNameData,ivProductInfo,productDataId,tradenamesArray){
    let sectionText = (String(get(tradeNameData,"Name",'')));
    // eslint-disable-next-line
    sectionText = sectionText.replace(/    /g, "INDENT"); //all leading and trailing whitespace is implicitly trimmed, removing all of the indents that are in the text, on the .split() call
    console.debug(sectionText);
    ivProductInfo.push(<div>
    {sectionText.split("\n").map(function(item, index){
        if(item.endsWith(":")){
            return (<div key={index} className="inline-header-iv-product-info accordion-content-tpn">
                    {item}
                </div>
            )
        }
        else if(item.includes("INDENT")){
            item = item.replace(/INDENT/g, "");
            return (<div key={index} className="full-indent-iv-product-info accordion-content-tpn">
                    {item}
                </div>
            )
        }
        else{
            let tname=get(tradeNameData,"Id",'')+1;
            tradenamesArray.push(tname+". "+item);
            let buttonid=get(tradeNameData,"Id",'')+''+productDataId;
            return (<div key={index} className="accordion-content-tpn">
                <botton class="iv-compat-prep-and-storage-drug-text" style={{ cursor: "pointer" }}  onClick={() => scrollToTarget(buttonid)}>{get(tradeNameData,"Id",'')+1}{". "+item}</botton>

                </div>
            )
        }
    })}
    </div>)
}

export function getMultiformulationReferenceIdsForEachSection(text){
    //input will be 'document' from json
    let referencesBySection={};
    let referenceSections = get(text, "References");
    let referenceArray = getArray(referenceSections, "Reference");

    for (let i = 0; i < referenceArray.length;i++) {
        let field = get(referenceArray[i], "Field");
        let code = get(referenceArray[i], "Code");
        if (field in referencesBySection) {
            referencesBySection[field].push(code);
        } else {
            referencesBySection[field] = [code];
        }
    }
    return referencesBySection
}

export function parseFormulationReferencesController(text, showDocument=f=>f){
    let isOldContent = checkOldContentOrNewContent(text);
    if(isOldContent === true){
        return parseFormulationReferencesOld(text, showDocument=f=>f);
    }else{
        return parseFormulationReferences(text,showDocument=f=>f);
    }
}

// Display the consolidated references at bottom for  formulation
export function parseFormulationReferencesOld(text, showDocument=f=>f) {
    // below is case for when page not fully mounted yet, deals with undefined/empty text
    console.debug(text)
    if((typeof text).localeCompare("string") === 0){
        return <div/>
    }

    let ivProductInfoReferences=[];
    let ivProductInfoCodes = new Set();
    let referenceSections = get(text, "contentTree.ProductData.References")
    let referenceArray = getArray(referenceSections, "Reference");

    for (var i = 0; i < referenceArray.length;i++){
        let code = get(referenceArray[i], "Code");
        if (!ivProductInfoCodes.has(code)) {
            ivProductInfoCodes.add(code);
            let author = get(referenceArray[i], "Author");
            let title = get(referenceArray[i], "Title");
            let journal = get(referenceArray[i], "Journal");
            let year = get(referenceArray[i], "Year");
            let volume = get(referenceArray[i], "Volume");
            let pages = get(referenceArray[i], "Pages");
            var obj={code:code,
                author:author,
                title:title,
                journal:journal,
                year:year,
                volume:volume,
                pages:pages
            };
            ivProductInfoReferences.push(obj);
        }
    }
    return ivProductInfoReferences.map( (referenceObject, index)=> {
        return <div className="productInfoReferenceDiv" key = {referenceObject.code}>
            {referenceObject.code} : {referenceObject.author} <pre class= "prespace">{referenceObject.title}</pre>. {referenceObject.journal} {referenceObject.year} {referenceObject.volume} {referenceObject.pages}
        </div>
    })
}

// Display the consolidated references at bottom for  formulation
export function parseFormulationReferences(text, showDocument=f=>f) {
    // below is case for when page not fully mounted yet, deals with undefined/empty text
    console.debug(text)
    if((typeof text).localeCompare("string") === 0){
        return <div/>
    }

    let ivProductInfoReferences=[];
    let ivProductInfoCodes = new Set();
    let referenceSections = get(text, "contentTree.ProductData.ReferencesSection");
    let citation = getArray(referenceSections,"citation");

    for (var i = 0; i < citation.length;i++){
        let citationText = get(citation[i], "citationText");
        let code =  citationText.substring(citationText.lastIndexOf("<code>"), citationText.indexOf("</code>")).replace("<code>", "");
        if (!ivProductInfoCodes.has(code)){
            ivProductInfoCodes.add(code);
            let author = citationText.replace("<code>","").replace("</code>","").replace("<pubmedID>","pubmedID : ").replace("</pubmedID>","");
            var obj={code:code,author:author              
            };
            ivProductInfoReferences.push(obj);
        }
    }
    ivProductInfoReferences.sort((a, b) => a.code.localeCompare(b.code));

    return ivProductInfoReferences.map( (referenceObject, index)=> {
        return <div className="productInfoReferenceDiv" key = {referenceObject.code}>
            {referenceObject.author}
        </div>
    })
}

const extractContentFromParas = (sectionElement) => {
    console.debug("Inside extractContentFromParas for section: ", sectionElement);
    sectionElement = sectionElement.replaceAll("INDENT","").replaceAll("\n","");
    // Find the index of the last space
    let lastIndex="";
    if(sectionElement.endsWith("  ")){
     lastIndex = sectionElement.lastIndexOf("  ");
      // Replace the last space with a specific character or string
      sectionElement = sectionElement.substring(0, lastIndex);
    }

    // Create a temporary container to ensure sectionElement is wrapped in a root element
    const tempContainer = document.createElement('div');

    // Check if sectionElement is a string or an actual DOM element
    if (typeof sectionElement === 'string') {
      tempContainer.innerHTML = `<root>${sectionElement}</root>`;
    } else if (sectionElement && sectionElement.innerHTML) {
      tempContainer.innerHTML = `<root>${sectionElement.innerHTML}</root>`;
    } else if (sectionElement && sectionElement.textContent) {
      tempContainer.innerHTML = `<root>${sectionElement.textContent}</root>`;
    } else {
      console.debug("sectionElement has no innerHTML or textContent.");
      return '';
    }

    const rootElement = tempContainer.firstChild;
    if (!rootElement) {
      console.debug("rootElement is null.");
      return '';
    }

    // Initialize an array to store the content of suitable <para> elements
    let contentArray = [];

    // Extract the text content directly within the root element (excluding child elements)
    const directTextContent = Array.from(rootElement.childNodes)
      .filter(node => node.nodeType === Node.TEXT_NODE)
      .map(node => node.textContent.trim())
      .join(' ')
      .trim();

    if (directTextContent.length > 0) {
      contentArray.push(directTextContent);
    }
    const titles = rootElement.getElementsByTagName('strong');
    for(let title of titles){
        if(title.textContent){
       contentArray.push(`<h6 style="margin-left: 10px">${title.textContent}</h6>`);
       }
    }
    // Select all <para> elements within the root element
    const paras = rootElement.getElementsByTagName('p');

    // Iterate over each <para> element
    for (let para of paras) {
      // Select all <xref> elements within the current <para>
      const xrefs = para.getElementsByTagName('xref');

      // Get the full text content of the current <para> element
      const paraText = para.textContent.trim();
      let pattern = /\[\d+\]/g;

      // Find all matches of the pattern in the string
      let matches = paraText.match(pattern);
      
      // Check if the string contains only occurrences of the pattern
      let containsOnlyPattern = matches && matches.join("") === paraText;

      // Check if the <para> contains any meaningful text
      if (paraText.length > 0 && !containsOnlyPattern) {
        // Concatenate text content of all <xref> elements
        let xrefText = '';
        for (let xref of xrefs) {
          xrefText += xref.textContent.trim();
        }
  
        // If the text content is more than just the <xref> text or there's meaningful text with/without <xref>
        if (xrefs.length === 0 || paraText !== xrefText) {
          contentArray.push(`<p>${para.innerHTML.trim()}</p>`);
        }
      }
    }
    const list = rootElement.getElementsByTagName("ul");
    for (let li of list) {
      const items = li.getElementsByTagName("li");
      if (items.length > 0) {
        contentArray.push(`<ul>`);
        for (let item of items) {
          contentArray.push(`<li>${item.textContent}</li>`);
        }
        contentArray.push(`</ul>`);
      }
    }
    // Join all suitable contents with newlines and return
    const result = contentArray.join('\n');
    console.debug("Final contentArray result: ", result);
    return result.replaceAll("INDENT","").trim();
}

const processText = (item, text,tooltip) => {
    // Regular expression to match all <xref> tags and capture the number inside
    const xrefRegex = /<xref[^>]*>\[(\d+)\]<\/xref>/g;

    // Replace each <xref> tag with an <a> tag with the desired attributes
    const processedText = item.replace(xrefRegex, (match, xrefCode) => {
        const tooltipText = breakLongLinesForTooltip(
           parseDataReferences(text, xrefCode)[0],
            DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS
          );
      return `[<a
              data-tooltip-id="${tooltip}"
              data-tooltip-float="true"
              data-tooltip-html="${tooltipText}"
          >${xrefCode}</a>]`
    });

    return processedText;
  };

const IVProductInfoPage = connect(
    mapStateToProps,
    mapDispatchToProps
) (ivProductInfoPresentation);

export default IVProductInfoPage;
