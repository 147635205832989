/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment, Component} from 'react';
import {connect} from "react-redux";
import {get} from "../../utils";
import {LogoutModalPopup} from "./LogoutModalPopup";
import {SubscriptionModalPopup} from "./SubscriptionModalPopup";
import {makeLogoutThunk} from "../../pages/LogoutPage/logout-page-redux";
import {Route} from 'react-router-dom';
import {
    LOGIN_PAGE_GLOBAL_STATE_NAME,
    OVERFLOW_MENU_GLOBAL_STATE_NAME,
    makeSetLogoutModalAction,
    makeSetSubscriptionModalAction
} from "./common-overflow-redux"
import SubscriptionNotification from "../subscription/SubscriptionNotification";
import ErrorModalPopup from "../error/ErrorModalPopup";
import { makeSetErrorModalAction } from './common-overflow-redux/common-overflow-redux';

const mapStateToProps = current_state => {
    const state_results = {
        show_logout_modal_flag: get(current_state, OVERFLOW_MENU_GLOBAL_STATE_NAME+".show_logout_modal_flag", false),
        show_subscription_modal_flag: get(current_state, OVERFLOW_MENU_GLOBAL_STATE_NAME+".show_subscription_modal_flag", false),
        subscription_state: get(current_state, LOGIN_PAGE_GLOBAL_STATE_NAME+'.mdxAuthenticationJson', {}),
        unsubscribed_module: get(current_state, 'subscription.unsubscribed_module', ''),
        showErrorPopupProps: get(current_state, OVERFLOW_MENU_GLOBAL_STATE_NAME + '.showErrorPopupProps', '')
    }
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onShowLogoutModalEvent: (show_chat_logout_modal=false) => {
            dispatch(makeSetLogoutModalAction(show_chat_logout_modal))
        },
        onShowSubscription: (show_subscription_modal=false) => {
            dispatch(makeSetSubscriptionModalAction(show_subscription_modal))
        },
        onLogout: (logoutPageProps) => {
            dispatch(makeLogoutThunk(logoutPageProps));
        },
        onPageLoaded: () => {
            dispatch(makeSetErrorModalAction({show_modal: false}))
        }
    }
}

export class CommonOverflowModalPopupsPresentation extends Component {

    constructor(props) {
        super(props);
        this.logoutHandler=this.logoutHandler.bind(this);
         this.props.onPageLoaded();
    }
    logoutHandler(localProps){
        this.props.onShowLogoutModalEvent(false);
        this.props.onLogout(localProps);
    }

    render() {
        return (
            <Fragment>
                {
                    this.props.show_logout_modal_flag===true
                    &&
                    <Route render={(routeProps) => <LogoutModalPopup
                        logoutHandler={() => this.logoutHandler(
                            {
                                ...this.props,
                                ...routeProps
                            }
                        )}
                        onCloseHandler={() => this.props.onShowLogoutModalEvent(false)}
                    />} />
                }
                {
                    this.props.show_subscription_modal_flag===true
                    &&
                    <SubscriptionModalPopup
                        subscription_info={this.props.subscription_state}
                        onCloseHandler={() => this.props.onShowSubscription(false)}
                    />
                }
                {
                    this.props.unsubscribed_module
                    &&
                    <SubscriptionNotification props={this.props}/>
                }
                {
                  this.props.showErrorPopupProps.show_modal
                  &&
                  <ErrorModalPopup props={this.props.showErrorPopupProps} />
                }
            </Fragment>
        )
    }
}

export const CommonOverflowModalPopups = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommonOverflowModalPopupsPresentation)

export default CommonOverflowModalPopups;