/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

// TOOD: error message on detecting duplicate drugs / solutions

import {
  immutable_set,
  enhanced_get,
  get,
  linear_search,
  binary_search,
  restrict_data_array_range,
  compute_checked_entry_flags,
  remove_array_index,
  FULL_SEARCH_RANGE,
  data_array_sort, IV_COMPATIBILITY_ERROR_TYPE
} from "../../../utils";
import {handleErrorStatuses} from "../../../utils/internal-error-handler";
import {
  ERROR_STATUS,
  LOADING_DESCRIPTION, LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction,
  NONE_STATUS
} from "../../response-loading/redux/response-loading-redux";

// indices into picklist_state.data_array and picklist_state.picklist_display_array rows ...
export const DATA_LONG_NAME_INDEX   = 0    // index of picklist entry long name
export const DATA_DOCUMENT_ID_INDEX = 1    // index of picklist entry document id
export const DATA_UPPER_NAME_INDEX  = 2    // index of picklist entry long_name.toUpperCase()
export const DATA_ARRAY_INDEX_INDEX = 3    // index of picklist entry index within picklist_state.data_array

// Definition of picklist state fields ...
export const PICKLIST_INITIAL_STATE = {
    // array of *sorted* picklist data; case insensitive sorte by long_name.toUpperCase()
    // each row item is an array: [long_name, document_id, long_name.toUpperCase(), data_array_index]
    data_array: [],

    // each row is a reference to a data_array row: [long_name, document_id, long_name.toUpperCase(), data_array_index]
    // starts with data_array entries whose initial letters match search_text
    // followed by other data_array entries that *contain* the search_text
    picklist_display_array: [],

    // *sparse* array of true flags at data_array indices where we need to show a checkmark in the pulldown
    checked_entry_flags: [],

    // picklist text entered by user
    search_text: '',

    // if true, then display "No results found" message in pulldown
    no_results_found_flag: false,

    // list of data_array items rows selected by user;
    // sorted by long_name.toUpperCase()
    // if these items occur in data_array[i], then checked[i] should also be true
    // TODO: is OK if selected items contain duplicate document_id values?
    selected_items: [],

    // warning text about selecting items with duplicate document_ids
    warning_text: ''
}

/**
 * Reset the data_array in the picklist state to an empty state.
 * This can happen right before loading new pulldown data.
 * </br>
 * Note that the picklist_state.selected_items is not cleared
 * </br>
 * WARNING: new_state will be *MODIFIED*; never call this function on the old_state in a reducer;
 * never modify the old_state in a reducer.
 * @param new_state
 */
export const reset_data_array = new_state => {
    new_state.data_array = []
    new_state.picklist_display_array = []
    new_state.checked_entry_flags = []
    new_state.no_results_found_flag = false
    new_state.only_load_data_once_flag = false
    new_state.warning_text = ''
}

/**
 * Import a data_array from a wordwheel RestApi and return
 * a case insenstive sorted data_array.
 * Sorted by the item_name.toUpperCase() column of each row.
 *
 * Some rows of the input data_array have item_names that are a semi-colon
 * delimited list of synonyms. These will be separate rows in the
 * returned sorted data_array.
 * @param new_data_array each row format: [item_name, document_id] where item_name may
 * be a semi-colon separated list of synonyms
 * @returns {[]} data_array sorted on item_name.toUpperCase(); each row format:
 * [item_name, document_id, item_name.toUpperCase(), row_index]
 */
export const import_data_array = (new_data_array=[] )=> {
    // DE75451: clone the data array and perform case insensitive sort it
    let sorted_data_array = [];
    for (let i=0;i<new_data_array.length;i++) {
        let [long_names, document_id] = new_data_array[i];

        // compose a document_id if one is missing
        if (document_id===undefined||document_id==='') {
            // composed document_id is string version of index within new_data_array
            document_id = ''+i
        }

        // look for composite long_names, like "Ringer's Lactate;LR - 5%;Lactated Ringers"
        // add a row to sorted_data_array for each component ...
        const split_names = long_names.split(';');
        for (let j=0;j<split_names.length;j++) {
            const item_name = split_names[j].trim();
            if (item_name!=='') {
                // add new row to sorted_data_array;
                // the fourth column, data_array_index, will be computed later
                sorted_data_array.push([item_name, document_id, item_name.toUpperCase(), 0])
            } //endif item
        } //endfor j
    } //endfor i

    // sort data_array by the item_name.toUpperCase() column in each row
    data_array_sort(
        sorted_data_array,
        DATA_UPPER_NAME_INDEX
    )
    let sorted_data_array_output = []
    let output_index = 0
    // update the final data_array index value in each row
    for (let i=0;i<sorted_data_array.length;i++) {
        let output_name = ""
        if(output_index > 0){
            output_name = sorted_data_array_output[output_index-1][DATA_UPPER_NAME_INDEX]
        }
        const new_name = sorted_data_array[i][DATA_UPPER_NAME_INDEX]
        if(output_name !== new_name){
            sorted_data_array_output[output_index] = sorted_data_array[i]
            sorted_data_array_output[output_index][DATA_ARRAY_INDEX_INDEX] = output_index
            output_index++
        }
    }
    return sorted_data_array_output
}

/**
 * Given a data array sorted by uppercase names,
 * select the items displayed in the picklist pulldown based
 * on containing the (uppercased) search_text
 *
 * The first portion of the returned picklist display array are the data_array rows that
 * actually start with the search_text, followed by other data_array items that *contains*
 * the search_text.  Both portions are in alphabetical order.
 *
 * @param data_array format for each row [item_name, document_id, item_name.toUpperCase(), index within data_array]
 * @param search_text will be upper cased during the selection operation
 * @returns {[]} data_array rows selected from data_array for
 * display in a picklist pulldown;
 * row format is: [item_name, document_id, item_name.toUpperCase(), index within data_array]
 */
export const compute_picklist_display_array = (
    data_array = [],
    search_text = ''
) => {
    // search for the upper case version of search_text
    const search_text_upper = search_text.toUpperCase()

    // will be returned to caller
    let picklist_display_array = []

    // no picklist entries if no data_array rows or if no search_text
    if (search_text_upper===''||data_array.length===0) {
        return picklist_display_array
    }

    // first search for data_array rows that start with search_text:
    const [start_index, end_index] = restrict_data_array_range(
        search_text_upper,
        data_array,
        FULL_SEARCH_RANGE,
        DATA_UPPER_NAME_INDEX
    )

    // display data_array rows that start with search_text
    for (let i=start_index; i<end_index;i++) {
        picklist_display_array.push(data_array[i])
    }

    // display data_array rows before start_index that contain search_text
    for (let i=0;i<start_index;i++) {
        const row = data_array[i]
        if (row[DATA_UPPER_NAME_INDEX].indexOf(search_text_upper)>=0) {
            picklist_display_array.push(row)
        }
    }

    // display data_array rows from end_index to the end that contain search_text
    for (let i=end_index;i<data_array.length;i++) {
        const row = data_array[i]
        if (row[DATA_UPPER_NAME_INDEX].indexOf(search_text_upper)>=0) {
            picklist_display_array.push(row)
        }
    }

    return picklist_display_array
}

/**
 * Get the selected_items from the picklist state
 * @param iv_picklist_state
 * @returns {*[]} returns a NEW array of selected data items; NOTHING must be altered in the
 * array itself or any items that it contains
 */
export const get_selected_items = iv_picklist_state => [...get(iv_picklist_state,'selected_items', [])]

////////////////////////////////////////////////
// state query functions ...
////////////////////////////////////////////////

/**
 * Should the "No results found" message be displayed in the pulldown menu?
 * @param iv_picklist_state
 * @returns {boolean} true if we need to show "No results found" message
 */
export const should_show_no_results_found_message = iv_picklist_state => iv_picklist_state.no_results_found_flag===true

/**
 * Should the pulldown menu be open?
 * @param iv_picklist_state
 * @returns {boolean} true if the pulldown menu should display opened
 */
export const is_pulldown_open = iv_picklist_state =>
    iv_picklist_state.search_text!==''&&iv_picklist_state.search_text!==undefined

/**
 * How many items are visible in the  pulldown menu
 * @param picklist_display_array array of [item_name, document_id, uppercase name, data index]
 * @returns {number} count of visible items in the pulldown
 */
export const get_active_data_count = ({ picklist_display_array = [] }) => {
    return picklist_display_array.length
}

// redux action constants
export const A = {
    SET_SEARCH_TEXT_ACTION: "IV_PICKLIST_SET_SEARCH_TEXT_ACTION",
    SET_DATA_ARRAY_ACTION: "IV_PICKLIST_SET_DATA_ARRAY_ACTION",
    SET_DUPLICATE_ITEM_WARNING_TEXT: "IV_PICKLIST_SET_DUPLICATE_ITEM_WARNING_TEXT",
    SELECT_ITEM_ACTION: "IV_PICKLIST_SELECT_ITEM_ACTION",
    DESLECT_SELECTED_ITEM_ACTION: "IV_PICKLIST_DESLECT_SELECTED_ITEM_ACTION",
    DESELECT_SELECTED_ITEM_WITHOUT_WORD_WHEEL_DATA_ACTION:
        "IV_PICKLIST_DESELECT_SELECTED_ITEM_WITHOUT_WORD_WHEEL_DATA_ACTION",
    CLEAR_SELECTED_ITEMS: "IV_PICKLIST_CLEAR_SELECTED_ITEMS",
    MANUALLY_SET_ITEM_ACTION: "IV_PICKLIST_MANUALLY_SET_ITEM_ACTION",
    SAVE_SELECTED_ITEM_ACTION: "SAVE_SELECTED_ITEM_ACTION",
    SET_DATA_LOADING_FLAG_ACTION: "IV_PICKLIST_SET_DATA_LOADING_FLAG_ACTION"
}

/**
 * Nested reducer for handling the state changes for
 * a picklist instance on a containing page.
 * </br>
 * Here is how the handle_picklist_action() function is integrated
 * in the DrugInteractionsSearchPage reducer
 * <code>
     switch(action.type) {
        case A.SEARCH_RESULTS:
            return wordweelResponseReducer(current_state,action)
        case A.CLEAR_SEARCH_STATE:
            return clearDrugInteractionSearchReducer(current_state, action)
        default:
            const new_state = handle_picklist_action(
                DRUG_INTERACTION_PAGE_NAME,
                current_state,
                action
            )
            if(new_state){
                return new_state;
            }
            return current_state
    } //endswitch
 *     </code>
 * @param page_name name of the containing page as found in app-state-redux.js
 * @param current_state state input for a reducer
 * @param action reducer action
 * @returns {a|null} null if handle_picklist_action() determines that no changes are needed in
 * current_state; otherwise return the new_state having picklist related changes.
 * If a null value is returned, then the outer reducer should return current_state
 */
export const handle_picklist_action = (page_name='',current_state={}, action={}) => {
    switch (action.type) {
        // only handle iv picklist related actions
        case A.SET_SEARCH_TEXT_ACTION:
        case A.SET_DATA_ARRAY_ACTION:
        case A.SELECT_ITEM_ACTION:
        case A.DESLECT_SELECTED_ITEM_ACTION:
        case A.DESELECT_SELECTED_ITEM_WITHOUT_WORD_WHEEL_DATA_ACTION:
        case A.MANUALLY_SET_ITEM_ACTION:
        case A.SET_DUPLICATE_ITEM_WARNING_TEXT:
        case A.CLEAR_SELECTED_ITEMS:

            // only handle iv picklist actions that belong to the parent page
            if (action.page_name!==page_name) {
                return null
            }

            // only handle iv picklist actions that belong to the picklist_id instance on the page
            const current_iv_picklist_state = get(current_state, action.picklist_id)
            if (current_iv_picklist_state === undefined) {
                return null
            }

            // derive new state by running reducers with this action
            const new_iv_picklist_state = picklistDispatch(current_iv_picklist_state, action)
            if (new_iv_picklist_state === current_iv_picklist_state) {
                // if nothing changed ... return the existing state
                return null
            } else {
                // something in the picklist state changed ... set new picklist state into the page's parent state ...
                return immutable_set(current_state, action.picklist_id, new_iv_picklist_state)
            }

        case A.SAVE_SELECTED_ITEM_ACTION:
          return saveSelectedItemsReducer(current_state, action);
        default:
            // action.type not for iv picklist
            return null
    }
}

// REDUX Actions ...
export const makeSetSearchTextAction = (search_text='', picklist_id='', page_name='') => ({
    type: A.SET_SEARCH_TEXT_ACTION,
        search_text,
    picklist_id,
    page_name
})

/**
 * Manually set selected_items in this iv_picklist when the IVCompatibilitySearch page is initialized
 * @param selected_items_input array of selected items;
 * each row has format: [item_name, document_id]
 * @param picklist_id
 * @param page_name
 * @returns {{picklist_id: string, selected_items_input: *[], page_name: string, type: string}}
 */
export const makeManuallySetItemAction = (selected_items_input=[], picklist_id='', page_name='') => ({
    type: A.MANUALLY_SET_ITEM_ACTION,
    selected_items_input,
    picklist_id,
    page_name
});

export const makeSaveSelectedItemsAction = (selected_items_input=[], picklist_id='', page_name='') => ({
    type: A.SAVE_SELECTED_ITEM_ACTION,
    selected_items_input,
    picklist_id,
    page_name
});


export const makeSetDataArrayAction = (
    data_array=[],
    picklist_id='',
    page_name='',
    only_load_data_once_flag = false
) => ({
    type: A.SET_DATA_ARRAY_ACTION,
    data_array,
    picklist_id,
    page_name,
    only_load_data_once_flag
})

export const makeDisplayDuplicateItemWarningTextAction = (warning_text='', picklist_id='', page_name='') => ({
    type: A.SET_DUPLICATE_ITEM_WARNING_TEXT,
    warning_text,
    picklist_id,
    page_name
})

export const makeSelectItemAction = (picklist_display_index = -1, picklist_id='', page_name='') => ({
    type: A.SELECT_ITEM_ACTION,
    picklist_display_index,
    picklist_id,
    page_name
})

export const makeDeselectSelectedItemAction = (data_array_index, picklist_id='', page_name='') => ({
    type: A.DESLECT_SELECTED_ITEM_ACTION,
    data_array_index,
    picklist_id,
    page_name
})

export const makeDeselectSelectedItemWithoutWordWheelDataAction = (drug_id, picklist_id, page_name) => ({
    type: A.DESELECT_SELECTED_ITEM_WITHOUT_WORD_WHEEL_DATA_ACTION,
    drug_id,
    picklist_id,
    page_name
})

export const makeClearSelectedItemsAction = (picklist_id='', page_name='') => ({
    type: A.CLEAR_SELECTED_ITEMS,
    picklist_id,
    page_name
})

// redux-thunk THUNKs

/**
 * Dispatch this thunk when initializing the IV Search Tool page with
 * drug and solution names from the initial_params in the page's URL query string
 * @param initial_selection_names each name row is also an array: [item_name, document_id]
 * @param fetch_data_array_promise function for fetching raw data array from mdx-services;
 * always returns a Promise
 * @param page_name name of the parent page from combineReducers
 * @param picklist_id name of ivpicklist instance on the page
 * @returns a Promise that is resolved with the data_array from mdx-services
 */
export function makeSetInitalSelectionsThunk(
    initial_selection_names = [],
    fetch_data_array_promise,
    page_name = '',
    picklist_id = '',
    wordWheelName,
    props,
    login_page
) {
    return (dispatch, getState) => {

        // get the data_array from the state belonging to this instance of the ivpicklist
        const data_array = enhanced_get(
            getState(), // getState() returns the raw redux store state
            // picklist state is nested inside of the page state ...
            [page_name, picklist_id, 'data_array'],
            []
        )

        // see if we have already loaded data_array from mdx-services
        if (data_array.length!==0) {
            // yes, data_array already loaded ...
            // all we need to do is set the selected items
            dispatch(
                makeManuallySetItemAction(
                    initial_selection_names,
                    picklist_id,
                    page_name
                )
            )

            // always return an empty Promise so that Jest code can call .then(done())
            return Promise.resolve('')
        } else {
            // we need to load data_array from mdx-services *before* setting the selected items
            // we need to load new wordwheel data so initiate an asynchronous REST API call to mdx-services (via mdx-proxy)
            return fetch_data_array_promise('') // always returns a Promise
                .then(response => {
                  const status = response.status
                  if(status === 200) {
                    dispatch(makeResponseLoadingAction(wordWheelName, NONE_STATUS, LOADING_DESCRIPTION));
                    return response.json();
                  } else {
                    dispatch(makeResponseLoadingAction(wordWheelName, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                    let mutable_props = {...props};
                    mutable_props.error_type = IV_COMPATIBILITY_ERROR_TYPE
                    handleErrorStatuses(status, mutable_props, dispatch, getState)
                  }
                })
                .then(new_data_array => {
                    // SUCCESS ... update the current state with the new data_array of pulldown menu items

                    // we got a new data_array .... update this ivpicklist instance's state
                    if (new_data_array && new_data_array.jsonDrugArray) {
                      dispatch(makeSetDataArrayAction(
                        new_data_array.jsonDrugArray,
                        picklist_id,
                        page_name
                      ))

                      // now we can set the selected items for this picklist
                      dispatch(makeManuallySetItemAction(
                        initial_selection_names,
                        picklist_id,
                        page_name
                      ))
                    }
                    // return the new data_array for use in Jest tests
                    return new_data_array
                })
              // .catch() <= errors will be caught and handled on IVCompatibilitySearchPage
        } //endelse data_array.length
    } //endfunction
}

//
// REDUCERS ...
// good writeup on using "nested reducers" ...
// https://medium.com/@irmantaszenkus/nested-reducers-f97a597000f1
//
export function trimLeft(string) {
    if (string===undefined) return string;

    return string.replace(/^\s+/,"");
}

/**
 * User typed and changed the picklist search text
 * @param iv_picklist_state current state of this ivpicklist instance
 * @param action_search_text new search text as seen in this picklists input field
 * @returns {a|*} new ivpicklist state
 */
export const setSearchTextReducer = (
    iv_picklist_state,
    {search_text: action_search_text = ''}
) => {
    //
    // *ASSUME* that this reducer can never be called
    // while iv_picklist_state.data_loading_flag===true
    // .... the GUI should *disable* the text entry field while data is loading ...
    //

   // TODO: figure out hwo to handle leading spaces in action_search_text

    const {
        data_array,
        search_text: current_search_text = ''
    } = iv_picklist_state

    // use upper case for search text change comparisons
    const new_search_text = trimLeft(action_search_text.toUpperCase()) // get rid of leading spaces
    const old_search_text = current_search_text.toUpperCase()

    // exit if nothing changed ...
    if (new_search_text === old_search_text) {
        return iv_picklist_state
    }

    // always update state's search_text ...
    let new_state = immutable_set(
        iv_picklist_state,
        'search_text',
        action_search_text
    )

    // if user started typing in empty search field, and there currently is no data_array,
    // *assume* that data_array is still in the process of being loaded by the makeSetInitalSelectionsThunk()
    if (new_search_text !== '' && data_array.length === 0) {
        new_state.no_results_found_flag = false
        return new_state
    }

    // select the data_array rows that match the search_text;
    // these rows will be displayed in the pulldown menu
    const picklist_display_array = compute_picklist_display_array(
        data_array,
        new_search_text
    )
    new_state.picklist_display_array = picklist_display_array

    // see if we need to display "No results found"
    new_state.no_results_found_flag = picklist_display_array.length===0

    return new_state
}

export const setDuplicateWarningTextReducer = (iv_picklist_state, {warning_text=''}) => {
    let new_state = immutable_set(iv_picklist_state, 'warning_text', warning_text);

    return new_state;
}

/**
 * Sets a new data_array into this ivpicklist's instance's state
 * @param iv_picklist_state redux state for this instance on the page
 * @param new_data_array new data from mdx-services (via mdx-proxy)
 * @returns {a} new iv_picklist state
 */
export const setDataArrayReducer = (
    iv_picklist_state,
    {
        data_array: new_data_array,
    }
) => {
    const {
        search_text,
        selected_items
    } = iv_picklist_state

    // DE75451: clone the data array and perform case insensitive sort it
    let sorted_data_array = import_data_array(new_data_array);

    let new_state = immutable_set(
        iv_picklist_state,
        'data_array',
        sorted_data_array
    )

    // if there is no new data, then just turn off data loading
    if (sorted_data_array.length === 0) {
        reset_data_array(new_state)
        new_state.no_results_found_flag = true
        return new_state
    }

    // scan selected_items to see if any items in the new_data_array need to display a checkmark ...
    const new_checked_entry_flags = compute_checked_entry_flags(
        selected_items,
        sorted_data_array
    )

    new_state.checked_entry_flags = new_checked_entry_flags

    // select the data_array rows that match the search_text;
    // these rows will be displayed in the pulldown menu
    const picklist_display_array = compute_picklist_display_array(
        sorted_data_array,
        search_text
    )
    new_state.picklist_display_array = picklist_display_array

    // see if we need to display "No results found"
    new_state.no_results_found_flag = picklist_display_array.length===0

    // signal that data has been loaded
    return new_state
}


/**
 * Called when user clicks on a picklist item in the pulldown
 * @param iv_picklist_state state of this iv_picklist instance
 * @param picklist_display_index index of <b>pulldown row</b> that was clicked
 * @returns {a|*} new iv_picklist state
 */
export const selectItemReducer = (iv_picklist_state, {picklist_display_index = -1}) => {
    const {
        data_array,
        picklist_display_array,
        checked_entry_flags,
        selected_items
    } = iv_picklist_state

    // check that index is within valid range
    if (picklist_display_index < 0 || picklist_display_index >= picklist_display_array.length) {
        return iv_picklist_state
    }

    //
    const selected_picklist_item = picklist_display_array[picklist_display_index]

    // check to see if picklist_display_array[picklist_display_index] exists ...
    if (selected_picklist_item === undefined) {
        return iv_picklist_state
    }

    // destructure selected row
    const [
        , // selected item_name,
        , // selected document_id,
        selected_upper_name,
        drug_array_index
    ] = selected_picklist_item

    // check to see if this item is already checked
    const already_checked = checked_entry_flags[drug_array_index]===undefined?false:true

    let new_state = iv_picklist_state
    if (already_checked) {
        // if already checked ...
        // then uncheck it ...

        // dup the checked_entry_flags array
        let new_checked_entry_flags = [...checked_entry_flags]

        // delete the "true" value at the selected index
        delete new_checked_entry_flags[drug_array_index]

        // derive new state
        new_state = immutable_set(
            iv_picklist_state,
            'checked_entry_flags',
            new_checked_entry_flags
        )

        // remove item from the selected_items array ...
        const selected_items_index = linear_search(
            selected_upper_name,
            selected_items,
            FULL_SEARCH_RANGE,
            DATA_UPPER_NAME_INDEX
        )
        if (selected_items_index>=0) {
            const new_selected_items = remove_array_index(selected_items, selected_items_index)
            new_state.selected_items = new_selected_items
        }
    } else {
        // not already checked ...

        // give this item a check mark in the pull down list
        let new_checked_entry_flags = [...checked_entry_flags]
        new_checked_entry_flags[drug_array_index] = true
        new_state = immutable_set(
            iv_picklist_state,
            'checked_entry_flags',
            new_checked_entry_flags
        )

        // create a new selected_items entry ...

        // NOTE: iv_picklist_state.selected_items array can *SHARE* rows
        // with iv_picklist_state.data_array since all row data is never modified
        const new_data_item = data_array[drug_array_index]
        let new_selected_items = [...selected_items, new_data_item]

        // sort the selected_items array by item_name.toUpperCase()
        data_array_sort(
            new_selected_items,
            DATA_UPPER_NAME_INDEX
        )

        new_state.selected_items = new_selected_items;
    } //endelse already_checked

    return new_state
}

/**
 * Called when user clicks the X icon on one of the selected item pills
 * @param iv_picklist_state
 * @param data_array_index index of selected item within data_array
 * @returns {a|*}
 */
export const deselectSelectedItemReducer = (iv_picklist_state, {data_array_index = -1}) => {
    const {
        // data_array,
        checked_entry_flags,
        selected_items
    } = iv_picklist_state

    const selected_item_index = linear_search(
        data_array_index,
        selected_items,
        FULL_SEARCH_RANGE,
        DATA_ARRAY_INDEX_INDEX
    )

    if (selected_item_index < 0 || selected_item_index >= selected_items.length) {
        return iv_picklist_state
    }

    const new_selected_items = remove_array_index(selected_items, selected_item_index)
    let new_state = immutable_set(
        iv_picklist_state,
        'selected_items',
        new_selected_items
    )

    let new_checked_entry_flags = [...checked_entry_flags]
    delete new_checked_entry_flags[data_array_index]
    new_state.checked_entry_flags = new_checked_entry_flags

    return new_state
}

export const deselectSelectedItemWithoutWordWheelDataReducer = (iv_picklist_state, drug) => {
    const new_selected_items = get(
        iv_picklist_state,
        'selected_items',
        []
    ).filter(selected_item => selected_item[1] !== get(drug, 'drug_id', ''));

    return immutable_set(
        iv_picklist_state,
        'selected_items',
        new_selected_items
    );
}

export const clearSelectedItemsReducer = (iv_picklist_state, action) => {
    const { selected_items, checked_entry_flags} = iv_picklist_state
    if (selected_items.length===0 && checked_entry_flags.length===0) {
        return iv_picklist_state
    }
    let new_state = immutable_set(iv_picklist_state, 'selected_items',[])
    new_state.checked_entry_flags = []
    return new_state
}

/**
 * Set the selected_items array in this iv_picklist's instance's state;
 * This reducer should be invoked during IVCompatibilitySearch page load,
 * right after the data_array has been fetched from mdx-services (via mdx-proxy)
 *
 * <b>ASSUME</b> that iv_picklist_state.data_array is already loaded and sorted
 *
 * @param iv_picklist_state
 * @param selected_items_input array of newly selected items;
 * each array row has format: [item_name, document_id]
 * @returns {a} new iv_picklist_state
 */
export const manuallySetItemActionReducer = (iv_picklist_state, {selected_items_input = []}) => {

    // create a new selected_items array based on selected_item_names
    const {
        data_array
    } = iv_picklist_state
    let selected_items = []
    let new_checked_entry_flags = []

    // for each newly selected item
    for (let i=0;i<selected_items_input.length;i++) {
        const [item_name] = selected_items_input[i]

       // look up the upper name version of the selected item_name
        const  [found_index, found_flag ] = binary_search(
            item_name.toUpperCase(),
            data_array,
            -1,
            -1,
            DATA_UPPER_NAME_INDEX
        )

        if (found_flag===true) {
            // found it in data_array ...

            // add data_array row to selected_items array
            selected_items.push(data_array[found_index])

            // add a checkmark it this item in the pulldown menu
            new_checked_entry_flags[found_index] = true
        }
    } //endfor i


    // sort the selected_items array by the item_name.toUpperCase() column
    data_array_sort(
        selected_items,
        DATA_UPPER_NAME_INDEX
    )

    // compose a new iv_picklist_state value
    let new_state = immutable_set (
        iv_picklist_state,
        'selected_items',
        selected_items
    )
    new_state.checked_entry_flags = new_checked_entry_flags

    return new_state
}


export const saveSelectedItemsReducer = (current_state, action) => {
  return immutable_set(
    current_state,
    `${action.picklist_id}.selected_items`,
    action.selected_items_input
  )
}

export const setDataLoadingFlagReducer = (
    iv_picklist_state,
        { data_loading_flag = false}
) => {
    return immutable_set(iv_picklist_state, 'data_loading_flag', data_loading_flag)
}

export const picklistDispatch = (
    current_state=PICKLIST_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case A.SET_SEARCH_TEXT_ACTION:
            return setSearchTextReducer(current_state, action)

        case A.SET_DATA_ARRAY_ACTION:
            return setDataArrayReducer(current_state, action)

        case A.SET_DUPLICATE_ITEM_WARNING_TEXT:
            return setDuplicateWarningTextReducer(current_state, action)

        case A.SELECT_ITEM_ACTION:
            return selectItemReducer(current_state, action)

        case A.DESLECT_SELECTED_ITEM_ACTION:
            return deselectSelectedItemReducer(current_state, action)

        case A.DESELECT_SELECTED_ITEM_WITHOUT_WORD_WHEEL_DATA_ACTION:
            return deselectSelectedItemWithoutWordWheelDataReducer(current_state, action)

        case A.CLEAR_SELECTED_ITEMS:
            return clearSelectedItemsReducer(current_state, action)

        case A.MANUALLY_SET_ITEM_ACTION:
            return manuallySetItemActionReducer(current_state, action);

        default:
            return current_state
    } //endswitch
}
