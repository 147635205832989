/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

 import React, { Component, createRef } from 'react';
import { connect } from "react-redux";
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { ToastNotification } from "@carbon/react";
import { get, isEmptyObject, getArray, getDecodedURIComponents, force_array } from "../../../utils";
import IVCompatibilityList from "./iv-summary-components/IVCompatibilityList";
import CommonOverflowModalPopups from "../../../components/common-overflow-menu/CommonOverflowModalPopups";
import {
    CheckboxFilterComponent,
    filterCheckboxItemsUsingFlags
} from "../../../components/checkbox-filter/CheckboxFilterComponent";

import {
    makeIVCompSetDrugsAndSolutionsAction,
    makeIVCompShowUnableToDeleteMessageAction,
    DRUG_NAMES_STATE_PATH,
    SOLUTION_NAMES_STATE_PATH,
    DRUG_SOLUTION_FILTER_GROUP_PATH,
    makeIVCompatibilityThunk,
    makeIvCompatSummaryGoToChatbotActionThunk,
    IV_COMP_ERROR
} from "./iv-compatibility-summary-utils";

import { ivRemoveItem, prepareFilterPanelState, result_types } from "../../../utils/commonIvDiUtils";
import { SelectedItem } from "../../../components/common/SelectedItem";
import {
    getFiltersState,
    getStateFromLocalStorage,
    resetIVDetailsFiltersState
} from "../../../components/checkbox-filter/checkbox-filters-state";
import { computeAllFlagsEnabled } from "../../../components/checkbox-filter/CheckboxFilterPanelHook";
import { CommonFooter } from "../../../components/common/footers/Footer";
import {
  IV_COMPATIBILITY_SUMMARY_PAGE_NAME
} from "../../../components/response-loading/redux/response-loading-redux";

const { ChevronLeft } = require('@carbon/react/icons');

const PAGE_NAME = 'iv_compatibility_page'

export const GLOBAL_DRUG_SOLUTION_FILTER_GROUP_PATH = PAGE_NAME + '.' + DRUG_SOLUTION_FILTER_GROUP_PATH
export const GLOBAL_SOLUTION_NAMES_STATE_PATH  = PAGE_NAME + '.' + SOLUTION_NAMES_STATE_PATH
export const GLOBAL_DRUG_NAMES_STATE_PATH = PAGE_NAME + '.' + DRUG_NAMES_STATE_PATH

const mapStateToProps = state => {
    console.debug('mapStateToProps: state=',state)
    const state_results ={
        drug_solution_filter_group: getArray(state, GLOBAL_DRUG_SOLUTION_FILTER_GROUP_PATH),

        drug_names: getArray(state,GLOBAL_DRUG_NAMES_STATE_PATH),
        solution_names: getArray(state,GLOBAL_SOLUTION_NAMES_STATE_PATH),

        show_unable_to_delete: get(state, 'iv_compatibility_page.show_unable_to_delete', false),
        ivSummaryPageResponseLoading: get(state, `inline_loading.${IV_COMPATIBILITY_SUMMARY_PAGE_NAME}`, {}),
        iv_data: get(state, 'iv_compatibility_page.iv_comp_summary_page', {})
    }
    console.debug("mapStateToProps state_results=",state_results);
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onDisplayIVComp: (drug_names=[], solution_names=[],  props={}) => {
            dispatch(makeIVCompSetDrugsAndSolutionsAction(
                drug_names,
                solution_names
            ))

            const child_props={
                agent_names:drug_names,
                result_types,
                selected_solutions:solution_names,
                props
            }
            dispatch(makeIVCompatibilityThunk(child_props));
        },
        onGoToChatbotPage: (props) => {
            dispatch(makeIvCompatSummaryGoToChatbotActionThunk(props));
        },
        onRemoveDrugName: (drug_name, props) => {
            console.debug("onRemoveDrugName drug_name=",drug_name)
            ivRemoveItem(dispatch, drug_name, props, true);
        },
        onRemoveSolutionName: (solution_name, props) => {
            console.debug("///////////////////////////// onRemoveSolutionName solution_name=",solution_name)
            ivRemoveItem(dispatch, solution_name, props, false);
        },
        onDismissUnableToDeleteMessage: () => {
            console.debug("onDismissUnableToDeleteMessage <<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
            dispatch(makeIVCompShowUnableToDeleteMessageAction(false))
        },
        onShowUnableToDeleteMessage: () => {
            console.debug("onShowUnableToDeleteMessage <<<<<<<<<<<<<<<<<<<<<<<<<<<<<")
            dispatch(makeIVCompShowUnableToDeleteMessageAction(true))
        },
        onUpdateCheckboxFilterDrugsSolutions: (filter_group_array, filter_flags_array, props) => {
            const drugs_and_solutions = filterCheckboxItemsUsingFlags(filter_group_array, filter_flags_array)
            const drug_names = getArray(drugs_and_solutions,'0')
            const solution_names = getArray(drugs_and_solutions,'1')

            // invoke rest api to get modified compatibility data
            const modified_props = {
                agent_names: drug_names,
                result_types,
                selected_solutions: solution_names,
                props
            }
            dispatch(makeIVCompatibilityThunk(modified_props))
        }
    }
}

export class IVCompatibilitySummaryPagePresentation extends Component {
    initial_url_params = {}
    constructor(props) {
        super(props);
        console.debug('IVCompatibilityPagePresentation CTOR props=',props);
        sessionStorage.removeItem("error_url_parameters");
        const loc_search = get(this.props,'location.search');
        this.state = {
          filter_panel_state: [],
          previous_url_params: loc_search
        }
        console.debug('CTOR this.props.location.search=',loc_search)
        if (loc_search) {
            this.initial_url_params = queryString.parse(loc_search)

            console.debug('CTOR+++ initial_url_params=',this.initial_url_params)
            // TODO: fix url search params ...
            // quick answers page location.search contains 'drugName' instead of 'drug_names'
            // this.initial_url_params['drug_names'] = this.initial_url_params['drugName']

            console.debug('CTOR initial_url_params=',this.initial_url_params)
        }
        this.scrollingDivRef = createRef()
        this.gotoChatbotPage = this.gotoChatbotPage.bind(this)
        this.scrollToTopHandler = this.scrollToTopHandler.bind(this);
        this.onChangeFilterPanelState = this.onChangeFilterPanelState.bind(this);
        this.displayIvCompatibilityWithDefaultFilters = this.displayIvCompatibilityWithDefaultFilters.bind(this);
        this.getEnabledFilters = this.getEnabledFilters.bind(this);

        if (localStorage.getItem(IV_COMP_ERROR) === 'true') {
           const filtersState = getFiltersState('ivSubmittedSearch', this.props.drug_solution_filter_group)
           const drugFlagsEnabled = this.getEnabledFilters(this.props.drug_names, filtersState[0]);
           const solutionFlagsEnabled = this.getEnabledFilters(this.props.solution_names, filtersState[1]);
           const filterFlagsArray = [drugFlagsEnabled, solutionFlagsEnabled];
           this.props.onUpdateCheckboxFilterDrugsSolutions(this.props.drug_solution_filter_group, filterFlagsArray);
        }
    } //endconstructor

    getEnabledFilters(namesArray, componentsFiltersState) {
        let enabledFilters = [];
        if (namesArray.length > 0) {
            enabledFilters = componentsFiltersState.toString(2).split('').reverse();
            enabledFilters.push(...Array(namesArray.length - enabledFilters.length).fill('0'));
            enabledFilters = enabledFilters.map(filter => filter === '1');
        }
        return enabledFilters;
    }

    displayIvCompatibilityWithDefaultFilters(url_params) {
        const saved_url_params = url_params
        this.initial_url_params = {}
        const drug_names = getDecodedURIComponents(getArray(saved_url_params, 'iv_drug_names'));
        const solution_names = getDecodedURIComponents(getArray(saved_url_params, 'iv_solution_names'));
        const drugSolutionFilterGroup = this.props.drug_solution_filter_group;
        drugSolutionFilterGroup[0].checkbox_items = drug_names
        drugSolutionFilterGroup[1].checkbox_items = solution_names
        this.props.onDisplayIVComp(
            drug_names,
            solution_names,
            this.props
        )
    }

    gotoChatbotPage() {
        this.props.onGoToChatbotPage(this.props);
    }
    onChangeFilterPanelState(currentPanelState) {
      prepareFilterPanelState(currentPanelState, this);
    }

  scrollToTopHandler(event) {
        console.debug ('IV - Comp ======', event);
        this.scrollingDivRef.current.scrollTop = 0
        console.debug('Drug - this.scrollingDivRef========', this.scrollingDivRef);
    }

    /**
     * When the react gui is displayed,
     * send the first drug points page information request
     */
    componentDidMount() {
        if (!isEmptyObject(this.initial_url_params)) {
            const drugSolutionFilterGroup = this.props.drug_solution_filter_group;
            const allFlagsEnabled = computeAllFlagsEnabled(drugSolutionFilterGroup);
            if (getFiltersState('ivSubmittedSearch', drugSolutionFilterGroup)
                    .every((value, index) => value === allFlagsEnabled[index])
                && Object.values(getStateFromLocalStorage().compatibilities).every(value => value === 0)) {
                this.displayIvCompatibilityWithDefaultFilters(this.initial_url_params);
            }
        }
        resetIVDetailsFiltersState()
        // else if pending_render_state is empty, then we are responding to just
        // a page switch from the ChatbotPage

    }

    componentDidUpdate(){
        // if the current url params is different from previous one, then we are responding
        // and changing the previous_url_params
        if (this.props.location.search !== this.state.previous_url_params) {
          this.setState({
            previous_url_params: this.props.location.search
          });
          const url_params = queryString.parse(this.props.location.search)
          this.displayIvCompatibilityWithDefaultFilters(url_params); 
        }
      }

    render() {
        const sectionName = 'ivSubmittedSearch';
        const drug_names_list = this.props.drug_names;
        const only_one_drug = (drug_names_list.length === 1);
        console.debug("this.props.show_unable_to_delete=", this.props.show_unable_to_delete);

        let linkUrl = this.buildModifySearchURL();
        const linkURL_encoded = encodeURI(linkUrl);
        return <div className="main-container mdx-ivcompatibility-data">
            <div  className="empty-container"></div>
            <div className="main-content">
                <div ref={this.scrollingDivRef} className="mdx-scroll-area mdx-scroll-area-ie">
                    <div className="mdx-header">
                        <Link
                            className='iv-compatibility-modify-link'
                            key='a'
                            to={linkURL_encoded}
                        >
                            <ChevronLeft />
                            <span>Modify Search</span>
                        </Link>
                        <h1 className="iv-compatibility-title-1 search-and-results-page-titles">IV Compatibility <span>Results Summary</span></h1>
                    </div>
                    <div className="mdx-inner-content iv-compat-summary-drug-box">
                        <div className="iv-submitted-search">
                        <div className="search-title">
                        <h5>Submitted Search</h5>
                            <CheckboxFilterComponent
                                checkbox_groups_list={this.props.drug_solution_filter_group}
                                submit_filter_items={
                                    filter_flags => this.props.onUpdateCheckboxFilterDrugsSolutions(
                                        this.props.drug_solution_filter_group,
                                        filter_flags,
                                        this.props
                                    )}
                                disableIcons={this.props.ivSummaryPageResponseLoading.status}
                                invokedSectionName={sectionName}
                                panel_button_click_handler={this.onChangeFilterPanelState}
                                panel_state={this.state.filter_panel_state}
                            />
                        </div>
                        {
                            this.props.show_unable_to_delete
                            &&
                            <ToastNotification
                                className="iv-submitted-search-cannot-delete-toast"
                                lowContrast={false}
                                title={"Cannot Delete!"}
                                caption=''
                                onCloseButtonClick={this.props.onDismissUnableToDeleteMessage}
                                subtitle={"Unable to delete the last drug. Please use the Modify Search link to make drug and solution changes."}
                                kind={"error"}
                            />
                        }
                        <div className="iv-compat-submitted-search-box">
                            <div className="iv-compat-selected-item-container">
                                <span className="iv-compat-selected-item-title">Drug(s):&nbsp;</span>
                                {
                                    drug_names_list.map( (drug_name, index) => {
                                            let drug_product_info_handler = undefined

                                            return <SelectedItem
                                                long_name={drug_name}
                                                key={index}
                                                click_handler={
                                                    only_one_drug
                                                        ?
                                                        () => this.props.onShowUnableToDeleteMessage()
                                                        :
                                                        () => this.props.onRemoveDrugName(drug_name, this.props)
                                                }
                                                info_click_handler={drug_product_info_handler}
                                                waiting_flag={this.props.ivSummaryPageResponseLoading.status}
                                            />
                                        }
                                    )}
                            </div>
                            {
                                Array.isArray(this.props.solution_names)
                                && this.props.solution_names.length !== 0 &&
                                <div className="iv-compat-selected-item-container">
                                <span className="iv-compat-selected-item-title">Solution(s):&nbsp;</span>
                                {
                                    this.props.solution_names.map((solution_name,index) =>
                                        <SelectedItem
                                            long_name={solution_name}
                                            key={index}
                                            click_handler={() => this.props.onRemoveSolutionName(solution_name, this.props)}
                                            waiting_flag={this.props.ivSummaryPageResponseLoading.status}
                                        />
                                    )
                                }
                            </div>
                            }
                        </div>
                        </div>
                        <div className="IVcomp-accordion-container">
                            <IVCompatibilityList
                              panel_button_click_handler={this.onChangeFilterPanelState}
                              filter_panel_state={this.state.filter_panel_state}
                            />
                        </div>
                    </div>
                  <CommonFooter/>
                </div>
            </div>
            <CommonOverflowModalPopups/>
        </div>
    }

    buildModifySearchURL() {
        let url = '/ivCompatibilitySearch?';
        let drug_names = this.props.drug_names;
        let solution_names = this.props.solution_names;
        console.debug(drug_names);
        console.debug(solution_names);
        for (let i=0;i< drug_names.length;i++)
        {
            url+="&iv_drug_names="+encodeURIComponent(drug_names[i]);
        }

        for (let i=0;i< solution_names.length;i++)
        {
            // url+="&allergy_ids="+allergies[i].id;
            url+="&iv_solution_names="+encodeURIComponent(solution_names[i]);
        }
        const loc_search = get(this.props,'location.search');
        const url_params = queryString.parse(loc_search)
        const drug_ids = force_array(url_params.drug_ids);
        if (drug_ids) {
          drug_ids.forEach(drug_id => url += "&iv_drug_ids=" + drug_id)
        }
        const solution_ids = force_array(url_params.iv_solution_ids);
        if (solution_ids) {
          solution_ids.forEach(solution_id => url += "&iv_solution_ids=" + solution_id)
        }

        console.debug(url);
        return url;
    }
}

const IVCompatibilitySummaryPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(IVCompatibilitySummaryPagePresentation)

export default IVCompatibilitySummaryPage
