/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import queryString from 'query-string';

import { get, isEmptyObject } from "./";

export const DCR_PAGE_NAVIGATION = "dcr_pageNavigation";

export const SUBMITTED_SEARCH_TERM = "submittedSearchTerm"
export const SELECTED_SEARCH_TERM = "selectedSearchTerm"

export function trackMessageEvents(message) 
{
    console.debug('inside trackMessageEvents -------------->', message);
    try {
    if (window._satellite) {
//                for (const [key, value] of Object.entries(message)) {
//                    console.debug('message-------------->', key,':',value)
//                    window._satellite.setVar(key, value);
//                }
             window._satellite.track('messageEvents', message);
       }
    } catch(e) {
      console.error("Error while sending data to Adobe ", e);
    }
}

export function addUserId(authenticationJson={})
{
    var metaTagUserName = document.createElement('meta');
    metaTagUserName.name = 'userid';
    metaTagUserName.content = authenticationJson.userId ;
    metaTagUserName.id = 'userid';
    document.getElementsByTagName('head')[0].appendChild(metaTagUserName);
}

export function addParentSiteMetatag(authenticationJson={})
{
    if (get(authenticationJson, 'Client_Config.secured_origins', '')) {
        var metaTagParentSite = document.createElement('meta');
        metaTagParentSite.name = 'parentSite';
        metaTagParentSite.content = get(authenticationJson, 'Client_Config.secured_origins', '');
        metaTagParentSite.id = 'parentSite';
        document.getElementsByTagName('head')[0].appendChild(metaTagParentSite);
    }
}

export function checkForIframe()
{
    var metaTagiFrameIndicator = document.createElement('meta');
    metaTagiFrameIndicator.name = 'isInIframeFlag';
    metaTagiFrameIndicator.id = 'isInIframeFlag';
    metaTagiFrameIndicator.content = 'TRUE';
    document.getElementsByTagName('head')[0].appendChild(metaTagiFrameIndicator);   
}

export function trackPageNavigation(location)
{
    console.debug('Inside trackPageNavigation ', location);
    if (location) {
        let queryParams = {};
        if (location.search) {
            queryParams = queryString.parse(location.search);
        }
        if (location.pathname) {
            let pageName = location.pathname;
            if (pageName.charAt(0) === '/') {
                pageName = pageName.substring(1);
            }
            if (pageName) {
                queryParams['pageName'] = pageName;
            }
        }
        trackAdobeAnalytics(DCR_PAGE_NAVIGATION, queryParams);
    }
}

/**
 * Generic method to send data to Adobe Analytics
 * @param dcrName "dcr" stands for "direct call rule" per Hap Atwal;
 * see https://medium.com/slalom-technology/adobe-launch-rule-creation-strategies-29e879af8dff
 * @param keyValueMap a Javascript object that is treated as a key/value pair map of
 * items to send to Adobe Analytics
 */
export function trackAdobeAnalytics(dcrName, keyValueMap) 
{
    console.debug('ENTER trackAdobeAnalytics: dcrName=', dcrName, ' keyValueMap=',keyValueMap);
    if (isEmptyObject(keyValueMap)) {
        console.debug("ERROR trackAdobeAnalytics: EMPTY keyValueMap!");
        return;
    }
    try {
        if (window._satellite) {
            window._satellite.track(dcrName, keyValueMap);
        } else {
            console.debug('ERROR trackAdobeAnalytics: window._satellite not available');
        }
    } catch(e) {
        console.error("ERROR trackAdobeAnalytics caught Exception:", e);
    }
}

export function trackAccountEvents(accountInfo) 
{
    console.log('inside trackMessageEvents -------------->'+ accountInfo);
    let dimentionMap={}
    if(get(accountInfo, 'customerId')){dimentionMap["OrgID"]= get(accountInfo, 'customerId')}
    if(get(accountInfo, 'issuerUrl')){dimentionMap["IssuerURL"]= get(accountInfo, 'issuerUrl')}
    if(get(accountInfo, 'accountName')){dimentionMap["AccountName"]= get(accountInfo, 'accountName')}
    if(get(accountInfo, 'AuthenticationType'))dimentionMap["AuthenticationType"]= get(accountInfo, 'AuthenticationType')
    trackAdobeAnalytics(DCR_PAGE_NAVIGATION, dimentionMap);
}
