/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import queryString from "query-string";
import {
  get,
  enhanced_get
} from "./getset";
import {
    resetFiltersIVSingle
} from "../components/checkbox-filter/checkbox-filters-state";

import {
    DRUG_POINTS_PAGE_PATH,
    DRUG_POINTS_CALL_IN_PROGRESS_PATH,
    DRUG_POINTS_CALL_ERROR_IN_CHATBOT_PATH,
    DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH
} from "../pages/DrugPointsPage2/drug-points-utils";

import {
    IN_DEPTH_PAGE_PATH,
    DRUGDEX_CALL_IN_PROGRESS_PATH,
    DRUGDEX_CALL_ERROR_IN_CHATBOT_PATH,
    DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH
} from "../pages/InDepthPage2/in-depth-utils";

export const capitalizeFirstLetter = word => {
    return word.substring(0, 1).toUpperCase() + word.slice(1).toLowerCase();
}

export const getDecodedURIComponents = names => {
    return names.map(name => {
        return decodeURIComponent(name);
    });
}

export const DESIRED_LOCATION_HASH = 'mdx_pwa.desired_location_hash';
export const LOGIN_LOCATION_HASH = '#/login';
export const DRUG_POINTS_URL_PREFIX ='#/drugPoints';
export const IN_DEPTH_URL_PREFIX = '#/inDepth';
const TOPIC_ID_PATH = 'topicId';
export const DRUG_INTERACTIONS_TOPIC_ID = 'druginteractions';
export const IV_COMPATIBILITY_TOPIC_ID = 'IVcompatibility';
const DRUG_POINTS_MENU_ELEMENT_ID = 'drug-points-menu';
const IN_DEPTH_MENU_ELEMENT_ID = 'drug-points-indepth-menu';
export const DRUG_POINTS_CONTENT_SET_ID = '100';
export const DRUG_DEX_CONTENT_SET_ID = '31';

export const onClickBackForwardButtonsOnDrugMonographPage = props => {
    const urlHash = window.location.hash;
    if (urlHash.startsWith(DRUG_POINTS_URL_PREFIX)
        || urlHash.startsWith(IN_DEPTH_URL_PREFIX)) {
        let urlParams = {};
        let locSearch = decodeURIComponent(urlHash.split('?')[1]);
        if (locSearch) {
            locSearch = '?' + locSearch
            urlParams = queryString.parse(locSearch)

            const newTopicId = get(urlParams, TOPIC_ID_PATH, '')
            const topicOptions = document.getElementById(DRUG_POINTS_MENU_ELEMENT_ID)
                || document.getElementById(IN_DEPTH_MENU_ELEMENT_ID);

            const documentId = get(urlParams, 'documentId', '');
            const contentSetId = get(urlParams, 'contentSetId', '');

            let mutable_props = { ...props }
            mutable_props.urlParams = urlParams

            if (props.current_state.documentId
                && ((topicOptions
                    && topicOptions.options[topicOptions.selectedIndex].value !== urlParams.topicId)
                || props.current_state.documentId !== urlParams.documentId)) {
                if (newTopicId === DRUG_INTERACTIONS_TOPIC_ID) {
                    urlHash.startsWith(DRUG_POINTS_URL_PREFIX)
                    ? props.onPrepDrugInteractionsQuickAnswers()
                    : props.onPrepareDrugInteractionsFilter();
                } else if (newTopicId === IV_COMPATIBILITY_TOPIC_ID) {
                    resetFiltersIVSingle();
                }

                for (let i = 0; i < topicOptions.options.length; i++) {
                    if (topicOptions.options[i].value === newTopicId) {
                        topicOptions.selectedIndex = i;
                        break;
                    }
                }

                props.onSwitchDrugTopic(
                    newTopicId,
                    null,
                    mutable_props);
            } else if ((urlHash.startsWith(DRUG_POINTS_URL_PREFIX)
                    && hasDrugPointsErrorInChatbot(props, documentId, contentSetId))
                || (urlHash.startsWith(IN_DEPTH_URL_PREFIX)
                    && hasDrugDexErrorInChatbot(props, documentId, contentSetId))) {
                props.onSwitchDrugTopic(newTopicId, null, props);
            }
        }
    }
}

export const isDrugPointsLoading = (state, documentId, contentSetId) => {
    const in_progress_key = DRUG_POINTS_CALL_IN_PROGRESS_PATH + '.' + documentId + '-' + contentSetId;
    return enhanced_get(state, [DRUG_POINTS_PAGE_PATH, in_progress_key], false);
}

export const hasDrugPointsErrorInChatbot = (state, documentId, contentSetId) => {
    const hasErrorKey = DRUG_POINTS_CALL_ERROR_IN_CHATBOT_PATH + '.' + documentId + '-' + contentSetId;
    return enhanced_get(state, [DRUG_POINTS_PAGE_PATH, hasErrorKey], false);
}

export const hasDrugPointsErrorInDrugMonograph = (state, documentId, contentSetId) => {
    const hasErrorKey = DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH + '.' + documentId + '-' + contentSetId;
    return enhanced_get(state, [DRUG_POINTS_PAGE_PATH, hasErrorKey], false);
}

export const isDrugDexLoading = (state, documentId, contentSetId) => {
    const in_progress_key = DRUGDEX_CALL_IN_PROGRESS_PATH + '.' + documentId + '-' + contentSetId;
    return enhanced_get(state, [IN_DEPTH_PAGE_PATH, in_progress_key], false);
}

export const hasDrugDexErrorInChatbot = (state, documentId, contentSetId) => {
    const hasErrorKey = DRUGDEX_CALL_ERROR_IN_CHATBOT_PATH + '.' + documentId + '-' + contentSetId;
    return enhanced_get(state, [IN_DEPTH_PAGE_PATH, hasErrorKey], false);
}

export const hasDrugDexErrorInDrugMonograph = (state, documentId, contentSetId) => {
    const hasErrorKey = DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH + '.' + documentId + '-' + contentSetId;
    return enhanced_get(state, [IN_DEPTH_PAGE_PATH, hasErrorKey], false);
}

export const replaceParamsInStateFromUrl = (current_state, urlParams) => {
    const { documentId, contentSetId, drugName } = urlParams
    current_state.documentId = documentId;
    current_state.contentSetId = contentSetId;
    current_state.drugName = drugName;
}
