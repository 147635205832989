/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */


// import IVCompatibilitySummaryPage from "../IVCompatibilitySummaryPage";
import {makeSetChatbotReturnTarget} from "../../../ChatbotPage/chatbot-utils";
import { getIVCompatibilityPromise } from "../../../../services";
import {immutable_set, get, getArray, util_alert} from "../../../../utils";
import {generate_drug_id_lookup, generate_solution_id_lookup} from './iv-compatibility-json-response-utils'

import {handleError, handleErrorStatuses} from "../../../../utils/internal-error-handler";
import {GENERIC_ERROR_MESSAGE, IV_COMPATIBILITY_ERROR_TYPE} from "../../../../components/common/constants";
import {
  ACTIVE_STATUS, ERROR_STATUS,
  IV_COMPATIBILITY_SUMMARY_PAGE_NAME, LOADING_DESCRIPTION, LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction, NONE_STATUS
} from "../../../../components/response-loading/redux/response-loading-redux";
import {resetFiltersState} from "../../../../components/checkbox-filter/checkbox-filters-state";

export const IV_COMPATIBILITY_PATH = 'iv_comp_summary_page'

export const DRUG_SOLUTION_FILTER_GROUPS = [
    {
        group_label: 'Drugs',
        summary_title: 'All Drugs',
        checkbox_items: [],
        min_checked: 1
    },
    {
        group_label: 'Solutions',
        summary_title: 'All Solutions',
        checkbox_items: [],
        min_checked: 0
    }
]

export const DRUG_SOLUTION_FILTER_GROUP_PATH = 'drug_solution_filter_group'
export const DRUG_NAMES_STATE_PATH = DRUG_SOLUTION_FILTER_GROUP_PATH+'.0.checkbox_items'
export const SOLUTION_NAMES_STATE_PATH = DRUG_SOLUTION_FILTER_GROUP_PATH+'.1.checkbox_items'

export const EMPTY_STATE = {
    [IV_COMPATIBILITY_PATH]:{},
    is_accordion_open: false,
    loading: false,
    // ... contains all selected drug_names and filter_names ...
    [DRUG_SOLUTION_FILTER_GROUP_PATH]: DRUG_SOLUTION_FILTER_GROUPS,
    filtered_drug_names: [], // checkbox filter
    filtered_solution_names: [], // checkbox filters
    drug_product_id_lookup: {},
    solution_product_id_lookup: {},
    show_unable_to_delete: false,
    // y_site_filters: IV_COMPAT_INITIAL_FILTER_STATE,
    // admixture_filters: IV_COMPAT_INITIAL_FILTER_STATE,
    // syringe_filters: IV_COMPAT_INITIAL_FILTER_STATE,
    // solutions_filter: IV_COMPAT_INITIAL_FILTER_STATE,
    // tpn_tpa_filters: IV_COMPAT_INITIAL_FILTER_STATE,
    iv_compatibility_objects: { drugs: [], solutions: [] }
}
export const A = {
    IV_COMP_SUMMARY_RESULTS: 'IV_COMP_SUMMARY_RESULTS',
    IV_COMP_CLEAR_SUMMARY_STATE: 'IV_COMP_CLEAR_SUMMARY_STATE',
    IV_ACCORDION_STATE: 'IV_ACCORDION_STATE',
    IV_COMP_SET_DRUGS_AND_SOLUTIONS: 'IV_COMP_SET_DRUG_AND_SOLUTIONS',
    IV_COMP_REMOVE_DRUG: 'IV_COMP_REMOVE_DRUG',
    IV_COMP_REMOVE_SOLUTION: 'IV_COMP_REMOVE_SOLUTION',
    IV_COMP_SHOW_UNABLE_TO_DELETE_MESSAGE: 'IV_COMP_SHOW_UNABLE_TO_DELETE_MESSAGE',
    IV_UPDATE_CURRENT_DRUG_FILTERS: 'IV_UPDATE_CURRENT_DRUG_FILTERS',
    IV_UPDATE_CURRENT_SOLUTION_FILTERS: 'IV_UPDATE_CURRENT_SOLUTION_FILTERS',
    IV_UPDATE_FILTERED_DRUGS_AND_SOLUTIONS: 'IV_UPDATE_FILTERED_DRUGS_AND_SOLUTIONS'
}

export const IV_COMP_ERROR = 'mdx_pwa.iv_comp.error';

//actions ...

//New filters

export function makeUpdateCurrentDrugFiltersAction(current_drug_filters){
    return {
        type: A.IV_UPDATE_CURRENT_DRUG_FILTERS,
        current_drug_filters
    }
}

export function makeUpdateCurrentSolutionFiltersAction(current_solution_filters){
    return {
        type: A.IV_UPDATE_CURRENT_SOLUTION_FILTERS,
        current_solution_filters
    }
}

export function makeIVCompatSummaryAction(
    iv_comp_summary_response_json={}
){
    return {
        type: A.IV_COMP_SUMMARY_RESULTS,
        iv_comp_summary_response_json
    }
}

export function makeIVCompatSummaryClearStateAction() {
    return {
        type: A.IV_COMP_CLEAR_SUMMARY_STATE
    }
}

export function makeChangeAccordionsAction(accordion_state=false){
    return {
        type: A.IV_ACCORDION_STATE,
        // is_accordion_open
        accordion_state: accordion_state
    }
}

export function makeIVCompSetDrugsAndSolutionsAction(drug_names = [], solution_names = []) {
    console.debug("makeIVCompSetDrugsAndSolutionsAction", "drug_names=",drug_names,"solution_names=", solution_names)
    return {
        type: A.IV_COMP_SET_DRUGS_AND_SOLUTIONS,
        drug_names,
        solution_names
    }
}

export function makeIVCompRemoveDrugAction(drug_name) {
    return {
        type: A.IV_COMP_REMOVE_DRUG,
        drug_name
    }
}

export function makeIVCompRemoveSolutionAction(solution_name) {
    return {
        type: A.IV_COMP_REMOVE_SOLUTION,
        solution_name
    }
}

export function makeIVCompShowUnableToDeleteMessageAction(show_unable_to_delete=false) {
    return {
        type: A.IV_COMP_SHOW_UNABLE_TO_DELETE_MESSAGE,
        show_unable_to_delete
    }
}

export function makeIVUpdateFilteredDrugsAndSolutions(
    filtered_drug_names = [],
    filtered_solution_names = []
) {
    console.debug("ENTER makeIVUpdateFilteredDrugsAndSolutions", "filtered_drug_names=",filtered_drug_names,"filtered_solution_names=", filtered_solution_names)
    return {
        type: A.IV_UPDATE_FILTERED_DRUGS_AND_SOLUTIONS,
        filtered_drug_names,
        filtered_solution_names
    }
}

//Reducers
export function ivCompatibilitySummaryReducer(current_state={}, action={}){
    // save new drug_points_response_json in the new_state
    console.debug('ENTER ::::::::::::::::::::::::::::: ivCompatibilitySummaryReducer', "action=", action)
    const response_json = get(action,'iv_comp_summary_response_json',{})

    let new_state = immutable_set(
        current_state,
        IV_COMPATIBILITY_PATH,
        response_json
    )

    new_state.drug_product_id_lookup = generate_drug_id_lookup(response_json)
    new_state.solution_product_id_lookup = generate_solution_id_lookup(response_json)

    return new_state
}
export function changeAccordionsReducers(current_state={}, action={}){
    return immutable_set(
        current_state,
        'is_accordion_open',
        action.accordion_state
    )
}

export function ivCompatibilitySetDrugsAndSolutionsReducer(current_state = {}, action = {}) {
    const {
        drug_names = [],
        solution_names = []
    } = action
    let new_state = immutable_set(
        current_state,
        DRUG_NAMES_STATE_PATH,
        [...drug_names]
    )
    new_state = immutable_set(
        new_state,
        SOLUTION_NAMES_STATE_PATH,
        [...solution_names]
    )
    new_state.filtered_drug_names = [...drug_names]
    new_state.filtered_solution_names = [...solution_names]
    return new_state;
}

export function clearCompSummaryStateReducer(current_state={}, action={}){
      console.debug("ENTER .................. clearCompSummaryStateReducer")
      return EMPTY_STATE
}

export function ivUpdateFilteredDrugsAndSolutionsReducer(current_state = {}, action = {}) {
    console.debug("ENTER ivUpdateFilteredDrugsAndSolutionsReducer", "action=",action)
    const {
        filtered_drug_names = [],
        filtered_solution_names = []
    } = action
    let new_state = immutable_set(current_state, 'filtered_drug_names', [...filtered_drug_names])
    new_state.filtered_solution_names = [...filtered_solution_names]
    console.debug("EXIT ivUpdateFilteredDrugsAndSolutionsReducer","new_state=",new_state)
    return new_state
}

// TODO: move this to utils, with unit tests
export function removeArrayItem(current_array=[], deleted_array_item='') {
    let new_array = []
    for (let i = 0; i<current_array.length;i++) {
        const current_item = current_array[i]
        if (current_item!==deleted_array_item) {
            new_array.push(current_item)
        }
    }
    if (new_array.length===current_array.length) {
        return null
    } else {
        return new_array
    }
}

/**
 * Reach inside current state and remove an array item
 * @param current_state
 * @param relative_path_to_array
 * @param item_to_remove
 * @returns {Object|*}
 */
export function removeArrayItemFromState(
    current_state,
    relative_path_to_array,
    item_to_remove
) {
    const new_array = removeArrayItem(
        getArray(current_state, relative_path_to_array),
        item_to_remove
    )
    let new_state = current_state

    if (new_array!==null) {
        new_state = immutable_set(
            current_state,
            relative_path_to_array,
            new_array
        )
    } //endif new_array
    return new_state
}

export function ivCompRemoveDrugReducer(current_state={}, action = {}) {
    const {
        drug_name: removed_drug_name = ''
    } = action
    let new_state = removeArrayItemFromState(
        current_state,
        DRUG_NAMES_STATE_PATH,
        removed_drug_name
    )

    return new_state
}

export function ivCompRemoveSolutionReducer(current_state={}, action = {}) {
    const {
        solution_name: removed_solution_name = ''
    } = action
    let new_state = removeArrayItemFromState(
        current_state,
        SOLUTION_NAMES_STATE_PATH,
        removed_solution_name
    )

    return new_state
}

export function ivCompShowUnableToDeleteMessageReducer(current_state= {}, action = {} ) {
    const new_flag = get(action,'show_unable_to_delete', false)
    const current_flag = get(current_state,'show_unable_to_delete' )
    console.debug("ivCompShowUnableToDeleteMessageReducer action=",action, "new_flag=",new_flag, "current_flag=", current_flag)
    let new_state = current_state
    if (new_flag!==current_flag) {
        new_state = immutable_set(
            current_state,
            'show_unable_to_delete',
            new_flag
        )
    }
    return new_state
}

export function makeIvCompatSummaryGoToChatbotActionThunk(props={}) {
    return (dispatch) => {
        dispatch(makeSetChatbotReturnTarget('IVCompatibilitySummaryPage')); //TODO:stitch in page name
        props.history.push('/chatbot')
    }
}


// TODO: remove b/c checkbox filters
// drug_filters:{current_filters:[], temp_filters:[],filter_panel_visible: false,}
export function updateCurrentDrugFiltersReducer(current_state={}, action={}){
   const new_state= immutable_set(
        current_state,
        'drug_filters.current_filters',
        action.current_drug_filters
    );
   return immutable_set(
       new_state,
       'drug_filters.temp_filters',
       action.current_drug_filters
   );
}

// TODO: remove b/c checkbox filters
export function updateCurrentSolutionFiltersReducer(current_state={}, action={}){
    const new_state= immutable_set(
        current_state,
        'solution_filters.current_filters',
        action.current_solution_filters
    );
    return immutable_set(
        new_state,
        'solution_filters.temp_filters',
        action.current_solution_filters
    )
}

//redux-thunk
export function makeIVCompatibilityThunk({
                                             agent_names, // aka drug_names
                                             result_types,
                                             selected_solutions,
                                             props
                                         }
) {
    return (dispatch, getState) => {
        console.debug("ENTER makeIVCompatibilityThunk agent_names=",agent_names)

        let current_state = getState()
        let login_page = {};

        // handle combined reducers ...
        if (current_state.iv_compatibility_page) {
            login_page = current_state.login_page;
            current_state = current_state.iv_compatibility_page
            console.debug('makeIVCompatibilityThunk',': login_page=',login_page)
        }

        let current_agent_names = agent_names
        if (current_agent_names===null) {
            current_agent_names = [
                ...getArray(current_state, DRUG_NAMES_STATE_PATH)
            ]
        }

        console.debug("makeIVCompatibilityThunk >>>>>>>>>>>>>>>>>>>>>>>>>>> current_agent_names=",current_agent_names)
        dispatch(makeResponseLoadingAction(IV_COMPATIBILITY_SUMMARY_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
        localStorage.removeItem(IV_COMP_ERROR);

        console.debug("Inside ivCompatibilityThunk")
        return getIVCompatibilityPromise(
            {
                agent_names,
                result_types,
                selected_solutions,
                login_page
            }
        ).then(function (response) {
            const status = response.status;
            console.debug('............ response=',response);
            if(status === 200) {
              return response.json();
            } else {
              dispatch(makeResponseLoadingAction(IV_COMPATIBILITY_SUMMARY_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
              if (status === 403) {
                dispatch(makeIVCompatSummaryClearStateAction())
              }
              else if (![401, 500, 503].includes(status)) {
                  resetFiltersState();
              }
              let mutable_props = {...props};
              mutable_props.error_type = IV_COMPATIBILITY_ERROR_TYPE
              handleErrorStatuses(status, mutable_props, dispatch, getState)
            }
          }).then(iv_compatibility_response_json => {
            console.debug(".............. iv_compatibility_response_json=",iv_compatibility_response_json)
            dispatch(makeIVUpdateFilteredDrugsAndSolutions(
                current_agent_names,
                selected_solutions
            ))

          if (iv_compatibility_response_json) {
            dispatch(
              makeIVCompatSummaryAction(
                iv_compatibility_response_json
              ))
            dispatch(makeResponseLoadingAction(IV_COMPATIBILITY_SUMMARY_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
          }
        }).catch(error => {
            localStorage.setItem(IV_COMP_ERROR, true);
            dispatch(makeResponseLoadingAction(IV_COMPATIBILITY_SUMMARY_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
            handleError({props, message:GENERIC_ERROR_MESSAGE});
            util_alert(
                "An error occurred. Please try again.",
                IV_COMPATIBILITY_ERROR_TYPE,
                dispatch,
                getState
            );
        })
    }
}

export function iv_compatibility_page(
    current_state = EMPTY_STATE,
    action = {}
) {
    switch (action.type) {
        case A.IV_COMP_SUMMARY_RESULTS:
            return ivCompatibilitySummaryReducer(current_state, action);
        case A.IV_COMP_CLEAR_SUMMARY_STATE:
            return clearCompSummaryStateReducer(current_state, action);
        case A.IV_ACCORDION_STATE:
            return changeAccordionsReducers(current_state, action);
        case A.IV_COMP_SET_DRUGS_AND_SOLUTIONS:
            return ivCompatibilitySetDrugsAndSolutionsReducer(current_state, action);
        case A.IV_COMP_REMOVE_DRUG:
            return ivCompRemoveDrugReducer(current_state, action);
        case A.IV_COMP_REMOVE_SOLUTION:
            return ivCompRemoveSolutionReducer(current_state, action);
        case A.IV_COMP_SHOW_UNABLE_TO_DELETE_MESSAGE:
            return ivCompShowUnableToDeleteMessageReducer(current_state, action);
        case A.IV_UPDATE_CURRENT_DRUG_FILTERS:
            return updateCurrentDrugFiltersReducer(current_state, action);
        case A.IV_UPDATE_CURRENT_SOLUTION_FILTERS:
            return updateCurrentSolutionFiltersReducer(current_state, action);
        case A.IV_UPDATE_FILTERED_DRUGS_AND_SOLUTIONS:
            return ivUpdateFilteredDrugsAndSolutionsReducer(current_state, action);
        default:
            return current_state
    }
}
