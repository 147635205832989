/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import IVCompatibilityList
    from '../../../IVCompatibilityPages/iv-compatibility-summary-page/iv-summary-components/IVCompatibilityList'
import {prepareFilterPanelState} from "../../../../utils/commonIvDiUtils";
import {resetIVDetailsFiltersState} from "../../../../components/checkbox-filter/checkbox-filters-state";

const mapStateToProps = state => {
    const state_results = {}
    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {}
}
export class IVCompatibilityTemplatePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter_panel_state: []
        }
    }

    componentDidMount() {
        resetIVDetailsFiltersState();
    }

    onChangeFilterPanelState = (currentPanelState) => prepareFilterPanelState(currentPanelState, this);

    render() {
        return <Fragment>
            <IVCompatibilityList
            location = {this.props.location}
            isSingleIV = {true}
            panel_button_click_handler={this.onChangeFilterPanelState}
            filter_panel_state={this.state.filter_panel_state}
            drugMonographDocumentId={this.props.drugMonographDocumentId}
            drugMonographDrugName={this.props.drugMonographDrugName}
            drugMonographInDepthId={this.props.drugMonographInDepthId}/>
        </Fragment>
    }
}
const IVCompatibilityTemplate = connect(mapStateToProps, mapDispatchToProps)(IVCompatibilityTemplatePage);

export default IVCompatibilityTemplate;