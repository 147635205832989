/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {get, getArray, immutable_set, sortArrayByNestedProperties} from "../../../../utils";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import {Accordion, AccordionItem} from "@carbon/react";
import {StructuredListWrapper,
    StructuredListBody,
    StructuredListCell
} from "@carbon/react";
import Collapsible from './collapsible';
import {iv_compatibility_elements} from "../../compatibility-elements";

import $ from 'jquery';
import { Information } from '@carbon/react/icons';
import {CheckboxFilterComponent} from "../../../../components/checkbox-filter/CheckboxFilterComponent";
import {LOCAL_STORAGE_FILTER_PANEL_STATE_KEY} from "../../../../components/checkbox-filter/checkbox-filters-state";
import {
    GLOBAL_DRUG_NAMES_STATE_PATH
} from "../IVCompatibilitySummaryPage";
import {IVCompatibilityInfoModal} from "../../iv-compatibility-info-modal/IVCompatibilityInfoModal";
import {makeChangeAccordionsAction} from "../iv-compatibility-summary-utils";
import {toBooleanArray} from "../../../../components/checkbox-filter/flag-set-utils";
import {IVFooter} from "../../../../components/common/footers";
import {makeSaveUrlParametersAction} from "../../../../components/common/url-params-redux";
import {IV_COMPATIBILITY_SUMMARY_PAGE_NAME} from "../../../../components/response-loading/redux/response-loading-redux";
import queryString from "query-string";
import { InlineLoading } from '@carbon/react';
import { 
    getUrlParametersNavigatingFromDrugMonograph,
    getUrlParameterFromSummaryPage 
} from "../../../../utils/queryParametersUtils";
import { PrefixContext } from '@carbon/react';
import { IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE } from '../../../../utils/commonIvDiUtils';

const mapStateToProps = state =>{
    console.debug('IVCompatibilityList::mapStateToProps','state=', state);

    const state_results = {
        iv_data: get(state, 'iv_compatibility_page.iv_comp_summary_page', {}),
        drugs_and_ids_dictionary: get(state, 'iv_compatibility_page.drug_product_id_lookup', {}),
        solutions_and_ids_dictionary: get(state, 'iv_compatibility_page.solution_product_id_lookup', {}),
        ivSummaryPageResponseLoading: get(state, `inline_loading.${IV_COMPATIBILITY_SUMMARY_PAGE_NAME}`, {}),
        drug_names: getArray(state,GLOBAL_DRUG_NAMES_STATE_PATH, []),
        filtered_drug_names: getArray(state, 'iv_compatibility_page.filtered_drug_names', []),
        filtered_solution_names: getArray(state, 'iv_compatibility_page.filtered_solution_names', []),
        summary_page_data: get(state,'iv_compatibility_page.iv_comp_summary_page','')
    }
    console.debug('IVCompatibilityList::mapStateToProps','state_results=', state_results);
    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {
        onChangedAccordions: (accordions_state) => {
            dispatch(makeChangeAccordionsAction(accordions_state))
        },
        onDisplayProductInfoPage: (product_id, props) => {
            dispatch(makeSaveUrlParametersAction(props.history.location.search))
            let search = '?productDocId='+ product_id;
            const location = get(props, 'location.pathname', '');
            const locationSearch = get(props, 'location.search', '');
            search = (location === "/drugPoints")
                        ? search + getUrlParametersNavigatingFromDrugMonograph('IVcompatibility', props)
                        : search + getUrlParameterFromSummaryPage(locationSearch, IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE);
            
            const historyLocation = {
                pathname: '/ivProductInfo',
                search: search,
                state: { iv_single_parameters: encodeURI(props.history.location.search) }
            }
            props.history.push(historyLocation);
        },
        onDisplayIvResults: (props) => {
            dispatch(makeSaveUrlParametersAction(props.history.location.search))
        }
    }
}

const administer_methods = ['solution', 'ysite', 'admixture', 'syringe', 'tpnTna', 'preparationAndStorage'];

const filter_state_names = ['solutions_filter','y_site_filters','admixture_filters', 'syringe_filters', 'tpn_tpa_filters', 'preparationAndStorage_filters'];
export const COMPATIBILITY_CODES = "CVUIN";
// Always use immutable_set() for any updates to the following arrays ...
export const SINGLE_FILTER_COMPATIBILITY_FLAGS = new Array(COMPATIBILITY_CODES.length).fill(true);
const ALL_FILTERS_COMPATIBILITY_FLAGS = new Array(filter_state_names.length).fill(SINGLE_FILTER_COMPATIBILITY_FLAGS);
export const filter_groups = [
    {
        group_label: 'Compatibility',
        summary_title: "All compatibilities",
        checkbox_items: [
            'Compatible',
            'Caution:Variable',
            'Uncertain',
            'Incompatible',
            'Not Tested'
        ],
        min_checked: 1
    }
]
// const MAX_MENU_ITEM_LENGTH = 30;
const LOCATIONS = ['/drugPoints'];

export const DrugItem = React.memo((
    {
        long_name='',
        info_click_handler
    }
) => {
    if(info_click_handler){
        return <div
            className="iv-compat-prep-and-storage-drug-div"
            title={long_name}
            onClick={info_click_handler}
        >
            <div>
                {  info_click_handler
                &&
                <Information
                    className="iv-compat-prep-and-storage-drug-icon"
                />
                }
                <span className="iv-compat-prep-and-storage-drug-text">{long_name}</span>
            </div>
        </div>
    }
    else{
        return <div
            className="iv-compat-prep-and-storage-drug-div-no-link"
            title={long_name}
        >
            <span className="iv-compat-prep-and-storage-drug-text-no-link">{long_name}</span>
        </div>
    }
})

export class AdministrativeItems extends Component {
    constructor(props) {
        super(props);

        this.updateCompatibilityFilterFlags = this.updateCompatibilityFilterFlags.bind(this);
        this.updateAccordionStatus=this.updateAccordionStatus.bind(this);
        this.displayProductInfoPage=this.displayProductInfoPage.bind(this);
        var accordionItemOpenStatus ={};
        for (let j = 0; j < administer_methods.length; j++) {
          accordionItemOpenStatus[administer_methods[j]+'_item_open_status'] = false;
        }
        // LOCAL STATE that tracks the filter state of each accordian filter
        this.state = {
            ...accordionItemOpenStatus,
            compatibility_filter_flags: ALL_FILTERS_COMPATIBILITY_FLAGS,
        }
        console.debug("vvvvvvvvvvvvvvvvvvv state=", this.state)

        //this.state = accordionItemOpenStatus;
    }

    /**
     * Update the local compatibility code flags for a specific accordian
     * @param accordian_index
     * @param new_flags
     */
    updateCompatibilityFilterFlags(accordian_index, new_flags) {
        this.setState(
            immutable_set(
                this.state,
                `compatibility_filter_flags.${accordian_index}`,
                // CheckboxFilterComponent call back sends an array of boolean arrays ...
                new_flags[0] // only one checkbox group in compatibility filters
            )
        )
    }

    updateAccordionStatus(close_other_accordions) {
        this.props.onChangedAccordions(false);
    }

    displayProductInfoPage(drugProductId, props){
        this.props.onDisplayProductInfoPage(drugProductId, props);
    }

    onAccordianItemClick = (clickedItem, invokedSectionName) => {
      const prefix = this.context;
      const currentFilterPanelClosedState = {sectionName: invokedSectionName, isPanelDisplayed: false};
      if (this.props.panel_button_click_handler) {
        this.props.panel_button_click_handler(currentFilterPanelClosedState);
      } else {
        this.processFilterPanelState(currentFilterPanelClosedState);
      }

      var accordionItemOpenStatus ={};

      for (let j = 0; j < administer_methods.length; j++) {
        var itemName = administer_methods[j]+'_item_open_status';
        if( clickedItem === itemName ){
          accordionItemOpenStatus[itemName] = !this.state[itemName];
        }else{
          accordionItemOpenStatus[itemName] = false;
        }
      }
      this.setState(accordionItemOpenStatus);

      let indexToScroll = accordionItemOpenStatus[clickedItem] ?
                $(`.${prefix}--accordion__item`).offset().top - $('.mdx-inner-content').offset().top : 0
      $('.mdx-scroll-area').animate({
         scrollTop: indexToScroll
      }, '4000');

    }

    onClickIvResultsLink = (linkURL_encoded, props) => {
      this.props.onDisplayIvResults(props);

      return linkURL_encoded;
    }

    processItemsForPreparationAndStorageSection = (items_array, ids_dictionary, props) => {
        const prefix = this.context;
        let accordion_items = [];
        let prep_and_storage_index = 250;
        for (let i = 0; i < items_array.length; i++) {
            let product_info_handler = undefined;
            let product_id = ids_dictionary[items_array[i]];
            if (ids_dictionary[items_array[i]]){
                product_info_handler = () => this.displayProductInfoPage(product_id, props)
            }
            let drug_row = <DrugItem
                long_name = {items_array[i]}
                info_click_handler = {product_info_handler}
            />
            accordion_items.push(<div key={prep_and_storage_index++} className='iv-compat-prep-and-storage-link'>
                <StructuredListWrapper>
                    <StructuredListBody className='bx--structured-list-pwa accordion-first'>
                        <StructuredListCell className={`${prefix}--structured-list-td-iv-row-cell`}>{drug_row}</StructuredListCell >
                    </StructuredListBody>
                </StructuredListWrapper>
            </div>);
        }
        return accordion_items;
    }

    render() {
      if (!this.props.ivSummaryPageResponseLoading.status) {
        let iv_index = 0;
        let linkUrl = 'ivResultDetails?documentIds=';
        console.debug("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn state=", this.state)
        const iv_data = this.props.iv_data
        let location = get(this.props, 'location.pathname', '');

        const drugs_and_ids_dictionary = this.props.drugs_and_ids_dictionary
        const solutions_and_ids_dictionary = this.props.solutions_and_ids_dictionary
        const drug_names = this.props.drug_names
        const filtered_drug_names = this.props.filtered_drug_names
        const filtered_solution_names = this.props.isSingleIV ? [] : this.props.filtered_solution_names
        const props = this.props;
        const ivResultsDisplayHandler = this.onClickIvResultsLink;

        let accordionItem = [];
        for (let j = 0; j < administer_methods.length; j++) {
          const administer_method = administer_methods[j]
          // const filter_state_name = filter_state_names[j]
          const isNotPreparationAndStorage = (administer_method !== 'preparationAndStorage');
          const filter_flags = getCompatibilitiesFiltersState()[j]
          const skip_compat_code_function = compatibility_code => {
            let code_index = COMPATIBILITY_CODES.indexOf(compatibility_code)
            return code_index < 0 || !filter_flags[code_index];
          }
          let y_site = get(iv_data, administer_method, {});
          console.debug(y_site);
          console.debug(y_site.length);
          let accordion_data;
          let titleName = `${get_title(administer_methods[j])} Test Results`;
          const admin_method_props = {
            administer_methods_name: administer_method,
            linkUrl,
            y_site,
            location,
            skip_compat_code_function,
            titleName,
            filtered_drug_names,
            props,
            ivResultsDisplayHandler
          }
          if (administer_method === 'preparationAndStorage') {
            if (filtered_solution_names.length) {
              accordion_data = (
                <div className='iv-compat-prep-and-storage-content-wrapper'>
                  <h3 className='iv-compat-prep-and-storage-content-heading'>Drugs:</h3>
                  {this.processItemsForPreparationAndStorageSection(filtered_drug_names, drugs_and_ids_dictionary, this.props)}
                  <h3 className='iv-compat-prep-and-storage-content-heading'>Solutions:</h3>
                  {this.processItemsForPreparationAndStorageSection(filtered_solution_names, solutions_and_ids_dictionary, this.props)}
                </div>
              )
            } else {
              accordion_data = (
                <div className='iv-compat-prep-and-storage-content-wrapper'>
                  <h3 className='iv-compat-prep-and-storage-content-heading'>Drugs:</h3>
                  {this.processItemsForPreparationAndStorageSection(filtered_drug_names, drugs_and_ids_dictionary, this.props)}
                </div>
              )
            }
          } else if (y_site.length > 0) {
            if (administer_method === 'solution') {
              let solution_data = [];
              solution_data.push(process_Solutions(
                admin_method_props
              ));
              accordion_data = solution_data;
            } else {
              accordion_data = process_TpnTna(
                admin_method_props
              );
            }
          } else {
            accordion_data = process_adminstrative_data(
                admin_method_props,
                this.context
            );
          }
          let result_title = undefined;
          if (!['solution', 'preparationAndStorage'].includes(administer_method)) {
            result_title = <h3
              className="iv-compat-summary-accordion-title"
              id='title-border-line'>{get_title(administer_methods[j])} Test Results</h3>;
          }
          const sectionName = administer_method;
          accordionItem.push(
          <div className="accordion-display-content">
                <AccordionItem
                  key={iv_index++}
                  title={get_heading_title(administer_method)}
                  className={`${administer_method === 'preparationAndStorage' ? 'prep-and-storage-accordion' : ''} iv-accordion-item`}
                  open={this.state[administer_methods[j] + '_item_open_status']}
                  onHeadingClick={() => this.onAccordianItemClick(administer_methods[j] + '_item_open_status', sectionName)}
                >
                  <div className='iv-compat-summary-accordion-first-line'>
                    <div className="iv-compat-accordion-left">
                      {result_title}
                    </div>
                    <div className="iv-compat-accordion-right">
                      {
                        isNotPreparationAndStorage
                        &&
                        <CheckboxFilterComponent
                          checkbox_groups_list={filter_groups}
                          submit_filter_items={new_flags => this.updateCompatibilityFilterFlags(j, new_flags)}
                          InfoPanelComponent={IVCompatibilityInfoModal}
                          invokedSectionName={sectionName}
                          panel_button_click_handler={this.props.panel_button_click_handler}
                          panel_state={this.props.filter_panel_state}
                        />
                      }
                    </div>
                  </div>
                  {
                    result_title &&
                    <div className ='ivBoxPreparation sectionTitleFlatIV'>
                      {result_title}
                    </div>
                  }
                  {accordion_data}
                </AccordionItem>
            </div>);
        }
        const summary_page_data = this.props.summary_page_data
        let multipleSearchLink;
        if (isLocationSuitable(location) && drug_names.length === 1 && !areAllAccordionsEmpty(summary_page_data)) {
          const loc_search = get(this.props,'location.search');
          const url_params = queryString.parse(loc_search)
          const documentId = url_params.documentId;
          let ivCompatibilitySearchUrl = `/ivCompatibilitySearch?&iv_drug_names=${drug_names[0]}&iv_drug_ids=${documentId}`;
          const linkURL_encoded = encodeURI(ivCompatibilitySearchUrl);
          multipleSearchLink = <Link className='drug-interaction-modify-link instructions-link' key='a'
                                     to={linkURL_encoded}>
            Check multiple IV compatibility
          </Link>
        }
        if (areAllAccordionsEmpty(summary_page_data) && isLocationSuitable(location)) {
          return <Fragment/>
        } else {
          return <Fragment>
            {multipleSearchLink}
            <Accordion className="IVCompatibilityAccordion" id='drug-points-iv-comp-accordion'>
              {accordionItem}
              <IVFooter styleName='iv-compatibility-summary-footer'/>
            </Accordion>
          </Fragment>
        }
      }
      return <InlineLoading className={"iv-compatibility-loading"}
                            description={this.props.ivSummaryPageResponseLoading.description}
                            status={this.props.ivSummaryPageResponseLoading.status}/>
    }
}

const isLocationSuitable  = location => LOCATIONS.includes(location); 

const HEADING_TITLE_MAP = new Map([
    ['ysite', 'Drug-Drug: Y-Site'],
    ['admixture', 'Drug-Drug: Admixture'],
    ['syringe', 'Drug-Drug: Syringe'],
    ['solution', 'Drug-Solution'],
    ['tpnTna', 'Drug-TPN'],
    ['preparationAndStorage', 'Preparation And Storage']
]);

const TITLE_MAP = new Map([
    ['ysite', 'Y-Site'],
    ['admixture', 'Admixture'],
    ['syringe', 'Syringe'],
    ['solution', 'Drug-Solution'],
    ['tpnTna', 'TPN(2-in-1) / TPN(3-in-1)'],
    ['preparationAndStorage', 'Preparation And Storage']
]);

const get_heading_title = (method_name = '') => {
    return HEADING_TITLE_MAP.has(method_name) ? HEADING_TITLE_MAP.get(method_name) : '';
}

const get_title = (method_name = '') => {
    return TITLE_MAP.has(method_name) ? TITLE_MAP.get(method_name) : '';
}

export function process_adminstrative_data (
    {
        administer_methods_name = '',
        linkUrl = '',
        y_site = [],
        location = '',
        skip_compat_code_function = () => false,
        titleName = '',
        filtered_drug_names = [],
        expand_all = false,
        props,
        ivResultsDisplayHandler = f => f
    },
    context
) {
    const prefix = context;
    const is_on_drug_points_page = location === "/drugPoints"
    const is_single_drug_iv = filtered_drug_names.length === 1

    let admin_data_index=1001;
    let iv_compatibility = get(y_site, 'ivCompatibility');
    console.debug("process_adminstrative_data administer_methods_name=",administer_methods_name,"iv_compatibility=",iv_compatibility);
    const y_site_list = getArray(iv_compatibility, `${administer_methods_name}List`);
    console.debug("process_adminstrative_data y_site_list=",y_site_list);
    let accordion_items = [];
    for (let i = 0; i < y_site_list.length; i++) {
        const item = get(y_site_list[i], administer_methods_name, {});

        // implement filtering by compatibility code ...
        const compatibilityCode=get(item,'compatibilityCode','');
        if (skip_compat_code_function(compatibilityCode)) {
            continue;
        }

        const compatibilityIconId = get(
            iv_compatibility_elements.compatibility_image_elements,
            compatibilityCode,
            ''
        )

        let display_results;
        const results = get(item, 'documentIds', []);
        if (results.length > 0) {
            const locationSearch = get(props, 'location.search', '');
            let result_linkUrl = `${linkUrl}${results}&compatibility=${compatibilityCode}&accordionFrom=${titleName}`;
            result_linkUrl = is_on_drug_points_page
                            ? result_linkUrl + getUrlParametersNavigatingFromDrugMonograph('IVcompatibility', props)
                            : result_linkUrl + getUrlParameterFromSummaryPage(decodeURIComponent(locationSearch), IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE);
            const linkURL_encoded = encodeURI(result_linkUrl);
            const resultsText = results.length === 1 ? 'Result' : 'Results';
            display_results= <Link
                className="iv-compat-subaccordion-row-data-link-text"
                onClick={() => ivResultsDisplayHandler(linkURL_encoded, props)}
                to={linkURL_encoded}>{results.length} {resultsText}</Link>
        } //end if results.length > 0

        if (!is_single_drug_iv) {
            const compatibilityText = get(
                iv_compatibility_elements.compatibility_elements,
                compatibilityCode,
                ''
            ).replace('compatibility-', '');
            accordion_items.push(
                <DrugDrugAccordionDisplay
                    key={admin_data_index++}
                    drug1={get(item, 'agentOne', '')}
                    drug2={get(item, 'agentTwo', '')}
                    compatibilityIconId={compatibilityIconId}
                    compatibilityText={compatibilityText}
                    display_results_link={display_results}
                    expand_all={expand_all}
                />
            )
        } else {
            const link_name = get_link_name(item);
            accordion_items.push(
                <div key={admin_data_index++} className="iv-compat-subaccordion-collapsible-container iv-compat-subaccordion-single-drug">
                    <StructuredListWrapper>
                        <StructuredListBody className={`${prefix}----structured-list-pwa accordion-first`}>
                            <StructuredListCell
                                className={`${prefix}--structured-list-td-iv-row-cell alnleft`}
                                id={compatibilityIconId}
                            >
                                {link_name}
                            </StructuredListCell>
                            <StructuredListCell
                                className={`${prefix}--structured-list-td-iv-row-cell iv-result-row alnright`}
                            >
                                {display_results}
                            </StructuredListCell>
                        </StructuredListBody>
                    </StructuredListWrapper>
                </div>
            );
        } //endelse !is_single_drug_iv
    } //endfor i
    return accordion_items;
}

export function get_link_name(item={}){
    let hyphen=get(item, 'agentOne')&&get(item, 'agentTwo', '')?'-':''
    return `${get(item, 'agentOne', '')}${hyphen}${get(item, 'agentTwo', '')}`;

}

export function process_TpnTna({
                                   administer_methods_name = '',
                                   linkUrl = '',
                                   y_site = [],
                                   location = '',
                                   skip_compat_code_function = () => false,
                                   titleName = '',
                                   filtered_drug_names = [],
                                   props,
                                   ivResultsDisplayHandler = f => f
                               }
){
    let accordion_items = [];
    let tpn_accordion = [];
    let tna_accordion = [];
    let tpn_tna_index = 50;
    const locationSearch = get(props, 'location.search', '');
    y_site.map((item) => {

        let tpnTnaList = get(item,'tpnTnaList');
        let agentName = get(item,'uniqueAgentsList.uniqueAgent.agentName', '');
        const drug_name = agentName ? agentName : get(item, 'formDisplay[0].name', '');
        let tpnList = [];
        let tnaList = [];
        tpnTnaList.map((list)=>{
            tpnList = get(list, 'tpnList', []);
            tnaList = get(list, 'tnaList', []);
            let tpn_accordion_data = [];
            let tna_accordion_data = [];

            if (tpnList.length !== 0) {
                let tpn_product_id = '';
                tpnList.map((item, index) => {
                    tpn_product_id = get(item,'productDocId','');
                    tpn_accordion_data.push(
                        tpn_tna_accordion(
                            item,
                            index,
                            linkUrl,
                            'tpn',
                            location,
                            skip_compat_code_function,
                            titleName,
                            props,
                            ivResultsDisplayHandler
                            ));
                    return '';
                });
                let productUrl = `ivProductInfo?productDocId=${tpn_product_id}`;
                productUrl = location === "/drugPoints" 
                            ? productUrl + getUrlParametersNavigatingFromDrugMonograph('IVcompatibility', props)
                            : productUrl + getUrlParameterFromSummaryPage(decodeURIComponent(locationSearch), IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE);
                const encoded_productURL = encodeURI(productUrl);
                tpn_accordion.push(<Fragment key={tpn_tna_index++}>
                    <table>
                        <tbody>
                            <tr>
                                <td key={tpn_tna_index++} className="accordion-first">
                                    <span className='alnleft'>TPN (2-in-1) Test Results</span>
                                    <span className='alnright'>
                                        <Fragment >
                                            <Link key={'a1-' } className="display-to-end"
                                                  onClick={() => ivResultsDisplayHandler(encoded_productURL, props)}
                                                  to={encoded_productURL}>
                                                <Information className="iv_compatibility_fill"/>
                                                TPN Information
                                            </Link>
                                        </Fragment>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Collapsible
                        key={tpn_tna_index++}
                        title={drug_name}
                    >
                        {tpn_accordion_data}
                    </Collapsible>
                </Fragment>);
            }

            if (tnaList.length !== 0) {
                let tna_product_id = '';
                tnaList.map((item,index) => {
                    tna_product_id = get(item,'productDocId','');
                    tna_accordion_data.push(
                        tpn_tna_accordion(
                            item,
                            index,
                            linkUrl,
                            'tna',
                            location,
                            skip_compat_code_function,
                            titleName,
                            props,
                            ivResultsDisplayHandler
                            ));
                    return '';
                });
                let productUrl = `ivProductInfo?productDocId=${tna_product_id}`;
                productUrl = location === "/drugPoints" 
                            ? productUrl + getUrlParametersNavigatingFromDrugMonograph('IVcompatibility', props)
                            : productUrl + getUrlParameterFromSummaryPage(decodeURIComponent(locationSearch), IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE);
                const encoded_productURL = encodeURI(productUrl);
                tna_accordion.push(<Fragment key={tpn_tna_index++}>
                    <table>
                        <tbody>
                            <tr>
                                <td key={tpn_tna_index++} className="accordion-first">
                                    <span className='alnleft'>TPN (3-in-1) Test Results</span>
                                    <span className='alnright'>
                                        <Fragment >
                                            <Link key={'a1-' } className="display-to-end"
                                                  onClick={() => ivResultsDisplayHandler(encoded_productURL, props)}
                                                  to={encoded_productURL}>
                                                <Information className="iv_compatibility_fill"/>
                                                TPN Information
                                            </Link>
                                        </Fragment>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Collapsible
                        key={tpn_tna_index++}
                        title={drug_name}
                    >
                        {tna_accordion_data}
                    </Collapsible>
                </Fragment>);
            }
            return '';
        })
        return '';
    })
    accordion_items.push(tpn_accordion);
    accordion_items.push(tna_accordion);
    return <Accordion key={tpn_tna_index++} className="mdx-accordion">
        {tpn_accordion}
        {tna_accordion}
    </Accordion>;
}

const AGENT_TYPE_MAP = new Map([
    ['Soln', 'Solution'],
    ['Admix', 'Admixture']
]);

export function tpn_tna_accordion(
    item=[],
    index,
    linkUrl='',
    type='',
    location='',
    skip_compat_code_function=()=>false,
    titleName = '',
    props,
    ivResultsDisplayHandler = f => f
){
    console.debug('tpn_tna_accordion item=',item, 'index=',index,'linkUrl=',linkUrl, 'type=',type)
    const locationSearch = get(props, 'location.search', '');
    let item_type = get(item,type,[]);
    let compatibility_code = get(item_type[0], 'compatibilityCode', '');
    if (skip_compat_code_function(compatibility_code)) {
        return <Fragment key={index++}/>
    }
    let result_linkUrl=`${linkUrl}${get(item_type[0],'docId','')}&compatibility=${compatibility_code}&accordionFrom=${titleName}`
    result_linkUrl = location === "/drugPoints" 
                            ? result_linkUrl + getUrlParametersNavigatingFromDrugMonograph('IVcompatibility', props)
                            : result_linkUrl + getUrlParameterFromSummaryPage(decodeURIComponent(locationSearch), IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE);
    const linkURL_encoded =encodeURI(result_linkUrl);
    let results_link = <Fragment key={index++}/>;
    if (get(item_type[0],'compatibilityCode','') !== 'N') {
        results_link= <td className='alnright-tpnTna'>
            <Fragment key={index++}>
                <Link key={'a-'+index}
                      onClick={() => ivResultsDisplayHandler(linkURL_encoded, props)}
                      to={linkURL_encoded}>View Result</Link>
            </Fragment>
        </td>
    }
    const notation = item_type[1] ? item_type[1].replace('<Notations>', '').replace('</Notations>', '').replace(/\n/g, '<br>') : '';
    let agent_type = get(item_type[0], 'type', '');
    if (AGENT_TYPE_MAP.has(agent_type)) {
        agent_type = AGENT_TYPE_MAP.get(agent_type);
    }
    //Added dangerouslySetInnerHTML inside table to print the RTE content properly for notation 
    let tpn_data= <Fragment key={index++}>
        <table>
            <tbody>
                <tr>
                    <td id={get(iv_compatibility_elements.compatibility_image_elements,compatibility_code,'')}> {get(item_type[0],'agent','')}-{agent_type}</td>
                    {results_link}
                </tr>
                <tr>
                	<pre style={{ font: 'inherit', display: 'inline', whiteSpace: 'pre-wrap', margin: 0 }}>
                    	<td className="RteContentStyling" colSpan="2"><div dangerouslySetInnerHTML={{__html:  notation}}></div></td>
                    </pre>
                </tr>
            </tbody>
        </table>
    </Fragment>;
    return tpn_data;
}

export function process_Solutions({
                                      administer_methods_name = '',
                                      linkUrl = '',
                                      y_site = [],
                                      location = '',
                                      skip_compat_code_function = () => false,
                                      props,
                                      ivResultsDisplayHandler = f => f
                                  }
){
    let solution_index= 100;
    let selected_accordion_data=[];
    let other_accordion_data=[];
    let common_accordion_data=[];
    let solution_final_results=[];
    let uniqueDrugNames = [];
    let previousAgentName;
    let drugsByAgentNames = new Map();
    let drugNames = new Set();
    let sorted_y_site = sortArrayByNestedProperties(y_site, 'uniqueAgentsList.uniqueAgent.agentName');
    sorted_y_site.forEach(item => {
      let drugName = get(item,'formDisplay[0].name', '');
      let agentName = get(item,'uniqueAgentsList.uniqueAgent.agentName', '');
      if ((drugsByAgentNames.size !== 0) && !(drugsByAgentNames.has(agentName) && previousAgentName === agentName)) {
        drugNames = new Set();
      }
      drugNames.add(drugName);
      drugsByAgentNames.set(agentName, drugNames);
      previousAgentName = agentName;
    })
    let y_siteWithoutSynonyms = y_site.length > 1
      ? y_site.filter(item => filterSynonymsForDrugSolutions(item, uniqueDrugNames, drugsByAgentNames))
      : y_site;
    y_siteWithoutSynonyms.map((item,index)=>{
        let iv_compatibility = get(item,'ivCompatibility');
        let agentName = get(item,'uniqueAgentsList.uniqueAgent.agentName', '');
        const drug_name = agentName ? agentName : get(item, 'formDisplay[0].name', '');

        //Selected List
        const selected_list = props.isSingleIV ? [] : getArray(iv_compatibility, `${administer_methods_name}List.selectedSolutions`);
        if (selected_list.length > 0) {
            const selected_props={
                list_items:selected_list,
                item:item,
                drug_name:drug_name,
                location:location,
                linkUrl:linkUrl,
                administer_methods_name:administer_methods_name,
                accordion_data:selected_accordion_data,
                skip_compat_code_function
            }
            selected_accordion_data = create_solution_list_data(selected_props);
        }

        const common_list = getArray(iv_compatibility, `${administer_methods_name}List.commonSolutions`);
        console.debug(common_list);
        const common_props = {
            list_items:common_list,
            item:item,
            drug_name:drug_name,
            location:location,
            linkUrl:linkUrl,
            administer_methods_name:administer_methods_name,
            accordion_data:common_accordion_data,
            skip_compat_code_function
        }
        common_accordion_data = create_solution_list_data(common_props);

        let otherList = get(iv_compatibility, `${administer_methods_name}List.otherSolutions`, []);
        if (otherList.length > 0) {
            const other_props={
                list_items:otherList,
                item:item,
                drug_name:drug_name,
                location:location,
                linkUrl:linkUrl,
                administer_methods_name:administer_methods_name,
                accordion_data:other_accordion_data,
                skip_compat_code_function
            }
            other_accordion_data = create_solution_list_data(other_props);
        }
        return '';
    });

    //Selected Solution List.
    if (selected_accordion_data.length > 0) {
        const selected_props={
            accordion_data:selected_accordion_data,
            location:location,
            linkUrl:linkUrl,
            props: props,
            ivResultsDisplayHandler: ivResultsDisplayHandler
        }
        solution_final_results.push(<div>
                <div className='ivBoxPreparation sectionTitleFlatIV'>
                    Matching Solutions Results
                </div>
                {solution_accordion_data(selected_props)}
            </div>
        );
    }

    //Common Solution List
    const child_props={
        accordion_data:common_accordion_data,
        location:location,
        linkUrl:linkUrl,
        props: props,
        ivResultsDisplayHandler: ivResultsDisplayHandler
    }
    solution_final_results.push(<div key={solution_index++}>
            <div className='ivBoxPreparation sectionTitleFlatIV'>
                Common Solutions Results
            </div>
            {solution_accordion_data(child_props)}
        </div>
    );

    //Other Solution List
    if(other_accordion_data.length>0) {
        const other_props={
            accordion_data:other_accordion_data,
            location:location,
            linkUrl:linkUrl,
            props: props,
            ivResultsDisplayHandler: ivResultsDisplayHandler
        }
        solution_final_results.push(<div key={solution_index++}>
                <div className='ivBoxPreparation sectionTitleFlatIV'>
                    Other Solutions Results
                </div>
                {solution_accordion_data(other_props)}
            </div>
        );
    }
    return solution_final_results;
}

export function create_solution_list_data({
                                              list_items,
                                              item,
                                              drug_name,
                                              location,
                                              linkUrl,
                                              administer_methods_name,
                                              solution_type,
                                              accordion_data,
                                              skip_compat_code_function
                                          }){

    // let accordion_data=[];
    list_items.map((otherList, i) => {
        let item = get(otherList, administer_methods_name, {});
        let link_name = `${get(item, 'name', '')}`;
        let compatibilityCode = get(item, 'compatibilityCode', '');
        if (!skip_compat_code_function(compatibilityCode)) {
        let moreSolutionDocumentId = get(item, 'moreSolutionDocumentId', '');
        const results = getArray(item, 'documentIds');
        const child_object = {
            drug_name: drug_name,
            solution: link_name,
            results: results,
            Rating: compatibilityCode,
            moreSolutionDocumentId: moreSolutionDocumentId,
            solution_type: solution_type
        }
        accordion_data.push(child_object);
    }
        return '';
    });
    console.debug(accordion_data);
    return accordion_data;
}

export function solution_accordion_data(
    {
        accordion_data,
        location,
        linkUrl,
        expand_all = false,
        props = {},
        ivResultsDisplayHandler = f => f
    }
){
    let data_for_accordion = [];
    let donot_check = [];
    let solution_accordian_index = 70;
    accordion_data = accordion_data.sort(sorting_property('solution'));
    const locationSearch = get(props, 'location.search', '');

    for (let x=0; x<accordion_data.length; x++) {
        let build_solution;
        if (donot_check.length === 0 || (!donot_check.includes(accordion_data[x].solution))) {
            let results_link=<Fragment/>;
            let result_url=`${linkUrl}${accordion_data[x].results}&compatibility=${accordion_data[x].Rating}&accordionFrom=Drug-Solution&fullSolutionName=${accordion_data[x].solution}`;
            result_url = location === "/drugPoints" 
                            ? result_url + getUrlParametersNavigatingFromDrugMonograph('IVcompatibility', props)
                            : result_url + getUrlParameterFromSummaryPage(decodeURIComponent(locationSearch), IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE);
    
            const linkURL_encoded =encodeURI(result_url);
            if (accordion_data[x].Rating !== 'N') {
                const resultsText = accordion_data[x].results.length === 1 ? 'Result' : 'Results';
                results_link= <span className='alnright'>
                    <Fragment>
                        <Link key={'a-'} className="display-to-end"
                              onClick={() => ivResultsDisplayHandler(linkURL_encoded, props)}
                              to={linkURL_encoded}><b>{accordion_data[x].results.length}</b> {resultsText}</Link>
                    </Fragment>
                </span>
            }
            build_solution = <Fragment>
                <tr>
                    <td className="accordion-first"><span className="alnleft">Drug: {accordion_data[x].drug_name}</span> {results_link}</td>
                </tr>
                <tr>
                    <td>Solution: {accordion_data[x].solution}</td>
                </tr>
                <tr>
                    <td>Rating: <span
                        id={
                            get(iv_compatibility_elements.compatibility_image_elements,
                                accordion_data[x].Rating,'')
                        }>
                        {
                            get(iv_compatibility_elements.compatibility_elements,accordion_data[x].Rating,'')
                        }
                    </span>
                    </td>
                </tr>
            </Fragment>;
        } else{
            continue;
        }

        let product_info_url = <></>;
        if (accordion_data[x].moreSolutionDocumentId !== "") {
            let productUrl = `ivProductInfo?productDocId=${accordion_data[x].moreSolutionDocumentId}`;
            productUrl = location === "/drugPoints" 
                            ? productUrl + getUrlParametersNavigatingFromDrugMonograph('IVcompatibility', props)
                            : productUrl + getUrlParameterFromSummaryPage(decodeURIComponent(locationSearch), IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE);
            const encoded_productURL = encodeURI(productUrl);
            product_info_url=<Fragment >
                <Link key={'a1-' } className="display-to-end" to={encoded_productURL}
                      onClick={() => ivResultsDisplayHandler(encoded_productURL, props)}>
                    <Information className="iv_compatibility_fill"/>
                    Solution Information
                </Link>
            </Fragment>
        }

        data_for_accordion.push(
            <Collapsible
                key={solution_accordian_index++}
                id={get(iv_compatibility_elements.compatibility_image_elements,accordion_data[x].Rating,'')}
                title={`${accordion_data[x].solution}-${accordion_data[x].drug_name}`}
                open={expand_all}
            >
                <div className="iv-compat-subaccordion-collapsible-container">
                    <table>
                        <tbody>
                        {build_solution}
                        <tr>
                            <td className='alnright'>
                                {product_info_url}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Collapsible>
        );
    }
    console.debug(data_for_accordion);
    return <Accordion className="mdx-accordion">
            {data_for_accordion}
        </Accordion>;
}

export function sorting_property(property){
        return function (x, y) {
            return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
        };
};

export function areAllAccordionsEmpty(summary_page_data) {
  const admixtureList = getArray(summary_page_data, 'admixture.ivCompatibility.admixtureList');
  const syringeList = getArray(summary_page_data, 'syringe.ivCompatibility.syringeList');
  const ysiteList = getArray(summary_page_data, 'ysite.ivCompatibility.ysiteList');
  const tpnTnaArray = getArray(summary_page_data, 'tpnTna');
  let tpnList = [];
  let tnaList = [];
  tpnTnaArray.forEach(tpnTna => {
    let tpnTnaList = getArray(tpnTna, 'tpnTnaList');
    tpnTnaList.forEach(list => {
      let tpns = getArray(list, 'tpnList');
      if (tpns.length !== 0) {
        tpnList.push(tpns);
      }
      let tnas = getArray(list, 'tnaList');
      if (tnas.length !== 0) {
        tnaList.push(tnas);
      }
    });
  });
  const solutionArray = getArray(summary_page_data, 'solution');
  let otherSolutionsList = [];
  let commonSolutionsList = [];
  solutionArray.forEach(array => {
    const solutionList = getArray(array, 'ivCompatibility.solutionList');
    const otherSolutions = getArray(solutionList.length === 1 ? solutionList[0] : solutionList, 'otherSolutions');
    const commonSolutions = getArray(solutionList.length === 1 ? solutionList[0] : solutionList, 'commonSolutions');
    let testedCommonSolutions = commonSolutions.filter(solution => {
      let compatibilityCode = get(solution, 'solution.compatibilityCode', '');
      return compatibilityCode !== 'N';
    });
    if (testedCommonSolutions.length !== 0 ) {
      commonSolutionsList.push(testedCommonSolutions);
    }
    if (otherSolutions.length !== 0) {
      otherSolutionsList.push(otherSolutions)
    }
  });

  return admixtureList.length === 0 && syringeList.length === 0
    && ysiteList.length === 0 && tpnList.length === 0 && tnaList.length === 0
    && commonSolutionsList.length === 0 && otherSolutionsList.length === 0;

}

function filterSynonymsForDrugSolutions(item, uniqueDrugNames, drugsByAgentNames) {
  let drugName = get(item,'formDisplay[0].name', '');
  let agentName = get(item,'uniqueAgentsList.uniqueAgent.agentName', '');
  for (let [key, value] of drugsByAgentNames) {
    if (key === agentName) {
      uniqueDrugNames.push(value.values().next().value)
    }
  }

  return uniqueDrugNames.includes(drugName);
}

const getCompatibilitiesFiltersState = () => {
    let compatibilityFlags = [];
    const currentState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTER_PANEL_STATE_KEY))
    Object.values(currentState.compatibilities).forEach(value => compatibilityFlags
        .push(toBooleanArray(value, COMPATIBILITY_CODES.length)));
    return compatibilityFlags;
}

const IVCompatibilityList = connect(
    mapStateToProps,
    mapDispatchToProps
) (AdministrativeItems)
export default withRouter(IVCompatibilityList);

export const DrugDrugAccordionDisplay = props => {
    const {
        drug1,
        drug2,
        compatibilityIconId,
        compatibilityText,
        display_results_link,
        expand_all=false
    } = props;

    return (
        <Collapsible
            id={compatibilityIconId}
            title={`${drug1}-${drug2}`}
            open={expand_all}
        >
            <div className="iv-compat-subaccordion-collapsible-container">
                <table>
                    <tbody>
                    <tr>
                        <td className='accordion-first'>
                            <span className='alnleft iv-compat-subaccordion-row-header-text'>Drug 1:</span>
                            <span className="iv-compat-subaccordion-row-data-text">{drug1}</span>
                            {display_results_link}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="iv-compat-subaccordion-row-header-text">Drug 2:</span>
                            <span className="iv-compat-subaccordion-row-data-text">{drug2}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="iv-compat-subaccordion-row-header-text">Rating:</span>
                            <span className="iv-compat-subaccordion-row-severity-text" id={compatibilityIconId}>{compatibilityText}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Collapsible>
    );
}
AdministrativeItems.contextType = PrefixContext;