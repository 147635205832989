/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import React, {Component} from 'react';
import {connect, useSelector} from "react-redux";
import queryString from 'query-string';
import {get, set} from "../../utils";
import {
    makeDrugConsultsActionThunk,
    makeDrugConsultsClearStateAction,
    makeDrugConsultsModalPopupAction
} from "./drug-consults-utils/drug-consults-redux";
import {CitationModalPopup, TopLevelRenderer} from "../../components/schema-based-renderer";
import {Link} from 'react-router-dom';
import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";
import {makeDeleteUrlParametersAction} from "../../components/common/url-params-redux";
import {CommonFooter} from "../../components/common/footers/Footer";
import {
    DRUG_CONSULTS_PAGE_NAME
} from "../../components/response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';
import { DisplayText } from '../DrugInteractionPage/drug-interaction-utils';

const {ChevronLeft} = require('@carbon/react/icons');

const mapStateToProps = state => {
    return {
        current_state: state,
        modal_reference_index: get(state.drug_consults_page, 'modal_reference_index', ''),
        drug_consults_response: get(state.drug_consults_page, 'drug_consults_response', {}),
        url_parameters: get(state.url_params, 'url_parameters', ''),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMakeDrugConsultsActionThunk: (documentId, props) => {
            dispatch(makeDrugConsultsActionThunk(documentId, props));
        },
        onModalPopupEvent: citation_id => {
            dispatch(makeDrugConsultsModalPopupAction(citation_id));
        },
        onClearDrugConsultsState: () => {
            dispatch(makeDrugConsultsClearStateAction());
        },
        onClearUrlParameters : () => {
          dispatch(makeDeleteUrlParametersAction())
        }
    }
}

export const sectionNames = ['patientDataBackgroundSection', 'responseSection', 'conclusionSection', 'referenceSection'];

export class DrugConsultsPagePresentation extends Component {
    constructor(props) {
        super(props);

        const location = queryString.parse(get(props, "location.search", ""));
        props.onMakeDrugConsultsActionThunk(
            get(location, 'drugConsultsDocumentId', ''),
            this.props
        );
    }

    render() {
        const mdxSectionChildGroup = get(this.props, 'drug_consults_response', {});
        const referenceSection = get(mdxSectionChildGroup, 'referenceSection', {});
        let xrefList = [];
        let responseSections = [];
        if (referenceSection) {
            xrefList = get(referenceSection.pwaSectionChild, 'mdxSectionChildGroup', {});
        }
        for (let index in sectionNames) {
            if (mdxSectionChildGroup.hasOwnProperty(sectionNames[index])) {
                responseSections.push(mdxSectionChildGroup[sectionNames[index]]);
            }
        }

        return <DrugConsults
            modal_reference_index={this.props.modal_reference_index}
            current_state={mdxSectionChildGroup}
            responseSections={responseSections}
            xrefList={xrefList}
            modalPopupHandler={this.props.onModalPopupEvent}
            locationSearch={queryString.parse(this.props.location.search)}
            url_parameters={this.props.url_parameters}
        />
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const currentState = this.props.current_state;
      const currentDocumentId
        = get(currentState, "drug_consults_page.drug_consults_response.documentId", "")
      const location = queryString.parse(get(this.props, "location.search"));
      if (currentDocumentId && location.drugConsultsDocumentId !== currentDocumentId) {
        this.props.onMakeDrugConsultsActionThunk(
          get(location, 'drugConsultsDocumentId', ''),
          this.props
        );
        set(
          this.props,
          "current_state.drug_consults_page.drug_consults_response.documentId",
          get(location, "drugConsultsDocumentId", "")
        );
      }
    }

    componentDidMount() {
      this.props.onClearUrlParameters()
    }

    componentWillUnmount() {
        this.props.onClearDrugConsultsState();
    }
}

export const DrugConsultsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(DrugConsultsPagePresentation)

export default DrugConsultsPage;

export const DrugConsults = ({
                                 current_state,
                                 responseSections = [],
                                 xrefList = [],
                                 modalPopupHandler = f => f,
                                 locationSearch = {},
                                 modal_reference_index = '',
                                 url_parameters = ''
                             }) => {

    const popup_handler_map = {
        xref_popup_handler: modalPopupHandler
    }
    const drugConsultsTooltipInfo = `
    Evidence-based, fully referenced
    articles that cover a wide range of
    topics on drug therapies and
    specific drug guidelines
    `;
    let returnArray = [];
    for (let index in responseSections) {
        const child_props = {
            is_drug_consults: true,
            main_topic_json: {...responseSections[index]},
            popup_handler_map: popup_handler_map
        }
        returnArray.push(<TopLevelRenderer {...child_props} />);
    }
    const drugName = decodeURIComponent(get(locationSearch, 'drugName', ''));
    const lastModifiedDate = (get(current_state, 'lastModifiedDate', ''));
    const drugConsultsResponseLoading = useSelector((state) => get(state, `inline_loading.${DRUG_CONSULTS_PAGE_NAME}`, {}));
    const inlineLoading = <InlineLoading className="drug-consults-loading"
                                         description={drugConsultsResponseLoading.description}
                                         status={drugConsultsResponseLoading.status} />;

    return <div className="main-container">
        <div className="empty-container"></div>
        <div className="main-content">
            <div className="mdx-scroll-area">
                <div className="drug-consults">
                    <div className="drug-consults-return-link-container">
                        <Link className="drug-consults-return-link" to={getReturnLinkLocation(locationSearch, drugName,
                            url_parameters)}>
                            <ChevronLeft className="chevronleft16_fill-details"/>
                            <span>{drugName}</span>
                        </Link>
                    </div>
                    <h1 className="drug-consults-title">
                        {current_state.title}
                    </h1>
                    <div data-floating-menu-container className="drug-consults-info tooltip-container">Drug consults
                        {/* <Tooltip key='tooltip' className="drug-consults-tooltip" iconDescription="Drug Consults">
                            <div dangerouslySetInnerHTML={{__html: drugConsultsTooltipInfo}}/>
                        </Tooltip> */}
                        <DisplayText
                            text={drugConsultsTooltipInfo}
                        />
                    </div>
                    {drugConsultsResponseLoading.status
                        ? inlineLoading
                        : <div>
                            <div className="drug-consults-response">
                                {returnArray}
                            </div>
                            {
                                lastModifiedDate !== ''
                                &&
                                <div className="drug-consults-modified-date">
                                Last Modified: {lastModifiedDate}
                                </div>
                            }
                        </div>
                    }
                </div>
              <CommonFooter/>
            </div>
        </div>
        {
            modal_reference_index !== '' && xrefList.length !== 0
            &&
            <CitationModalPopup
                citationId={modal_reference_index}
                xrefList={xrefList}
                onCloseHandler={() => modalPopupHandler('')}
            />
        }
        <CommonOverflowModalPopups/>
    </div>
}

const getReturnLinkLocation = (locationSearch, drugName, url_parameters) => {
    const contentSetId = get(locationSearch, 'contentSetId', '');
    const documentId = get(locationSearch, 'documentId', '');
    const topicId = get(locationSearch, 'topicId', '');
    const quickLinkDocumentId = get(locationSearch, 'quickLinkDocumentId', '');
    const inDepthLinkDocumentId = get(locationSearch, 'inDepthLinkDocumentId', '');
    const drugEvalsId = get(locationSearch, 'drugEvalsId', '');
    const inDepthId = drugEvalsId ? `&drugEvalsId=${drugEvalsId}` : `&inDepthLinkDocumentId=${inDepthLinkDocumentId}`

    const returnLinkProperties = `?contentSetId=${contentSetId}&drugName=${encodeURIComponent(drugName)}&documentId=${documentId}&topicId=${topicId}`;
    let linkUrl = contentSetId === '31' ? `/inDepth${returnLinkProperties}&quickLinkDocumentId=${quickLinkDocumentId}`
        : `/drugPoints${returnLinkProperties}${inDepthId}`;

    if (url_parameters) {
      return contentSetId === '31'
        ? `/inDepth${url_parameters}`
        : `/drugPoints${url_parameters}`
    }
    return encodeURI(linkUrl);
}
