/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Fragment, Component } from 'react';
import {Button, TextInput, ToastNotification} from "@carbon/react";
import { get, immutable_set } from "../../utils";
import * as serviceWorker from '../../services/registerServiceWorker';
import {
    makeSetUsernameAndPasswordAction,
    makeLoginThunk,
    getClientConfigurationThunk
} from './login-page-redux'
import {connect} from "react-redux";
import {getIPLoginClientConfigurationThunk, makeIpLoginThunk} from "../IpLoginPage/ip-login-page-redux";
import {DisplayText} from "../DrugInteractionPage/drug-interaction-utils";
import {
    Header,
    HeaderName,
    SkipToContent
} from "@carbon/react";
import {makeGlobalClearStateThunk} from "../LogoutPage/logout-page-redux";
import {BlackFooter} from "../../components/common/footers/BlackFooter";
import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";
import {
  makeSetErrorModalAction, OVERFLOW_MENU_GLOBAL_STATE_NAME
} from "../../components/common-overflow-menu/common-overflow-redux/common-overflow-redux";
import {
  INCORRECT_LOGIN_CREDENTIALS_HEADER,
  INCORRECT_LOGIN_CREDENTIALS_MESSAGE
} from "../../components/common/constants";

const PasswordProps = {
    className: 'login-page--password',
    id: 'login-password',
    labelText: 'Password',
};

const UsernameProps = {
    className: 'login-page--username',
    id: 'login-username',
    labelText: 'Username',
};

export const LoginButtonProps = {
    id: 'login-login-button',
    className: 'App',
    kind: 'primary',
    disabled: false,
    size: 'small',
    renderIcon: undefined,
};

export class LoginPagePresentation extends Component {
    initial_url_params = {};
    constructor(props) {
        super(props);
        this.state = {
            usernameValue: '',
            passwordValue: ''
        };
        this.updateUsernameValue = this.updateUsernameValue.bind(this)
        this.updatePasswordValue = this.updatePasswordValue.bind(this)

        if (String(window.performance.getEntriesByType("navigation")[0].type) === "reload") {
          this.props.onPageRefresh();
        }
    }

    render() {

        let show_ip_authentication_failure = get(this.props, 'showManualIPLoginFailureMessage', false);
        let show_inline_toast = get(this.props, 'showErrorPopupProps.show_inline_toast', false);
        let ip_authentication_failure = <Fragment/>;
        if(show_ip_authentication_failure) {
            ip_authentication_failure = <div className={"ip_login_failure_header"}><b>IP authentication failed.</b><div className={"ip_login_failure_body"}>Please connect to a network that can provide a valid IP address for authentication, or use a username/password to login.</div></div>
        }

        return (
            <div className="Login">
                <Header aria-label="Merative Platform Name" className="chatbot-custom-header">
                    <SkipToContent />
                    <HeaderName href="#" prefix="Merative">
                         Micromedex®
                    </HeaderName>
                </Header>
                <div className="mdx-scroll-area">
                <div className="mdx-loginBannerImg">
                    <img size="small" src="images/micromedex-lead.png" alt="" className='login-image'/>
                </div>

                <div className="mdx-login-content">
                    <link rel="manifest" href="%PUBLIC_URL%/manifest.json"></link>
                    <div className="mdx-left-content">
                    <div className="mdx-form-element">
                    <form>
                        <TextInput
                            value={this.state.usernameValue}
                            required
                            onChange={evt => this.updateUsernameValue(evt)}
                            {...UsernameProps}
                            autoComplete='username'
                        />
                        <TextInput
                            type="password"
                            required
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                            value={this.state.passwordValue}
                            onChange={evt => this.updatePasswordValue(evt)}
                            {...PasswordProps}
                            autoComplete='current-password'
                        />
                        <Fragment>
                          {
                            show_inline_toast &&
                            <ToastNotification
                              className="login-incorrect-toast"
                              lowContrast={true}
                              title={INCORRECT_LOGIN_CREDENTIALS_MESSAGE}
                              caption=' '
                              onCloseButtonClick={() => this.props.onDismissIncorrectLoginMessage()}
                              subtitle={INCORRECT_LOGIN_CREDENTIALS_HEADER}
                              kind={"error"}
                            />
                          }
                        </Fragment>
                        <Button size="small"
                            onClick={() => {
                             this.props.onLogin(
                                 this.state.usernameValue,
                                 this.state.passwordValue,
                                 this.props,
                             )
                              }}
                            {...LoginButtonProps}>
                            Login
                        </Button>
                    </form>
                    </div>
                </div>
                <div className="mdx-right-content">
                <div className='ip-login-txt'>
                        <u className="ip-login-click-handler-element" onClick={() => {this.IPAuthenticate(this.props)}}>
                           IP Authentication
                        </u>
                        <DisplayText
                            text="This option is for organizations which have I.P authentication enabled, for users to authenticate via I.P. address (login without needing a username and password)"
                        />
                    </div>
                    {ip_authentication_failure}
                            <div className='support-data'>
                                <span>
                                        24 x 7 Technical Support and Call Center Access are available from Micromedex
                                        Support
                                        <br/>
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           style={{color:"#0072ce"}}
                                           href="https://www.merative.com/contact">
                                        https://www.merative.com/contact
                                    </a>
                                    </span>
                            </div>
                            <div className='support-data'>
                                <span>
                                    U.S. and Canada Phone: 1-800-525-9083
                                    <br/>
                                    Global: 1-303-486-6444
                                    <br/>
                                    <a target="_blank"
                                       rel="noopener noreferrer"
                                       style={{color:"##0072ce"}}
                                       href="https://www.merative.com/contact">
                                        Contact Us
                                    </a>
                                </span>
                            </div>
                    </div>
                </div>
            </div>
              <BlackFooter />
              <CommonOverflowModalPopups/>
            </div>
        ); //endreturn
    }

    updateUsernameValue(evt) {
        this.setState(
            immutable_set(this.state,  "usernameValue", evt.target.value)
        );
    }

    updatePasswordValue(evt) {
        this.setState(
            immutable_set(this.state,  "passwordValue", evt.target.value)
        );
    }

    IPAuthenticate(props){
        props.onIPAuthenticate(props);
    }
} //endclass


    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
            serviceWorker.register();
        });
    }

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (username, password, loginPageProps) => {
            dispatch(makeGlobalClearStateThunk())
            dispatch(makeSetUsernameAndPasswordAction(username, password))
            dispatch(makeLoginThunk(loginPageProps));
            // TODO: perhaps getClientConfigurationThunk() should be chained after a successfull makeLoginThunk()
            dispatch(getClientConfigurationThunk(loginPageProps));
        },
        onIPAuthenticate: (props) => {
            dispatch(makeGlobalClearStateThunk())
            dispatch(makeIpLoginThunk(props, true));
            dispatch(getIPLoginClientConfigurationThunk(props));
        },
        onPageRefresh: () => {
          dispatch(makeSetErrorModalAction({show_modal: false}))
        },
        onDismissIncorrectLoginMessage: () => {
          dispatch(makeSetErrorModalAction({show_inline_toast: false}))
        }
    }
}

const mapStateToProps = state => {
    return {
        showManualIPLoginFailureMessage: get(state.login_page,'showManualIPLoginFailureMessage', false),
        showErrorPopupProps: get(state, OVERFLOW_MENU_GLOBAL_STATE_NAME + '.showErrorPopupProps', '')
    }
}

export const LoginPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPagePresentation)

export default LoginPage;
