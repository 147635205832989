/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment} from 'react';

import {
    InDepthShowQuickAnswers
} from "../in-depth-components";

import {get, isEmptyObject} from "../../../utils";
import {
    TEMPLATE_TYPE_IN_DEPTH,
    TEMPLATE_TYPE_IV_COMPATIBILITY,
    TEMPLATE_TYPE_LINK_TO_QUICK_ANSWERS,
    getTemplateType
} from '../in-depth-utils'

import { LinkToQuickAnswers} from "./LinkToQuickAnswers";
import {getMainTopicData} from "../in-depth-utils";
import {TopLevelRenderer} from "../../../components/schema-based-renderer";
import RelatedResults from "../../../components/related-results/RelatedResultsAccordion";
import {useSelector} from "react-redux";
import {
    IN_DEPTH_PAGE_NAME
} from "../../../components/response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';

import {DRUG_POINTS_PAGE_NAME} from "../../../components/response-loading/redux/response-loading-redux";

export function DispatchOnTemplateType(
    {
        current_state={},
        modalPopupHandler=f=>f,
        sectionPopupHandler=f=>f,
        switchInDepthQuick=f=>f,
        subscription_state={},
        clientConfig={},
        location={},
        relatedResults={}
    }
) {
    const method = 'InDepth.DispatchOnTemplateType'
    const template_type = getTemplateType(current_state)
    const ref_data = current_state.reference_data;

    const NO_RESULTS_AVAILABLE = 'No results available'
    const relatedResultsProps = {...relatedResults, location}
    const relatedResultsComponent = <RelatedResults key={template_type+'.r'}
                                                    childProps={relatedResultsProps}/>

    let returnArray = [];
    returnArray.push(
      <div key='dispatch.z'>
        <h3 className="mdx-section-heading-title instructions">{ref_data.group_title}</h3>
        <h5>{ref_data.section_title}</h5>
      </div>
    )
    const inDepthResponseLoading = useSelector((state) => get(state, `inline_loading.${IN_DEPTH_PAGE_NAME}`, {}));
    const drugPointsResponseLoading = useSelector((state) => get(state, `inline_loading.${DRUG_POINTS_PAGE_NAME}`, {}));
    const params = {
        current_state,
        modalPopupHandler,
        sectionPopupHandler,
        switchInDepthQuick,
        clientConfig,
        location,
        drugPointsResponseLoading
    }

    if (inDepthResponseLoading.status) {
        returnArray.push(<InlineLoading className='drug-monograph-loading'
                                        description={inDepthResponseLoading.description}
                                        status={inDepthResponseLoading.status} /> )
    } else {
        switch (template_type) {
            case TEMPLATE_TYPE_LINK_TO_QUICK_ANSWERS:
                returnArray.push(<LinkToQuickAnswers
                    key={template_type + '.a'} {...params}/>);
                returnArray.push(<InDepthShowQuickAnswers
                    key={template_type + '.b'}  {...params}/>);
                returnArray.push(relatedResultsComponent)
                break;
            case TEMPLATE_TYPE_IV_COMPATIBILITY:
                returnArray.push(<InDepthShowQuickAnswers
                    key={template_type + '.b'}  {...params}/>);
                returnArray.push(relatedResultsComponent)
                break;
            case TEMPLATE_TYPE_IN_DEPTH:
            default:
                // OLD: returnArray.push(<InDepthParent  { ...params}/>);
                // NEW: ...... starts HERE

                console.debug(method, ": current_state=", current_state)
                const main_topic_json = getMainTopicData(current_state) // an array of datasets
                console.debug(method, ": main_topic_json=", main_topic_json)

                if (!main_topic_json[0] || isEmptyObject(main_topic_json[0])) {
                    break;
                }

                const responseLength = main_topic_json.length
                let sectionHasData = false;
                for (let i = 0; i < responseLength; i++) {
                    const sectionHeading =
                        get(main_topic_json[i], 'pwaSectionChild.mdxSectionChildGroup.section.mdxSectionGroup.heading', '');
                    if (sectionHeading !== NO_RESULTS_AVAILABLE) {
                        sectionHasData = true;
                        break;
                    }
                }

                if (!sectionHasData) {
                    returnArray.push(<InDepthShowQuickAnswers
                        key={template_type + '.b'}  {...params}/>);
                    returnArray.push(relatedResultsComponent)
                    break;
                }

                returnArray.push(<LinkToQuickAnswers
                    key={template_type + '.a'} {...params}/>);

                const popup_handler_map = {
                    xref_popup_handler: modalPopupHandler,
                    sectionPopupHandler,
                    hiddenTextPopupHandler: f => f
                }
                console.debug(method, ":  popup_handler_map=", popup_handler_map)


                const modal_reference_index = get(current_state, 'modal_reference_index', '')
                console.debug(method, ": ref_data=", ref_data);

                const rendered_doc = <Fragment key={template_type + '.b'}>
                    {relatedResultsComponent}
                    {
                        main_topic_json.map(
                            (topic_json, index) => {
                                const params = {
                                    main_topic_json: topic_json,
                                    ref_data,
                                    popup_handler_map,
                                    other_params: {
                                        clientConfig
                                    },
                                    switchInDepthQuick,
                                    subscription_state,
                                    is_in_depth: true,
                                    is_top_level: true,
                                    location
                                }
                                console.debug(method, ": TopLevelRenderer params=", params)
                                const sectionHeading = get(params.main_topic_json,
                                    'pwaSectionChild.mdxSectionChildGroup.section.mdxSectionGroup.heading', '');
                                return responseLength > 1 && sectionHeading === NO_RESULTS_AVAILABLE ?
                                    <Fragment key={index}/> :
                                    <TopLevelRenderer key={index} {...params} />
                            }
                        )
                    }
                </Fragment>
                console.debug(method, "........xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx modal_reference_index=", modal_reference_index)

                returnArray.push(rendered_doc);
                break
        }
    }

    return returnArray;
}
