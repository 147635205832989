/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {userService} from "../../services";
import {get, immutable_set} from "../../utils";
import {handleError} from "../../utils/internal-error-handler";
import {makeQuickClearStateAction} from "../DrugPointsPage2/drug-points-utils";
import {makeClearIndepthState} from "../InDepthPage2/in-depth-utils";
import {
    makeDrugInteractionClearStateAction,
    makeDrugInteractionSearchClearStateAction,
    makeInteractionDetailsClearStateAction
} from "../DrugInteractionPage/drug-interaction-utils";
import {makeIvCompatSearchClearStateAction} from "../IVCompatibilityPages/iv-compatibility-search-page/iv-compatibility-search-utils";
import {makeIVCompatSummaryClearStateAction} from "../IVCompatibilityPages/iv-compatibility-summary-page/iv-compatibility-summary-utils";
import {makeIVDetailsClearStateAction} from "../IVCompatibilityPages/iv-details-page/iv-details-utils";
import {makeIVProductInfoClearStateAction} from "../IVCompatibilityPages/iv-product-info-page/iv-product-info-utils";
import {makeChatClearStateAction} from "../ChatbotPage/chatbot-utils";
import {makeLoginClearStateAction} from "../LoginPage/login-page-redux";
import {makeClearTokenAuthenticationState} from "../GatewayPage/gateway-page-redux";
import {makeClearIpAuthenticationState} from "../IpLoginPage/ip-login-page-redux";
import {makeDrugConsultsClearStateAction} from "../DrugConsultsPage/drug-consults-utils/drug-consults-redux";
import {makeClearCommonOverflowState} from "../../components/common-overflow-menu/common-overflow-redux";
import {makeSetUnsubscribedModuleAction} from "../../components/subscription/subscription-notification-redux";
import {resetFiltersState} from "../../components/checkbox-filter/checkbox-filters-state";
import {GENERIC_ERROR_MESSAGE} from "../../components/common/constants";
import {makeRelatedResultsClearStateAction} from "../../components/related-results/redux/related-results-redux";
import {makeDeleteUrlParametersAction} from "../../components/common/url-params-redux";
import {makeClearInlineLoadingStateAction} from "../../components/response-loading/redux/response-loading-redux";
import {makeClearHomePageStateAction} from "../HomePage/home-page-utils";

/**
 * For each reducer in combined_reducer,
 * dispatch the corresponding clear state action
 * @returns {function(...[*]=)}
 */
export function makeGlobalClearStateThunk() {
    console.debug('makeGlobalClearStateThunk: ENTER');

    return (dispatch, getState) => {
        dispatch(makeClearHomePageStateAction())

        dispatch(makeDrugInteractionClearStateAction())
        dispatch(makeInteractionDetailsClearStateAction())
        dispatch(makeDrugInteractionSearchClearStateAction())

        dispatch(makeIvCompatSearchClearStateAction())
        dispatch(makeIVCompatSummaryClearStateAction())
        dispatch(makeIVDetailsClearStateAction())
        dispatch(makeIVProductInfoClearStateAction())

        dispatch(makeChatClearStateAction())
        dispatch(makeQuickClearStateAction())
        dispatch(makeClearIndepthState())

        dispatch(makeLoginClearStateAction())
        dispatch(makeClearTokenAuthenticationState())
        dispatch(makeClearIpAuthenticationState())

        dispatch(makeClearCommonOverflowState())
        dispatch(makeSetUnsubscribedModuleAction(''))
        dispatch(makeDrugConsultsClearStateAction())
        dispatch(makeRelatedResultsClearStateAction())
        dispatch(makeDeleteUrlParametersAction())
        dispatch(makeClearInlineLoadingStateAction())
        resetFiltersState()
    }
}


const defaultLogoutPageState = {
}

// Action constants
export const A = {
    SET_LOGOUT_STATE: 'SET_LOGOUT_STATE',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_IS_RELOGIN_STATE: 'SET_IS_RELOGIN_STATE'
}
export function makeLogoutAction(response) {
    return {
        type: A.SET_LOGOUT_STATE
    }
}
export function makeSetErrorMessageAction(errorMessage='') {
    return {
        type: A.SET_ERROR_MESSAGE,
        errorMessage
    }
}

export function makeSetReloginStateAction(reloginState = false) {
    return {
        type: A.SET_IS_RELOGIN_STATE,
        reloginState
    }
}

//Reducers

export function updateSetLogoutReducer( action={}) {
    let new_state = {}
    return new_state;
}
export function updateSetErrorMessageReducer(current_state={}, action={}) {
    let new_state =  immutable_set(
        current_state,
        'errorMessage',
        action.errorMessage
    )
    return new_state;
}

export function setIsReloginStateReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'isReloginState',
        action.reloginState
    )
}

export function logout_page(
    current_state=defaultLogoutPageState,
    action={}
) {
    console.debug('inside logout page')
    switch (action.type) {
        case A.SET_LOGOUT_STATE:
            return updateSetLogoutReducer(action)
        case A.SET_ERROR_MESSAGE:
            return updateSetErrorMessageReducer(current_state, action)
        case A.SET_IS_RELOGIN_STATE:
            return setIsReloginStateReducer(current_state, action)
        default:
            return current_state
    }
}

// redux-thunk THUNKs
export function makeLogoutThunk(logoutPageProps={}) {
    return (dispatch,getState) => {
        userService.logout(logoutPageProps, get(getState(), 'login_page.mdxAuthenticationJson.Set-Cookie', ''))
            .then(function (response) {
                let current_state = getState();
                console.debug('current state in side logout Thunk :',current_state);
                dispatch(makeGlobalClearStateThunk())
                current_state = getState();
                console.debug('After clearing current state in side logout Thunk :',current_state)

                //Logout here
                logoutPageProps.history.push("/login");

            }).catch((err)=> {
            console.error("Logout failed.",err); // TODO: better error handling/logging
            handleError({props: logoutPageProps, message:GENERIC_ERROR_MESSAGE, dispatch},
              makeSetErrorMessageAction(err));
            return;
        });
    }
}