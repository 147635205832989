/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React from 'react'
import {Button } from "@carbon/react"

import {useCheckboxPanelState} from "./CheckboxFilterPanelHook";
import {CheckboxFilterPanelPure} from "./CheckboxFilterPanel";
import {isFilterPanelOpened} from "../../utils/commonIvDiUtils";
import OutsideClickHandler from 'react-outside-click-handler';
import { Tag } from '@carbon/react';

const {SettingsAdjust, Information} = require('@carbon/react/icons')

const NO_OP_FUNCTION = f=>f

/**
 * Stateful top level checkbox filter component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxFilterComponent = props => {
    // destructure props
    const {
      //  checkbox_filter_id = '',
        checkbox_groups_list = [], // list of checkbox filter group definitions ... see CheckboxFilterGroup.INITIAL_CHECKBOX_GROUP_STATE
        submit_filter_items = NO_OP_FUNCTION, // parent page callback called when filter Apply button is clicked
        InfoPanelComponent,  // optional; component to display when filter's info icon is clicked
        disableIcons = false, // disable icons while loading
        invokedSectionName,
        panel_button_click_handler=f=>f,
        panel_state = []
    } = props

    // custom Hook that tracks filter component state changes
    const filter_panel_state = useCheckboxPanelState(
        checkbox_groups_list, // checkbox_groups
        submit_filter_items, // submitFilteredItems
        invokedSectionName,
        panel_button_click_handler,
        panel_state
    )

    // destructure filter panel state
    let {
        // filter pill ...
        isPillDisplayed,  // boolean
        pillCount,        // integer
        resetFilterPill,  // filter pill onclick handler

        // filter panel ...
        isFilterPanelOpen,   // boolean
        toggleFilterPanelOpen,  // filter icon onclick handler

        // info panel ...
        isInfoPanelOpen,   // boolean
        toggleInfoPanelOpen, // info icon onclick handler
        closeFilterPanel
    } = filter_panel_state

    //needed to handle non IV summary pages
    if (isFilterPanelOpen && !isFilterPanelOpened(panel_state, invokedSectionName)) {
        isFilterPanelOpen = false;
    }

    // render filter pill if it is displayed
    let filter_pill;
    if (isPillDisplayed) {
        let pill_click_handler = resetFilterPill
        if (isFilterPanelOpen || disableIcons) {
            pill_click_handler = NO_OP_FUNCTION
        }
        filter_pill =
            <Tag className="filter-pill-tag"
                type="cool-gray" 
                disabled={isFilterPanelOpen || disableIcons} 
                onClick={pill_click_handler}
                filter>
                  {pillCount}
            </Tag>
    }

    // render info icon if it can open an InfoPanelComponent
    let info_button
    if (InfoPanelComponent) {
        let info_panel_class = "checkbox-component-icon"
        let info_panel_onclick = toggleInfoPanelOpen
        if (disableIcons) {
            info_panel_class = "checkbox-component-icon-disabled"
            info_panel_onclick = NO_OP_FUNCTION
        }
        info_button =
            <Button className="checkbox-component-button" onClick={info_panel_onclick}>
               <Information className={info_panel_class}/>
            </Button>
    }

    // render the complete checkbox filter component
    let filter_panel_button_class = "checkbox-component-icon"
    let filter_panel_button_onclick = toggleFilterPanelOpen
    if (disableIcons) {
        filter_panel_button_class = "checkbox-component-icon-disabled"
        filter_panel_button_onclick = NO_OP_FUNCTION
    }

    return <OutsideClickHandler
      onOutsideClick={() => closeFilterPanel()}
    >
      <div className="checkbox-filter-component">
        {filter_pill}
        <div className="checkbox-filter-component-icon-box">
          <div className="checkbox-filter-panel-container">
            <Button className="checkbox-component-button" onClick={filter_panel_button_onclick}>
              <SettingsAdjust className={filter_panel_button_class}/>
            </Button>
            {
              isFilterPanelOpen
              &&
              <CheckboxFilterPanelPure
                {...filter_panel_state}
                checkbox_groups_list={checkbox_groups_list}
                invokedSectionName={invokedSectionName}
                panel_state={panel_state}
              />
            }
          </div>
          {info_button}
        </div>
        {
          (InfoPanelComponent && isInfoPanelOpen) && <InfoPanelComponent onCloseHandler={toggleInfoPanelOpen}/>
        }
      </div>
    </OutsideClickHandler>
}

export const filterCheckboxItemsUsingFlags = (filter_group_array = [], filter_flags_array = []) => {
    return filter_group_array.map(
        ({checkbox_items}, group_index) => {
            const group_flags = filter_flags_array[group_index]
            return checkbox_items.filter(
                (item_name, item_index) => group_flags[item_index]===true
            )
        }
    )
}