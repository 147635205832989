/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {
    getWordWheelPromise,
    IV_COMPATIBILITY_DRUGS_SEARCH,
    IV_COMPATIBILITY_SOLUTIONS_SEARCH
} from "../../../../services/wordWheelService";

import {makeSetChatbotReturnTarget} from "../../../ChatbotPage/chatbot-utils";
import {immutable_set} from "../../../../utils";
import {
    PICKLIST_INITIAL_STATE,
    handle_picklist_action
} from "../../../../components/ivpicklist/iv-picklist-redux";
import {
  ACTIVE_STATUS,
  LOADING_DESCRIPTION,
  makeResponseLoadingAction,
  WORD_WHEEL_GENERAL_NAME
} from "../../../../components/response-loading/redux/response-loading-redux";

export const IV_DRUG_PICKLIST_ID = 'iv_drug_picklist'
export const IV_SOLUTION_PICKLIST_ID = 'iv_solution_picklist'

export const EMPTY_STATE ={
    searchType:'',
    searchTerm:'',
    loadLimit:'',
    SEARCH_RESULTS:{},
    [IV_DRUG_PICKLIST_ID]: PICKLIST_INITIAL_STATE,
    [IV_SOLUTION_PICKLIST_ID]: PICKLIST_INITIAL_STATE,
}

export const IV_COMPATIBILITY_SEARCH_PAGE = 'iv_compatibility_search_page' // *MUST* match name of top level reducer function

export const A = {
    IV_COMPAT_SEARCH_RESULTS: 'IV_COMPAT_SEARCH_RESULTS',
    IV_COMPAT_CLEAR_SEARCH_STATE: 'IV_COMPAT_CLEAR_SEARCH_STATE'
}

// actions ...
export  function makeIvCompatSearchWordWheelResponseAction(word_wheel_response_json={}){
    return {
        type: A.IV_COMPAT_SEARCH_RESULTS,
        word_wheel_response_json
    }
}

export function makeIvCompatSearchClearStateAction() {
    return {
        type: A.IV_COMPAT_CLEAR_SEARCH_STATE
    }
}

export function makeIvCompatSearchGoToChatbotActionThunk(props={}) {
    return (dispatch) => {
        dispatch(makeSetChatbotReturnTarget('IvCompatibilitySearchPage')); // TODO: stitch in page name
        props.history.push('/chatbot')
    }
}


export function wordWheelIvCompatPicklistFetcher(
    searchTerm,
    searchType= IV_COMPATIBILITY_DRUGS_SEARCH,
    loadLimit= "20000",
    wordWheelFetcher = getWordWheelPromise,
    login_page,
    dispatch,
    props
){
    console.debug("Inside wordWheelIvCompatPicklistFetcher", searchTerm, searchType);
    const wordWheelLoadingName = searchType + WORD_WHEEL_GENERAL_NAME;
    dispatch(makeResponseLoadingAction(wordWheelLoadingName, ACTIVE_STATUS, LOADING_DESCRIPTION));
    return wordWheelFetcher(
        {
            searchTerm, searchType, loadLimit
        }, login_page
    )
}

export function wordWheelIvDrugPicklistFetcher(searchText, login_page, dispatch, props){
    return wordWheelIvCompatPicklistFetcher(
        "",
        IV_COMPATIBILITY_DRUGS_SEARCH,
        "20000",
        getWordWheelPromise,
        login_page,
        dispatch,
        props
    );
}
export function wordWheeIvSolutionPicklistFetcher(searchText, login_page, dispatch, props){
    return wordWheelIvCompatPicklistFetcher(
        "",  // !!!!!!!!!!!!!!!!!!!! ivSolution wordwheel search only works with "" as searchText !!!!!!!!!!!!!!!!
        IV_COMPATIBILITY_SOLUTIONS_SEARCH,
        "20000",
        getWordWheelPromise,
        login_page,
        dispatch,
        props
    );
}

//Reducers ...
export function wordwheelIvCompatResponseReducer(current_state={}, action={}){
    console.debug('Inside wordwheelIvCompatResponseReducer ')
    return immutable_set(
        current_state,
        'SEARCH_RESULTS',
        action.word_wheel_response_json
    )
}
export function clearIvCompatSearchReducer(current_state={}, action={}){
    console.debug('Inside clearIvCompatSearchReducer ')
    return EMPTY_STATE;
}

export function iv_compatibility_search_page(
    current_state = EMPTY_STATE,
    action = {}
) {
    switch(action.type) {
        case A.IV_COMPAT_SEARCH_RESULTS:
            return wordwheelIvCompatResponseReducer(current_state,action);
        case A.IV_COMPAT_CLEAR_SEARCH_STATE:
            return clearIvCompatSearchReducer(current_state, action);
        default:
            const new_state = handle_picklist_action(
                IV_COMPATIBILITY_SEARCH_PAGE,
                current_state,
                action
            )
            if(new_state){
                return new_state;
            }
            return current_state
    } //endswitch
}