/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {
    userService
} from '../../services'
import {
    immutable_set
} from "../../utils";
import {makeSetAuthenticationStateAction, makeSetClientConfigurationAction, makeShowManualIpAuthenticationFailed} from "../LoginPage/login-page-redux";
import {
  handleError
} from "../../utils/internal-error-handler";
import {makeSetErrorModalAction} from "../../components/common-overflow-menu/common-overflow-redux/common-overflow-redux";
import {GENERIC_ERROR_MESSAGE, IP_AUTH_ERROR_MESSAGE} from "../../components/common/constants";
import { DESIRED_LOCATION_HASH } from "../../utils/commonUtils";
import {trackAccountEvents} from "../../utils/analytics-tracker-utils";

const DEFAULT_IP_LOGIN_PAGE_STATE = {
    /*
    Example:
    {
        "authorization": "success",
        "quickSubscription": "yes",
        "indepthSubscription": "yes"
    }
     */
    mdxAuthenticationJson: {},
    errorMessage:''
}

const PATH_TO_MDX_AUTHENTICATION_JSON = 'mdxAuthenticationJson';

// Action constants
export const A = {
    SET_AUTHENTICATION_STATE: 'SET_AUTHENTICATION_STATE',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    CLEAR_IP_AUTHENTICATION_STATE: 'CLEAR_IP_AUTHENTICATION_STATE'
}


// Action Creators
export function makeClearIpAuthenticationState() {
    return {
        type: A.CLEAR_IP_AUTHENTICATION_STATE
    }
}

export function makeSetIpLoginAuthenticationStateAction(authenticationJson={}) {
    return {
        type: A.SET_AUTHENTICATION_STATE,
        authenticationJson
    }
}

export function makeSetIpLoginErrorMessageAction(errorMessage='') {
    return {
        type: A.SET_ERROR_MESSAGE,
        errorMessage
    }
}

// redux-thunk THUNKs
export function makeIpLoginThunk(loginPageProps={}, manualRequest=false) {
    return (dispatch, getState) => {
        dispatch(makeSetErrorModalAction({show_toast_modal: false, error_type: ""}))
        const shouldRedirectToLoginPage = true;
        const shouldShowErrorPopup = true;

        return userService.ipLogin(loginPageProps, getState())
            .then(function(response) {
                return {"data": response.json(), "status": response.status};
            }).then(function(response){ //NOTE not the full response
                if (response.status === 200) {
                    console.info("Ip Authentication is success...! response.status==200");
                    return response.data
                } else if (response.status === 403) {
                    console.error("I got a successful response but did not log in! response.status==403");
                    if(manualRequest) {
                        handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE,
                            shouldRedirectToLoginPage, dispatch},
                          makeShowManualIpAuthenticationFailed(true));
                        return Promise.resolve();
                    } else {
                        handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE,
                          shouldRedirectToLoginPage, dispatch},
                          makeShowManualIpAuthenticationFailed(true));
                        return Promise.resolve();
                    }
                } else {
                    console.error("UNHANDLED response.status==", response.status)
                  handleError({props: loginPageProps, message: IP_AUTH_ERROR_MESSAGE,
                      shouldRedirectToLoginPage, shouldShowErrorPopup, dispatch,
                      errorPopupAction: makeSetErrorModalAction({show_toast_modal: true, error_type: "IP"})},
                    makeShowManualIpAuthenticationFailed(true));
                    return Promise.resolve();
                }
            }).then(function(response_data) {
                    if(response_data !== undefined){
                        trackAccountEvents(response_data);
                        dispatch(makeSetIpLoginAuthenticationStateAction(response_data));
                        dispatch(makeSetAuthenticationStateAction(response_data));
                        const hash = localStorage.getItem(DESIRED_LOCATION_HASH)
                        if (hash) {
                          window.location.hash = hash;
                          localStorage.removeItem(DESIRED_LOCATION_HASH);
                        } else {
                          loginPageProps.history.push("/home");
                        }
                    }
                    return Promise.resolve();

        }).catch((err) => {
                console.error("Login failed.", err); // TODO: better error handling/logging
                handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE, shouldRedirectToLoginPage});
                return Promise.resolve();
            })
    }
}


export function getIPLoginClientConfigurationThunk(loginPageProps={}) {
    return (dispatch) => {
        userService.getClientConfiguration(loginPageProps).then(
            function (json) {
                dispatch(makeSetClientConfigurationAction(json));
        })
        // TODO: error handling?
    }
}

// Reducers
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */
export function updateSetAuthenticationStateReducer(current_state={}, action={}) {
    let new_state =  immutable_set(
        current_state,
        PATH_TO_MDX_AUTHENTICATION_JSON,
        action.authenticationJson
    )
    return new_state;
}

export function updateSetErrorMessageReducer(current_state={}, action={}) {
    let new_state =  immutable_set(
        current_state,
        'errorMessage',
        action.errorMessage
    )
    return new_state;
}

export function clearIpLoginPageStateReducer() {
    return DEFAULT_IP_LOGIN_PAGE_STATE;
}

// NOTE: reducers can be used to initialize state
export function ip_login_page(
    current_state=DEFAULT_IP_LOGIN_PAGE_STATE,
    action={}
) {
    switch (action.type) {
        case A.SET_AUTHENTICATION_STATE:
            return updateSetAuthenticationStateReducer(current_state, action)
        case A.SET_ERROR_MESSAGE:
            return updateSetErrorMessageReducer(current_state, action)
        case A.CLEAR_IP_AUTHENTICATION_STATE:
            return clearIpLoginPageStateReducer(current_state, action)
        default:
            return current_state
    }
}
