/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */


export const iv_compatibility_elements = {
    compatibility_elements: {
        "C":"Compatible",
        "V":"Caution:Variable",
        "U":"Uncertain",
        "I":"Incompatible",
        "N":"Not Tested"
    },
    compatibility_image_elements: {
        "C":"compatibility-Compatible",
        "V":"compatibility-Caution-Variable",
        "U":"compatibility-Uncertain",
        "I":"compatibility-Incompatible",
        "N":"compatibility-NotTested"
    }
}