/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

/* eslint no-fallthrough: 0 */  // --> OFF
/* eslint no-unused-vars: 0 */  // --> OFF    //TODO: remove this once development is complete!!!

import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import {
    get,
    isEmptyObject,
    force_array,
    deep_clone_the_object,
    immutable_set,
    areArraysEqual,
    isBackToTopScrollAvailable,
    getArray
} from "../../../utils";
import {
    makeIVDetailsGoToChatbotPageActionThunk,
    makeIVDetailsActionThunk
} from "./iv-details-utils";

import queryString from "query-string";


import CommonOverflowModalPopups from "../../../components/common-overflow-menu/CommonOverflowModalPopups";

import { Accordion, AccordionItem } from "@carbon/react";
import { makeChangeExpandedAccordionsAction } from "../../IVCompatibilityPages/iv-details-page/iv-details-utils";
import IVDetailsAccordionCollapser from "./iv-details-utils/ivDetailsAccordionCollapser";
import { iv_compatibility_elements } from "./../compatibility-elements";

import { IVFooter } from "../../../components/common/footers";
import { Link } from 'react-router-dom';
import { CheckboxFilterComponent } from "../../../components/checkbox-filter/CheckboxFilterComponent";
import {
    COMPATIBILITY_CODES,
    filter_groups,
    SINGLE_FILTER_COMPATIBILITY_FLAGS
} from "../iv-compatibility-summary-page/iv-summary-components/IVCompatibilityList";
import { IVCompatibilityInfoModal } from "../iv-compatibility-info-modal/IVCompatibilityInfoModal";
import { CommonFooter } from "../../../components/common/footers/Footer";
import {
  IV_COMPATIBILITY_DETAILS_PAGE_NAME
} from "../../../components/response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';
import { getPreviousPageLink } from "../../../utils";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { breakLongLinesForTooltip } from '../../../utils/tooltip-utils';
import { IV_COMPATIBILITY_ROOT_PATH } from '../../../utils/commonIvDiUtils';

const { ChevronLeft } = require('@carbon/react/icons');

const IV_DETAILS_DOCUMENT_IDS_PATH = 'documentIds';

const DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS = 20;

const mapStateToProps = state => {
    console.debug('mapStateToProps: state=',state)
    const state_results = {
        current_state: state.iv_details_page,
        expanded_accordions: get(state.iv_details_page, 'expanded_accordions', []),
        url_parameters: get(state.url_params, 'url_parameters', ''),
        current_document_id: get(state.drug_points_page, 'documentId'),
        topic_id: get(state.drug_points_page,'topicId'),
        current_drugEvals_id: get(state.in_depth_page,'documentId'),
        drug_name: get(state.drug_points_page,'drugName'),
        ivDetailsPageResponseLoading: get(state, `inline_loading.${IV_COMPATIBILITY_DETAILS_PAGE_NAME}`, {})
    }
    console.debug(state_results);
    return state_results
}
const mapDispatchToProps = dispatch => {
    return {
        onChangeExpandedAccordions: (expandedAccordionsState) => {
            dispatch(makeChangeExpandedAccordionsAction(
                expandedAccordionsState
            ))
        },
        onDisplayDetailsPageDetails: (initial_url_params={}, props) => {
            //how will document IDs be formatted in the url?
            // do same format as base mdx, number;number;number  , which encoded as url will be number%3Bnumber%3Bnumber%3B, ; = %3B https://www.w3schools.com/tags/ref_urlencode.ASP
            const newProductDocumentIds = force_array(get(initial_url_params, IV_DETAILS_DOCUMENT_IDS_PATH, []).split(","));
            const lastProductDocumentIds = get(props.current_state, IV_DETAILS_DOCUMENT_IDS_PATH, []);
            if (!areArraysEqual(newProductDocumentIds, lastProductDocumentIds)) {
              dispatch(makeIVDetailsActionThunk(newProductDocumentIds, props));
            }
        },
        onGoToChatbotPage: (props) => {
            console.debug('Inside onGoToChatbotPage!');
            dispatch(makeIVDetailsGoToChatbotPageActionThunk(props));
        },
        redux_dispatch_function: dispatch
    }
}
export class ivDetailsPresentation extends Component {
    initial_url_params = {}
    constructor(props) {
        super(props);
        console.debug('IVDetailsPagePresentation CTOR props=', props)
        const loc_search = get(this.props,'location.search')
        if (loc_search) { //what is this for?
            this.initial_url_params = queryString.parse(loc_search)
            console.debug('CTOR initial_url_params=', this.initial_url_params)
        }
        this.closeIVDetailsAndReturn=this.closeIVDetailsAndReturn.bind(this);
        this.getIsAccordionOpen=this.getIsAccordionOpen.bind(this);
        this.updateAccordionStatus=this.updateAccordionStatus.bind(this);
        this.updateIvDetailsFilterFlags = this.updateIvDetailsFilterFlags.bind(this);
        this.state = {
            iv_details_filter_flags: SINGLE_FILTER_COMPATIBILITY_FLAGS
        }
    }//end of constructor

    closeIVDetailsAndReturn() {
        return getPreviousPageLink(this.initial_url_params, IV_COMPATIBILITY_ROOT_PATH, this.props);
    }

    getIsAccordionOpen(drugItem) {
        const expandedAccordions = get(this.props, 'expanded_accordions', []);
        console.debug(expandedAccordions);
        return expandedAccordions.includes(drugItem);
    }

    updateIvDetailsFilterFlags(new_flags) {
        this.setState(immutable_set(this.state, 'iv_details_filter_flags', new_flags[0]));
    }

    componentDidMount() {  //***when is this used?
        // if initial_url_params is not empty, then we are responding
        // to a quick links url
        console.debug("iv details page mounted! ");
        if (!isEmptyObject(this.initial_url_params)) {
            const saved_url_params = this.initial_url_params
            console.debug(saved_url_params);
            this.props.onDisplayDetailsPageDetails(
                saved_url_params,
                this.props
            )
        }
    }

    updateAccordionStatus(accordionID) {
        console.debug("updating accordion status");
        console.debug(accordionID);
        let clonedExpandedAccordions = deep_clone_the_object(get(this.props, 'expanded_accordions', []));
        console.debug(clonedExpandedAccordions);
        if(clonedExpandedAccordions.includes(accordionID)) {
            //Remove it.
            clonedExpandedAccordions.splice(clonedExpandedAccordions.indexOf(accordionID), 1);
        } else {
            //Add it.
            clonedExpandedAccordions.push(accordionID);
        }
        console.debug(clonedExpandedAccordions);
        this.props.onChangeExpandedAccordions(clonedExpandedAccordions);

    }


    render() {
      let key_index = 1001
      const sectionName = 'ivDetailsPage';
      let iv_details_json = get(this.props.current_state, 'IV_DETAILS_RESPONSE');
      console.debug(iv_details_json);

      let accordion_list = [];
      let iv_details = get(iv_details_json, 'ivdocs', []);
      let primaryDrugName = '';
      let secondary_agent_name = '';
      let compatibility = get(this.initial_url_params, 'compatibility', '');
      let compatibility_icon = get(iv_compatibility_elements.compatibility_image_elements, compatibility, '');
      let numResults = iv_details.length;
      let render_results = <></>;
      let accordionFromName = get(this.initial_url_params, "accordionFrom", "");
      let fullSolutionName = get(this.initial_url_params, "fullSolutionName", "");
      const filter_flags = this.state.iv_details_filter_flags;
      const skip_compatibility_function = compatibility_code => {
        let code_index = COMPATIBILITY_CODES.indexOf(compatibility_code)
        return code_index < 0 || !filter_flags[code_index];
      }

      if (numResults === 0) {
        console.debug("returning empty div while page is initially loading");
        render_results = <></>;
      } else {
        let accordion_items = [];
        iv_details.map((singleDocument, index) => {
          console.debug(singleDocument);
          if (!isEmptyObject(singleDocument)) {
            let content_tree = get(get(singleDocument, 'document', {}), 'contentTree', {});
            primaryDrugName = get(get(content_tree, 'PrimaryAgent', {}), 'Name', '');
            let secondaryAgents = get(content_tree, "SecondaryAgents", {});
            let secondaryAgent = getArray(secondaryAgents, "SecondaryAgent");
            if (secondaryAgent.length === 0 || isEmptyObject(secondaryAgent)) {
                secondaryAgent = getArray(content_tree, 'Solution', {});
            }
            //if SecondaryAgent present ,then dipaly the secondary agents with '-' separtor
            //if not display solution name
            if (secondaryAgent.length > 0) {
                for (let i = 0; i < secondaryAgent.length; i++) {
                    secondary_agent_name = (secondaryAgent.length===1) ? get(secondaryAgent[i], "Name") : secondary_agent_name + get(secondaryAgent[i], "Name");
                    if (i < secondaryAgent.length - 1) {
                        secondary_agent_name = secondary_agent_name + " - ";
                    }
                }
            }
            else {
                if (fullSolutionName.length > 0) {
                    secondary_agent_name = fullSolutionName;
                }
            }

            let accordionID = `Study ${index + 1}`;
            let is_accordion_open = this.getIsAccordionOpen(accordionID);
            console.debug(is_accordion_open);
            accordion_items.push(processSingleDocument(
              singleDocument,
              index,
              is_accordion_open,
              accordionID,
              this.updateAccordionStatus,
              skip_compatibility_function,
              key_index++,
              accordionFromName
            ));
            accordion_list.push(accordionID);
            return accordion_items;
          } else {
            return accordion_items;
          }
        });
        render_results = accordion_items;
      }

      return <div key={key_index++} ref={this.scrollingDivRef} className="main-container drug-details-text">
        {/* Use same class as drug interaction details page for formatting consistency, defined in _drug-interaction-page.scss*/}
        <div className="empty-container"></div>
        <div className="main-content">
          <div className="mdx-scroll-area mdx-scroll-area-ie" onScroll={() => isBackToTopScrollAvailable()}>
            <div key='a' className="mdx-header">
              {/* Use same class as drug interaction details page for formatting consistency, defined in _drug-interaction-page.scss*/}
              <Link key='a.b' className="drug-interaction-modify-link" to={() => this.closeIVDetailsAndReturn()}
                    onClick={() => this.props.onChangeExpandedAccordions([])}>
                <ChevronLeft className="chevronleft16_fill-details"/>
                <span>Results summary</span>
              </Link>
              <h4 key='a.c' className="drug-interaction-title-1 search-and-results-page-titles">IV Compatibility Results Detail</h4>
            </div>
            {/* Use same class as drug interaction details page for formatting consistency, defined in _drug-interaction-page.scss*/}
            <div key='b' className="mdx-inner-content">
              <div key='b.a' id='detail-interaction'>
                <span className="iv-details-result">{accordionFromName}</span>
                <table className="iv-result_header_table">
                  <tbody>
                  <tr>
                    <td>
                      <p id={compatibility_icon} className="iv_results_header_icon_padding">
                        {!this.props.ivDetailsPageResponseLoading.status
                          ? `${secondary_agent_name} - ${primaryDrugName}`
                          : ' - '}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Results: {!this.props.ivDetailsPageResponseLoading.status ? numResults : 0}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div key='c' className="iv-compat-details-filter-container">
                <CheckboxFilterComponent
                  key='c.a'
                  checkbox_groups_list={filter_groups}
                  submit_filter_items={
                    new_flags => this.updateIvDetailsFilterFlags(new_flags)
                  }
                  InfoPanelComponent={IVCompatibilityInfoModal}
                  invokedSectionName={sectionName}
                />
              </div>
              <IVDetailsAccordionCollapser
                key='c.b'
                accordions={accordion_list}
              />
              {!this.props.ivDetailsPageResponseLoading.status
                ? <div key='c.c' id="ivDetailsPageContent">{render_results}</div>
                : <InlineLoading className={"iv-compatibility-details-loading"}
                                 description={this.props.ivDetailsPageResponseLoading.description}
                                 status={this.props.ivDetailsPageResponseLoading.status}/>}
              <IVFooter key='d' styleName='iv-compatibility-details-footer'/>
            </div>
            <CommonFooter key='e'/>
          </div>
        </div>
        <CommonOverflowModalPopups key='f'/>
      </div>
    }
}

export function  processSingleDocument(document, index,
                                       is_accordion_open,
                                       accordionID,
                                       updateAccordionStatus=f=>f,
                                       skip_compatibility_function,
                                       key_index = 1111,
                                       accordionFromName = ''
) {
    console.debug(document);
    let content_tree= get(get(document, 'document',{}),'contentTree',{});

    let primary_agent= get(content_tree,'PrimaryAgent',{});
    let primaryDrugName=get(primary_agent,'Name','');
    let primaryAmount = get(primary_agent,'Concentration.Value','') + get(primary_agent,'Concentration.Units','') ;
    primaryAmount = primaryAmount && get(primary_agent,'Vehicle','') ? primaryAmount + "`in` " + get(primary_agent,'Vehicle','') : primaryAmount;

    //From content_tree getting the secondary agents as array
    let secondaryAgents = get(content_tree, "SecondaryAgents", {});
    let secondaryAgent = getArray(secondaryAgents, "SecondaryAgent");
    let secondaryAgentSolution = getArray(secondaryAgents, "solution");

    let compatibility_code = get(content_tree,'CompatibilityCode','');
    let citation_data=get(content_tree,'Citation','');
    let drug= 'Drug ';
    let status = get(iv_compatibility_elements.compatibility_image_elements,compatibility_code,'');
    if (skip_compatibility_function(compatibility_code)) {
        console.debug("skipped Document due to filter settings")
        let emptyFragment = <div key={key_index}>
        </div>
        return emptyFragment;
    }
    let solution = 'Solution';
    var isSecondaryAgent = true;
    var isCompSolu = false;
    //if secondary agent is empty then display solution informaion
    if (secondaryAgent.length === 0 || isEmptyObject(secondaryAgent)) {
        isCompSolu = true;
        isSecondaryAgent = false;      
        secondaryAgent = getArray(content_tree, 'Solution', {});
    }
    let information_data=[];
    if((get(content_tree,'PhysicalCompatibility','')!=='')){
        information_data.push(<Fragment key={'a.'+key_index}>
            <b>Physical Compatibility:</b>
            <div dangerouslySetInnerHTML={{__html: get(content_tree,'PhysicalCompatibility','')}}></div>
        </Fragment>)
    }
    if (get(content_tree, 'ChemicalStability', '') !== '') {
        information_data.push(<Fragment key={'b.'+key_index}>
            <b>Chemical Stability:</b>
            <div dangerouslySetInnerHTML={{__html: get(content_tree, 'ChemicalStability', '')}}></div>
        </Fragment>)
    }
    information_data.push(<Fragment key={'c.'+key_index}>
            <b>Storage:</b>
            <div dangerouslySetInnerHTML={{__html: get(content_tree,'StorageConditions','')}}></div>
    </Fragment>)

    let test_parameters_data=[];//Test Parameters
    test_parameters_data.push(<Fragment key={'d.'+key_index}>
        <b>Container:</b>
        <div>{get(content_tree,'Container','')}</div>
       </Fragment>)

    if((get(content_tree,'StudyPeriod',''))!==''){
        test_parameters_data.push(<Fragment key={'e.'+key_index}>
            <b>Study Period:</b>
            <div>{get(content_tree,'StudyPeriod','')}</div>
        </Fragment>)
    }
    if((get(content_tree,'Methods',''))!==''){
        test_parameters_data.push(<Fragment key={'f.'+key_index}>
            <b>Method:</b>
            <div>{get(content_tree,'Methods','')}</div>
        </Fragment>)
    }

    test_parameters_data.push(<Fragment key={'g.'+key_index}>
        <span className="referenceText">Reference: </span><pre class= "prespace">
        <a href data-tooltip-float data-tooltip-id='tooltip' data-tooltip-html={breakLongLinesForTooltip(build_reference_data(citation_data), DESIRED_TOOLTIP_LINE_LENGTH_IN_CHARACTERS)}>{get(citation_data,'Code','')}</a>
        <ReactTooltip id="tooltip" place="bottom"/></pre>
    </Fragment>)
    let notes = [];
    if((get(content_tree,'Notations',''))!==''){
        let notationText = (String(get(content_tree,'Notations','')));
        notationText = notationText.replace(/\n/g, '<br>');
        notes.push(<tr>
            <td className="iv-details-column1">Notes: </td>
            <pre style={{ font: 'inherit', display: 'inline', whiteSpace: 'pre-wrap', margin: 0 }}>
                <td className="iv-details-column2 RteContentStyling"><div dangerouslySetInnerHTML={{__html: notationText}}></div></td>
            </pre>
            
        </tr>)
    }

    let accordion_item=<Accordion key={key_index} className="mdx-accordion">
        <AccordionItem
        open={is_accordion_open}
        className={`${status} iv-details-accordion-item`}
        title={accordionID}
        onHeadingClick={()=>updateAccordionStatus(accordionID)}
    >
        <table className="iv-details-summary-table">
            <tbody>
            <tr>
                <td className="iv-details-column1">Drug 1:</td>
                <td className="iv-details-column2">
                    <div><b>{primaryDrugName}</b></div>
					<div>{primaryAmount}</div>
                    <div>{get(primary_agent,'Manufacturer','')}</div>
                </td>
            </tr>
                {
                    secondaryAgent.map((agent, index) => (isSecondaryAgent ? (
                        //dipslay more than 2 drug secondary agents information
                        //Added condition if both Concentration and Vehicle details are present,then prints the result in the format of using 'in' otherwise print empty
                        <tr>
                            <td className="iv-details-column1">{drug} {index + 2}:</td>
                            <td className="iv-details-column2">
                                <div><b>{get(agent, 'Name', '')}</b></div>
                                <div>{get(agent,'Concentration') && get(agent,'Vehicle','') ? " " + get(agent,'Concentration.Value','') 
                                + get(agent,'Concentration.Units','') + "`in` "
                                + get(agent,'Vehicle','') : " " + get(agent,'Concentration.Value','') 
                                + get(agent,'Concentration.Units','')}</div>
                                <div>{get(agent, 'Manufacturer', '')}</div>
                            </td>
                        </tr>
                    ) :
                    //displays the solution information when secondary agent is empty
                        <tr>
                            <td className="iv-details-column1">{solution} {index + 1}:</td>
                            <td className="iv-details-column2">
                                <div><b>{get(agent, 'Name', '')}</b></div>
                                <div>{get(agent, 'Manufacturer', '')}</div>
                            </td>
                        </tr>))
                }
                {secondaryAgentSolution.map((agent, index) => (isCompSolu ? 
                <tr>
                            <td className="iv-details-column1">{solution} {index + 2}:</td>
                            <td className="iv-details-column2">
                                <div><b>{get(agent, 'Name', '')}</b></div>
                                <div>{get(agent, 'Manufacturer', '')}</div>
                            </td>
                        </tr>:<tr>
                            <td className="iv-details-column1">{solution} {index + 1}:</td>
                            <td className="iv-details-column2">
                                <div><b>{get(agent, 'Name', '')}</b></div>
                                <div>{get(agent, 'Manufacturer', '')}</div>
                            </td>
                        </tr>))}
            <tr>
                <td className="iv-details-column1">Status: </td>
                <td className="iv-details-column2" id={status}>&nbsp;</td>
            </tr>
            <tr>
                <td className="iv-details-column1">Information: </td>
                <td className="iv-details-column2">{information_data}</td>
            </tr>
            <tr>
                <td className="iv-details-column1">Test Parameters: </td>
                <td className="iv-details-column2">{test_parameters_data}</td>
            </tr>
            {notes}
            </tbody>
        </table>
    </AccordionItem>
    </Accordion>
    return accordion_item;
}

export function build_reference_data(citation_data){
    let reference_data = 'Reference: ';
    let code = "[" + get(citation_data, 'Code', '') + "] ";
    reference_data += code;
    if(get(citation_data, 'Author', '') !== '')
        reference_data += get(citation_data, 'Author', '') + ': ';
    if(get(citation_data, 'Title', '') !== '')
        reference_data += get(citation_data, 'Title', '') + ' ';
    if(get(citation_data, 'Journal', '') !== '')
        reference_data += get(citation_data, 'Journal', '') + ' ';
    if(get(citation_data, 'Year', '') !== '')
        reference_data += get(citation_data, 'Year', '') + ' ';
    if(get(citation_data, 'Volume', '') !== '')
        reference_data += get(citation_data, 'Volume', '') + ' ';
    if(get(citation_data, 'Pages', '') !== '')
        reference_data += get(citation_data, 'Pages', '');
    return reference_data
}


const IVDetailsPage = connect(
    mapStateToProps,
    mapDispatchToProps
) (ivDetailsPresentation);

export default IVDetailsPage;
