/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {fixup_server_url} from "./locate-mdx-proxy-url";
import {get, getParentUrl, util_alert} from "../utils";
import queryString from 'query-string'
import {
  handleError
} from "../utils/internal-error-handler";
import {
  defaultFilters, defaultIVSingleFilters,
  LOCAL_STORAGE_FILTER_PANEL_STATE_KEY,
  updateLocalStorage
} from "../components/checkbox-filter/checkbox-filters-state";
import {GENERIC_ERROR_MESSAGE, OTHER_ERROR_TYPE} from "../components/common/constants";

const TOKEN_PATH_PREFIXES = ['jws', 'ibte', 'ibti'];

export const PERSIST_CONFIG_PREFIX = 'persist';
export const PERSIST_CONFIG_KEY = 'root:pwa';

export const userService = {
    ipLogin,
    login,
    isLoggedIn,
    getClientConfiguration,
    logout,
    iFrameLogin
}

const state = {
    isLoggedIn: false
}

function login(username, password, loginPageProps, currentState) {
    performLogoutRequestIfNeeded(loginPageProps, currentState);
    const proxy_url = fixup_server_url('/api/authentication')

    return fetch(proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password,
            })
        }
    ).then(function(response) {
        return response;
    }, function(error) {
        handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE});
        util_alert("Login Request Failed to send.");
        console.error("I got a login error! "+error.message);
    })
}

/**
*   iFrameLogin method to authenticate the user based on Json Token or IP address
*
**/
function iFrameLogin(gatewayPageProps, currentState) {
    performLogoutRequestIfNeeded(gatewayPageProps, currentState);
    const proxy_url = fixup_server_url('/api/iFrame/authenticate')
    console.debug("gatewayPageProps");
    let loc_search = gatewayPageProps.location
      ? gatewayPageProps.location.search
      : gatewayPageProps.history.location.search;
    console.debug(loc_search);

    let token = '';
    let tokenType = '';
    let httpClientIp = null;
    if (loc_search) {
      let initial_url_params = queryString.parse(loc_search)
      console.debug(initial_url_params);
      httpClientIp = get(initial_url_params, 'HTTP_CLIENT_IP');
      TOKEN_PATH_PREFIXES.some(pathString => {
        token = get(initial_url_params, pathString, '');
        if (token === '') {
            return false;
        } else {
            tokenType = pathString;
            return true;
        }
      })
    }

    let headers = {
        'Accept': 'application/json',
            'Content-Type': 'application/json',
            'jwtToken': token,
            'tokenType' : tokenType,
            ...getParentUrl()
    }

    // Allows testing of IP Authentication in IFrame using any specified IP address
    if (httpClientIp) {
        headers['HTTP_CLIENT_IP'] = httpClientIp;
    }

    console.debug('iFrameLogin: headers=', headers);

    return fetch(proxy_url,
        {
            headers: headers,
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({
                //header : headers
            })
        }
    ).then(function(response) {
        return response;
    }, function(error) {
      console.error("I got a Token Login error! "+error.message);
      return error;
    })
}

function getClientConfiguration(loginPageProps) {
    const proxy_url = fixup_server_url('/api/configuration')
    return fetch(proxy_url,
        {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json'
            }
        }
    ).then(function(response) {
        if(response.status === 200) {
            return response.json();
        } else if(response.status === 403){
            console.error("Could not retrieve client config! response.status==403");
            util_alert("Client configuration request failed to send.",OTHER_ERROR_TYPE);
            handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE});
        } else {
            console.error("UNHANDLED exception trying to retrieve client config. response.status==",response.status);
            util_alert("Client configuration request failed to send.",OTHER_ERROR_TYPE);
            handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE});
        }
    }, function(error) {
        handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE});
        util_alert("Client configuration request failed to send.", OTHER_ERROR_TYPE);
        console.error("Error attempting to send client configuration request: "+error.message);
    })
}

function ipLogin(loginPageProps, currentState) {
    performLogoutRequestIfNeeded(loginPageProps, currentState);
    const method = 'ipLogin'
    console.debug(method,": ENTER")
    const proxy_url = fixup_server_url('/api/')
    return fetch(proxy_url,
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then(function(response) {
        return response;
    }, function(error) {
        handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE});
        util_alert("An error occurred while attempting IP Login. Please try again.");
        console.error(method,": I got an IP Login error! "+error.message);
    })
}
function logout(props, uuid) {
    console.debug("inside logout...")
    const proxy_url = fixup_server_url('/api/logout')
    return fetch(proxy_url,
        {
            method: 'POST',
            headers: {
              authCookie: uuid
            }
        }
        ).then(function(response){
        localStorage.removeItem(PERSIST_CONFIG_PREFIX + ":" + PERSIST_CONFIG_KEY);
        localStorage.removeItem(LOCAL_STORAGE_FILTER_PANEL_STATE_KEY);
        updateLocalStorage(defaultFilters);
        updateLocalStorage(defaultIVSingleFilters);
        return response;
    }, function(error){
        console.error("I got a logout error: "+error.message);
        handleError({props, message: GENERIC_ERROR_MESSAGE});
        return error;
    })
}

const performLogoutRequestIfNeeded = (props, currentState) => {
    const uuid = get(currentState, 'login_page.mdxAuthenticationJson.Set-Cookie', '');

    if (uuid) {
      logout(props, uuid);
    }
}

function isLoggedIn() {
    console.debug("inside isLoggedIn() isLoggedIn=", state.isLoggedIn)
    return state.isLoggedIn;
}