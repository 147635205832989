/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {
    useState,
    useMemo,
    useEffect
} from "react"
import {
    initializeFlags,
    countFlags,
    countFlagsArray,
    toBooleanArray
} from "./flag-set-utils";
import {isFilterPanelOpened, isSectionInSummaryPage} from "../../utils/commonIvDiUtils";
import {
    getFiltersState,
    updateFiltersState
} from "./checkbox-filters-state";

export const computeAllFlagsEnabled = checkbox_group => checkbox_group.map(
    ({checkbox_items=[]}) => initializeFlags(checkbox_items.length)
)

/**
 * Custom React Hook that processes a Checkbox Panel's state changes and derives
 * the visibility and enablement of filter panel widgets
 *
 * @param checkbox_groups and array of checkbox filter groups, where each group is an object like
 * <pre>
 *     {
        group_title: 'Drugs',
        summary_title: 'All Drugs',
        min_checked: 2           // OPTIONAL, defaults to zero
        checkbox_items: [
            'Aspirin',     // checkbox 0
            'Amoxicillin', // checkbox 1
            'Orange Juice', // checkbox 2
            'Wafarin'  // checkbox 3
        ]
    }
 *     </pre>
 * @param submitFilteredItems callback that handles committed filter changes;
 * the input param for this callback is an array of checkbox_items, where only the
 * checked items are included.
 * @returns {{handleResetButton:
 *    handleResetButton: function,
 *    applyButtonIsEnabled: boolean,
 *    transientGroupState: integer[],
 *    isPillDisplayed: boolean,
 *    resetFilterPill: function,
 *    toggleInfoPanelOpen: function,
 *    pillCount: integer,
 *    isInfoPanelOpen: boolean,
 *    toggleFilterPanelOpen: function,
 *    updateCheckboxGroupState: f=>f=>f,
 *    resetButtonIsEnabled: boolean,
 *    handleApplyButton: function,
 *    isFilterPanelOpen: boolean,
 *    currentGroupState: integer[]}}
 */
export const useCheckboxPanelState = (
    checkbox_groups=[],  // definitions of panel's checkbox filter groups
    submitFilteredItems = f=>f,
    invokedSectionName = '',// page callback than handles page updates that occur when Apply button is clicked
    panel_button_click_handler = f=>f,
    panel_state = [],
    initialCheckboxGroupState = null, // optional; array of interger checkbox group states
    externalDisableFlag = false,// optional, disables filter panel icons during a page refresh // TODO: TBD

) => {

    const [local_checkbox_groups, set_local_checkbox_groups] = useState(checkbox_groups)

    // integer array with all checkboxes in checked state
    const all_flags_enabled = useMemo(
        () => computeAllFlagsEnabled(local_checkbox_groups),
        [local_checkbox_groups]
    )

    // total number of child checkboxes on panel
    const total_checkbox_count = useMemo(
        () => countFlagsArray(all_flags_enabled),
        [all_flags_enabled]
    )

    // integer array of minimum checked value for each checkbox filter group
    const min_checked_counts = useMemo(
        () => local_checkbox_groups.map(
            ({min_checked=0}) => min_checked
        ),
        [local_checkbox_groups]
    )

    // compute the initial checked settings of each checkbox group
    let actual_initial_group_state = initialCheckboxGroupState
    if (actual_initial_group_state===null) {
        actual_initial_group_state = all_flags_enabled
    }

    // see useState() docs: https://reactjs.org/docs/hooks-reference.html#usestate
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false) // boolean
    const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(false)     // boolean
    // snapshot of checkbox group states when filter panel is opened or closed
    const [currentGroupState, setCurrentGroupState] = useState([...actual_initial_group_state]) // integer array
    // dynbamic checkbox group states when checkbox filter panel is open
    const [transientGroupState, setTransientGroupState] = useState([...actual_initial_group_state]) // integer array

    // keep track of displayed pill count
    const [pillCount, setPillCount] = useState(countFlagsArray(actual_initial_group_state))

    // detect changes in the identity of the checkbox_groups parameter and reset the filter state if it changes
    useEffect(
        () => {
            set_local_checkbox_groups(checkbox_groups)
            setIsFilterPanelOpen(false)
            setIsInfoPanelOpen(false)
            // TODO: maybe diff changes between old and new checkbox_groups items ... TBD
            // ... for now, just set all checkboxes in checked state ...
            const current_flags = getFiltersState(invokedSectionName, checkbox_groups)
            setCurrentGroupState([...current_flags])
            setTransientGroupState([...current_flags])
            setPillCount(countFlagsArray(current_flags))
            if (current_flags.length === 1 && current_flags[0] !== 0) {
                handleApplyButton(current_flags)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [checkbox_groups]
    )

    /**
     * Update transientGroupState whenever any checkbox is toggled
     * @param group_index index of checkbox group
     * @returns {function(*=): void}
     */
    const updateCheckboxGroupState = group_index => new_flags => {
        let new_group_state = [...transientGroupState] // DO NOT MUTATE transientGroupState
        new_group_state[group_index] = new_flags
        setTransientGroupState(new_group_state)
    }

    // display the pill if any checkbox is unchecked
    const isPillDisplayed = pillCount!==total_checkbox_count

    /**
     * function to open the checkbox filter panel
     */
    const openFilterPanel = () => {
        setIsFilterPanelOpen(true);

        setIsInfoPanelOpen(false) // close the info panel

        setTransientGroupState([...currentGroupState]) // initialize the transient checkbox group states
    }

    /**
     * function to close the filter panel
     */
    const closeFilterPanel = () => {
        if (!isFilterPanelOpen) {
            return
        }
        setIsFilterPanelOpen(false) // close filter panel

        // reset transient flags to what they were when panel first opened
        setTransientGroupState([...currentGroupState])
        panel_button_click_handler({sectionName: invokedSectionName, isPanelDisplayed: false})
    }

    /**
     * onclick handler that toggles filter panel between opened and closed state
     */
    const toggleFilterPanelOpen = () => {
        if (!isSectionInSummaryPage(invokedSectionName)) {
          if (isFilterPanelOpen) {
            closeFilterPanel();
          } else {
            openFilterPanel();
          }
        } else {
          if (isFilterPanelOpened(panel_state, invokedSectionName)) {
            closeFilterPanel();
          } else {
            panel_button_click_handler({sectionName: invokedSectionName, isPanelDisplayed: true})
            openFilterPanel();
          }
        }
    }

    /**
     * Open the info panel
     */
    const openInfoPanel = () => {
        if (isInfoPanelOpen) {
            // nothing to do if info panel already opened
            return
        }
        if (isFilterPanelOpen) {
            closeFilterPanel()
            panel_button_click_handler({sectionName: invokedSectionName, isPanelDisplayed: false})
        }

        setIsInfoPanelOpen(true) // open the info panel
    }

    /**
     * Close the info panel
     */
    const closeInfoPanel = () => {
        setIsInfoPanelOpen(false)
    }

    /**
     * onclick handler that toggles the filter panel between opened and closed
     */
    const toggleInfoPanelOpen = () => {
        if (isInfoPanelOpen) {
            closeInfoPanel()
        } else {
            openInfoPanel()
        }
    }

    // the apply button is enabled if the filter panel is open and
    // any transient checkbox group state is different from its value when the filter panel was opened;
    // but disable the apply button if any checkbox group has an error due to not enough required
    // enabled checkboxes have been checked
    const applyButtonIsEnabled = transientGroupState.some(
        (transient_flags, index) => {
            return(
                transient_flags !== currentGroupState[index]
                &&
                countFlags(transient_flags)  >= min_checked_counts[index]
                &&
                    // IV COMPAT FILTER specific: don't enable Apply button if no drugs checked!!!!
                    // TODO: make this configurable!!
                transientGroupState[0] !== 0
            )
        }
    )

    /**
     * The reset button is enabled if there is any checkbox group has an unchecked checkbox
     * @type {boolean}
     */
    const resetButtonIsEnabled = transientGroupState.some(
        (transient_flags, index) => transient_flags !== all_flags_enabled[index]
    )

    /**
     * onclick handler for Apply button
     * @param param_transient_group_state optional; array of integers; the filter states to be submitted
     * to the page; defaults to transientGroupState
     */
    const handleApplyButton = (param_transient_group_state) => {
        // default param_transient_group_state to transientGroupState
        let temp_transient_group_state = param_transient_group_state
        if (temp_transient_group_state===undefined) {
            temp_transient_group_state = transientGroupState
        }

        setIsFilterPanelOpen(false) // close the filter panel
        closeFilterPanel();
        panel_button_click_handler({sectionName: invokedSectionName, isPanelDisplayed: false});

        // set the curent and transient checkbox group states to applied states ...
        setCurrentGroupState([...temp_transient_group_state])   // DO NOT MUTATE currentGroupState
        setTransientGroupState([...temp_transient_group_state]) // !!!! DO NOT MUTATE transientGroupState
        setPillCount(countFlagsArray(temp_transient_group_state))

        // TODO: determine exactly what needs to be submitted to parent page ...
        // for now return a filtered list of checkbox labels for each checkbox group in the panel
        const filtered_items = checkbox_groups.map(
            ({checkbox_items=[]}, group_index) => {
                const transient_flags = temp_transient_group_state[group_index]
                return toBooleanArray(transient_flags, checkbox_items.length)
            }
        )

        // call back to parent page to change the filtered view details
        submitFilteredItems(filtered_items)
        updateFiltersState(invokedSectionName, temp_transient_group_state)
    }

    /**
     * onclick handler for Reset button
     */
    const handleResetButton = () => {
        setTransientGroupState([...all_flags_enabled]) // easy! check all checkboxes in each checkbox group
    }

    /**
     * onclick handler for the filter pill
     */
    const resetFilterPill = () => {

        setIsInfoPanelOpen(false) // close filter panel if it is open

        // !!!! struggled to get this one working !!!!
        // !!!! this did not work here !!! : setTransientGroupState([...all_flags_enabled])
        // ... and this did NOT work!!! : transientGroupState = [...all_flags_enabled]

        // programmatically click the Apply button; but override transientGroupState with all checkboxes checked
        handleApplyButton(all_flags_enabled)
    }

    return {
        // filter pill ...
        isPillDisplayed,  // boolean
        pillCount,        // integer
        resetFilterPill,  // filter pill onclick handler

        // filter panel ...
        isFilterPanelOpen,   // boolean
        toggleFilterPanelOpen,  // filter icon onclick handler
        updateCheckboxGroupState, // custom hook callback that tracks state of each child checkbox group

        // info panel ...
        isInfoPanelOpen,   // boolean
        toggleInfoPanelOpen, // info icon onclick handler

        // reset button
        resetButtonIsEnabled, // boolean
        handleResetButton,    // reset button onclick handler

        // apply button
        applyButtonIsEnabled, // boolean
        handleApplyButton,    // apply button onclick handler

        // when filter panel is open ...
        currentGroupState,   // integer array; snapshot of checkbox group states when panel first opened
        transientGroupState,  // integer array; dynamic state of checkbox groups when panel is opened
        closeFilterPanel
    }
}
