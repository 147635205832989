/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment} from 'react'
import {Button } from "@carbon/react"

import {CheckboxFilterGroup} from './CheckboxFilterGroup'
import {useCheckboxPanelState} from "./CheckboxFilterPanelHook";
import {getArray} from "../../utils";
import {isFilterPanelOpened} from "../../utils/commonIvDiUtils";

/**
 * A stateless presentation component that renders a checkbox filter panel
 * that contains one or more checkbox filter groups of checkboxes
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxFilterPanelPure = props => {
    // destructure props
    const {
        checkbox_groups_list = [], // list of checkbox filter group definitions
        transientGroupState = [], // array of integers; each integer represents one checkbox group state

        // callback that allows a checkbox group to update panel's transientGroupState array
        // usage: updateCheckboxGroupState(checkbox_group_index)(updated_state_flags)
        updateCheckboxGroupState = f=>f=>f,

        resetButtonIsEnabled, // boolean
        handleResetButton,    // reset button onclick handler

        applyButtonIsEnabled, // boolean
        handleApplyButton,    // apply button onclick handler
        invokedSectionName,
        panel_state
    } = props

    let isPanelOpened = isFilterPanelOpened(panel_state, invokedSectionName);

    // render the checkbox filter panel ...
    return isPanelOpened && <Fragment>
        <div className="checkbox-filter-popup-panel">
            {
                checkbox_groups_list.map(
                    (checkbox_group_state, group_index) => {
                        let checkbox_group_display

                        if (getArray(checkbox_group_state,'checkbox_items').length===0) {
                            // if there are no children checkboxes, then don't render this checkbox filter group
                            checkbox_group_display = <Fragment key={`empty-${group_index}`}/>
                        } else {
                            const group_enable_flags = transientGroupState[group_index]

                            // curry the updateCheckboxGroupState function by locking in the group index
                            const flags_update_function = updateCheckboxGroupState(group_index)

                            checkbox_group_display = <CheckboxFilterGroup
                                checkbox_group_index={group_index}
                                key={`chkgrp-${group_index}`}
                                checkbox_group_state={checkbox_group_state}
                                update_checkbox_panel_fn={flags_update_function}
                                initial_checkbox_flags={group_enable_flags}
                           />
                        } //endif
                        return checkbox_group_display
                    }) //endarrow endmap
            }
            <div className="checkbox-filter-button-block">
                <Button className='secondary-button iv-compat-filter-reset-button'
                        kind="tertiary"
                        disabled={!resetButtonIsEnabled}
                        onClick={() => handleResetButton()}>Reset Filters</Button>
                <Button className='primary-button checkbox-filter-apply-button'
                        kind="primary"
                        disabled={!applyButtonIsEnabled}
                        onClick={() => handleApplyButton()}>Apply</Button>
            </div>
        </div>
    </Fragment>
}

/**
 * Stateful checkbox filter panel;
 * <br/>
 * NOTE: CheckboxFilterPanel is only used in tests and Storybook stories
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxFilterPanel = props => {
    // destructure props
    const {
        checkbox_groups_list = [],  // checkbox group definitions
        submit_filter_items = f=>f  // page filtering callback called when apply button is clicked
    } = props

    const filter_panel_state = useCheckboxPanelState(
        checkbox_groups_list, // checkbox_groups
        submit_filter_items // submitFilteredItems
    )

    return <CheckboxFilterPanelPure
        {...filter_panel_state}
        checkbox_groups_list={checkbox_groups_list}
    />
}
