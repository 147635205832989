export function breakLongLinesForTooltip(input, desiredLineLengthInCharacters) {
    let currentCharacterIndex = desiredLineLengthInCharacters;
    let previousLineEndChacacterIndex = 0;
    let output = [];
    while (input[currentCharacterIndex]) {
        if (input[currentCharacterIndex++] === ' ') {
            output.push(input.substring(previousLineEndChacacterIndex, currentCharacterIndex));
            previousLineEndChacacterIndex = currentCharacterIndex;
            currentCharacterIndex += desiredLineLengthInCharacters;
        }
    }
    output.push(input.substr(previousLineEndChacacterIndex));
    var filtered = output.filter(elm => elm !== ' ');
    let transformedString = '';
    for(let i = 0; i < filtered.length; i++) {
        transformedString = transformedString + filtered[i] + (i !== filtered.length -1? "</br>" : "");
    }
    return transformedString;
}