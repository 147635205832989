/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import React from 'react'
import {Modal} from "@carbon/react"

const compatibility_codes = [
    ['C', 'Compatible', 'images/iv_compat_compatible.png', 'IV compatibility is compatible.'],
    ['V', 'Caution:Variable', 'images/iv_compat_cautionvariable.png', 'Caution: IV compatibility is variable.'],
    ['U', 'Uncertain', 'images/iv_compat_uncertain.png', 'IV compatibility is uncertain.'],
    ['I', 'Incompatible', 'images/iv_compat_incompatible.png', 'IV compatibility is incompatible.'],
    ['N', 'Not Tested', 'images/iv_compat_nottested.png', 'IV compatibility is not tested.']
]

const ModalProps = {
    className: 'iv-compat-filter-info-modal',
    open: true,
    passiveModal: true,
    danger: false,
    shouldSubmitOnEnter: false,
    modalHeading: 'Compatibility Key',
    modalLabel: '',
    modalAriaLabel: 'Reference information for IV Compatibility Symbols',
    iconDescription: 'Close Reference information',
    'aria-label': 'Reference information for IV Compatibility Symbols'
}

export const IVCompatibilityInfoModal = ({onCloseHandler = f => f}) => {
    return <Modal
        {...ModalProps}
        onRequestClose={(event) => onCloseHandler(event)}
    >
        {
            compatibility_codes.map(([compat_code, compat_label, compat_image, compat_description], index) => {
                return <div key={index} className="iv-compat-filter-info-group">
                    <h4>
                        <img className='iv-compat-filter-info-image' src={compat_image} alt={compat_label}/>
                        <span className='iv-compat-filter-info-label'>{compat_label}</span>
                    </h4>
                    <p className='iv-compat-filter-info-description'>{compat_description}</p>
                </div>
            })
        }
    </Modal>
}
