/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Fragment } from "react";
import { Link } from 'react-router-dom'
import { get,getArray } from "../../../utils";
import $ from 'jquery';
import { actionType } from 'redux-postmessage-middleware';
import {indepth_vendor_section_mapping} from './vendorSectionMapping';
import {resetFiltersState} from "../../../components/checkbox-filter/checkbox-filters-state";
// import { IS_RUNNING_IN_IFRAME } from "../../../app-state-redux"
import { InlineLoading } from '@carbon/react';

// TODO: Turn RenderTurn into a real React Component that implements React.PureComponent

// GLOBAL counter used to provide unique key attributes to each rendered turn
let global_key_counter = 0
function nextKey() {
    return 'turn-key-'+(++global_key_counter)
}

// ************************************************************************* NEW FUNCTIONS ***********************

export function getMappingSectionKey(sectionKey,contentSetId){
    if (contentSetId==='31') {
        sectionKey = get(indepth_vendor_section_mapping, sectionKey, sectionKey);
    }
    else if(contentSetId==='100'){
        if(sectionKey==='contraindications') {
            sectionKey = 'contraindicationsSection'
        }else if(sectionKey==='pediatricDosageSection'){
            sectionKey = 'pediatricDosingSection'
        } else if(sectionKey === 'precautions' ){
            sectionKey = 'precautionsSection'
        } else if(sectionKey === 'adverseReactionsSection'){
            sectionKey = 'adverseEffectsSection'
        } else if(sectionKey === 'fdaUsesSection') {
            sectionKey = 'raSection'
        }
    }
    return sectionKey;
}
export function isInDepthExternalNavigation(gateway_login_page) {
    console.debug(gateway_login_page);
    const gateway_login=get(gateway_login_page, 'mdxAuthenticationJson.authorization');
    if(gateway_login === 'success'){
        const external_links= getArray(gateway_login_page,'mdxAuthenticationJson.Client_Config.externallinks');
        console.debug(external_links);
        return external_links.filter(link => link.inDepthLinks)[0];
    }
    return false;
}

export function isDrugPointsExternalNavigation(gateway_login_page) {
    console.debug(gateway_login_page);
    const gateway_login=get(gateway_login_page, 'mdxAuthenticationJson.authorization');
    if(gateway_login === 'success'){
        const external_links= getArray(gateway_login_page,'mdxAuthenticationJson.Client_Config.externallinks');
        return external_links.filter(link => link.quickLinks)[0];
    }
    return false;
}
export function composeExternalNavigationMessage(
    {
        contentSetId,
        drugName,
        quickLinkDocumentId='',
        inDepthLinkDocumentId='',
        topicList
    }
) {
    let sectionKey = topicList
    sectionKey=getMappingSectionKey(sectionKey, contentSetId);

    let contentSetName = 'UNKNOWN'
    let navType = 'UNKNOWN'
    switch (contentSetId) {
        case '100':
            contentSetName = 'DRUGPOINTS'
            navType = 'quickAnswers'
            break
        case '31':
            contentSetName = 'DRUGDEXEVALS'
            navType = 'indepthAnswers'
            break
        case '39':
            contentSetName = 'IVCOMPATIBILITY'
            navType = 'ivCompatibility'
            break
        case '107':
            contentSetName = 'DRUGINTERACTIONS'
            navType = 'drugInteractions'
            break
        default:
            break
    }
    return {
        name: 'MDXWatsonExternalNavigate',
        type: actionType('EXTERNAL'),
        title: drugName,
        quickAnswersDocId: quickLinkDocumentId,
        indepthAnswersDocId: inDepthLinkDocumentId,
        contentSetName: contentSetName,
        sectionKey: sectionKey,
        navType: navType
    }
}

/**
 * Compose the HREF link URL for a quick link object
 * @param contentSetId
 * @param drugName
 * @param documentId
 * @param topicList
 * @param displayText
 * @param index
 * @returns {*}
 */
function composeQuickLink(
    {
        linkType,
        contentSetId,
        drugName,
        documentIds=[],
        topicList,
        displayText,
        overrideURL,
        drugIds=[],
        isFake=false,
        shouldSkipInDepthCall=false
    },
    index=1,
    subscriptionInfo={},
    clientConfig={},
    credential={},
    onInDepthLinkForm = null,
    quickLinkDocumentId = '',
    onQuickAnsLinkForm = null,
    inDepthLinkDocumentId = '',
    onLaunchExternalNavigation = f=>f,
    gateway_login_page={},
    onDisplayDrugInteractionsWithDefaultState = f => f,
    props,
    onClearSearchTerm =f=>f
) {
    let linkUrl = "";
    let linkURL_encoded = '';
    console.debug(drugIds);
    if(overrideURL !== null && overrideURL !== undefined) {
        linkUrl = overrideURL;
        let target="_blank";
        linkURL_encoded = encodeURI(linkUrl)
        return (
            <Fragment key={'frag-'+index}>
                <li><a  target={target} className="mdx-link" href={linkURL_encoded}>{displayText}</a></li>
            </Fragment>
        )
    } else {
        switch(contentSetId){
            case '107':
                const hasDrugInteractions = get(subscriptionInfo, 'drugInteractions', 'no');
                if (hasDrugInteractions!=='yes') {
                    const target = "_blank";
                    displayText = 'Subscribe for access to Drug Interactions';
                    const localLinkUrl = clientConfig.subscriptionSupportURL;
                    linkURL_encoded = encodeURI(localLinkUrl);
                    return <Fragment key={'frag-' + index}>
                        <li><a target={target} className="mdx-link" href={linkURL_encoded}>{displayText}</a></li>
                    </Fragment>
                } //endif hasDrugInteractions!=='yes'
                else{
                    //Drug interactions
                    //let drugList = documentIds;
                    var s="";
                    for (var i=0;i< drugIds.length;i++)
                    {
                        s+="&drug_ids="+drugIds[i].id;
                        s+="&drug_names="+encodeURIComponent(drugIds[i].name);
                    }

                    linkUrl = `/drugInteraction?${s}`;
                    linkURL_encoded = encodeURI(linkUrl)
                    return (
                        <Fragment key={'frag-' + index}>
                            <li>
                              <Link key={'a-' + index}
                                    className="mdx-link"
                                    to={linkURL_encoded}
                                    onClick={() => onDisplayDrugInteractionsWithDefaultState(props, drugIds)}>
                                {displayText}
                              </Link>
                            </li>
                        </Fragment>
                    )
                }
            case '39':
                console.debug('inside iv...')
                const hasIVCompatibility = get(subscriptionInfo, 'IVCompatibilityYSiteAdmixtureSyringe', 'no');
                if (hasIVCompatibility !== 'yes') {
                    const target = "_blank";
                    displayText = 'Subscribe for access to IV Compatibility';
                    const localLinkUrl = clientConfig.subscriptionSupportURL;
                    linkURL_encoded = encodeURI(localLinkUrl);
                    return <Fragment key={'frag-' + index}>
                        <li><a target={target} className="mdx-link" href={linkURL_encoded}>{displayText}</a></li>
                    </Fragment>
                } //endif hasDrugInteractions!=='yes'
                else{
                    //IV Compatibility
                    console.debug(drugIds);
                    var drs = "";
                    for (var j = 0; j < drugIds.length; j++) {
                        console.debug('loop works');
                        drs += "&drug_ids=" + drugIds[j].id;
                        drs += "&iv_drug_names=" + encodeURIComponent(drugIds[j].name);
                    }
                    linkUrl = `/ivCompatibility?${drs}`;
                    linkURL_encoded = encodeURI(linkUrl)
                    console.debug(linkUrl);
                    return (
                        <Fragment key={'frag-' + index}>
                            <li><Link key={'a-' + index} className="mdx-link" to={linkURL_encoded}
                                      onClick={() => resetFiltersState()}>{displayText}</Link></li>
                        </Fragment>
                    )
                }
            case '31':
                let hasInDepth = get(subscriptionInfo, 'indepthSubscription', 'no');
                if (hasInDepth !== 'yes') {
                    if (isFake) {
                        return;
                    }
                    let target = "_blank";
                    displayText = 'Subscribe for access to In-Depth Answers';
                    const localLinkUrl = clientConfig.subscriptionSupportURL;
                    linkURL_encoded = encodeURI(localLinkUrl);
                    return <Fragment key={'frag-' + index}>
                        <li><a target={target} className="mdx-link" href={linkURL_encoded}>{displayText}</a></li>
                    </Fragment>
                }
                else if (isInDepthExternalNavigation(gateway_login_page)) { ////////////////////////////// URL CONFIG
                    if (isFake) {
                        return;
                    }
                    // Post message to parent window for external navigation
                    const secure_origin = get(gateway_login_page,'mdxAuthenticationJson.Client_Config.secured_origins');

                    const externalNavAction = composeExternalNavigationMessage(
                        {
                            contentSetId,
                            drugName,
                            quickLinkDocumentId,
                            inDepthLinkDocumentId,
                            topicList
                        }
                    )

                    return  <li key={'a-'+index}>
                        <a
                            target='_blank'
                            className="mdx-link"
                            onClick={evt => onLaunchExternalNavigation(externalNavAction, secure_origin, evt) }
                            href='/inDepth'>{displayText}</a>
                    </li>
                } else {
                    // navigate internally to in depth page ...
                    // preparing the cache for in-depth answers while building the link
                    let inDepthInfo = {
                        documentId : documentIds[0],
                        contentSetId : contentSetId,
                        topiclist : topicList,
                        drugname : drugName,
                        username: '',
                        password: '',
                        detailsQuickLinksSection : quickLinkDocumentId
                    };
                    if (!shouldSkipInDepthCall) {
                        onInDepthLinkForm(inDepthInfo, props);
                    }
                    if (isFake) {
                        return;
                    }
                    linkUrl = `/inDepth?contentSetId=${contentSetId}&drugName=${encodeURIComponent(drugName)}&documentId=${documentIds[0]}&topicId=${topicList}&quickLinkDocumentId=${quickLinkDocumentId}`;
                    linkURL_encoded = encodeURI(linkUrl)
                    return (
                        <Fragment key={'frag-' + index}>
                            <li>
                                <Link
                                key={'a-' + index}
                                className="mdx-link"
                                to={linkURL_encoded}
                                onClick={()=> {onClearSearchTerm()}}>
                                {displayText}
                                </Link>
                            </li>
                        </Fragment>
                    )
                } //endelse isInDepthExternalNavigation()

            case '100':
            default:
                if (isDrugPointsExternalNavigation(gateway_login_page)) { ////////////////////////////// URL CONFIG
                    if (isFake) {
                        return;
                    }
                    // Post message to parent window for external navigation
                    const secure_origin = get(gateway_login_page,'mdxAuthenticationJson.Client_Config.secured_origins');
                    const externalNavAction = composeExternalNavigationMessage(
                        {
                            contentSetId,
                            drugName,
                            quickLinkDocumentId,
                            inDepthLinkDocumentId,
                            topicList
                        }
                    )

                    return  <li key={'a-'+index}>
                        <a
                            target='_blank'
                            className="mdx-link"
                            onClick={evt => onLaunchExternalNavigation(externalNavAction, secure_origin, evt) }
                            href='/drugPoints'>{displayText}</a>
                    </li>
                } else {
                    let quickAnsInfo = {
                        documentId: documentIds[0],
                        contentSetId: contentSetId,
                        topiclist: topicList,
                        drugname: drugName,
                        username: '',
                        password: '',
                        detailsInDepthLinksSection: inDepthLinkDocumentId
                    }
                    onQuickAnsLinkForm(quickAnsInfo, props);
                    if (isFake) {
                        return;
                    }
                    linkUrl = `/drugPoints?contentSetId=${contentSetId}&drugName=${encodeURIComponent(drugName)}&documentId=${documentIds[0]}&topicId=${topicList}&inDepthLinkDocumentId=${inDepthLinkDocumentId}`;
                    linkURL_encoded = encodeURI(linkUrl)
                    return (
                        <Fragment key={'frag-' + index}>
                            <li>
                                <Link
                                key={'a-' + index}
                                className="mdx-link"
                                to={linkURL_encoded}
                                onClick={()=> {onClearSearchTerm()}}>
                                {displayText}
                                </Link>
                            </li>
                        </Fragment>
                    )
                } //endelse isDrugPointsExternalNavigation()
        } //endswitch
    } //endelse overrideURL !== null && overrideURL !== undefined
}

function getQuickLinkDocumentId(links={}){
    let quickLinkDocumentId = '';
    let inDepthLinkDocumentId='';
    $.each(links, function (index, element) {
         if(element.contentSetId === '100'){
           quickLinkDocumentId = element.documentIds && element.documentIds.length >0  ? element.documentIds[0] : '';
         }else if(element.contentSetId === '31'){
           inDepthLinkDocumentId = element.documentIds && element.documentIds.length >0  ? element.documentIds[0] : '';
         }
     })
     return {
         quickLinkDocumentId : quickLinkDocumentId,
         inDepthLinkDocumentId: inDepthLinkDocumentId
     }
 }
/**
 * Render a single conversational user turn which consists of a [user_input, chatbot_response] pair
 * @param user_input - the user's input string
 * @param chatbot_response - chatbot's response HTML
 * @param links - an array of quick links
 * @returns {*} - a react component for this conversation turn
 */
export default function RenderTurn(
    {
        user_input="",
        chatbot_response="",
        links=[],
        isLastTurn=false,
        subscriptionInfo={},
        clientConfig={},
        credential={},
        onInDepthLinkForm = null,
        onQuickAnsLinkForm,
        onLaunchExternalNavigation,
        gateway_login_page={},
        onDisplayDrugInteractionsWithDefaultState = f => f,
        props,
        onClearSearchTerm = f => f
    }
    ) {

   if(isLastTurn) {
        console.debug(isLastTurn);
    }

    // render any user input string ...
    let user_html = ""
    if (user_input) {
        if(isLastTurn) {
            user_html = <div key="t1" className="userInputDisplay" id="lastTurnScrollMarker">{user_input}</div>
        } else
            user_html = <div key="t1" className="userInputDisplay">{user_input}</div>
    }

    // render any chatbot response HTML ....
    let chatbot_html = "";
    if(chatbot_response.indexOf('help</a>') > -1 && chatbot_response.slice(-1)==='.'){
        chatbot_response = chatbot_response.slice(0, -1);
    }
    if (chatbot_response) {
        if(chatbot_response.indexOf(".... waiting ...") === -1) {
          chatbot_html = <div key="t2" className="chatbotResponseDisplay highlightResponse" dangerouslySetInnerHTML={{__html: chatbot_response}}/>
        } else {
          chatbot_html =
            <div key="t2" className="chatbotResponseDisplay">
              <InlineLoading className='chatbot-loading'
                             description={props.chatbotPageResponseLoading.description}
                             status={props.chatbotPageResponseLoading.status} />
            </div>
        }
    }

    // render any quick links ...
    let mdx_links = "";
    if (links && links.length > 0) {
        let quickLinksSections = getQuickLinkDocumentId(links);
        console.debug('quickLinksSections ##################: ', quickLinksSections);
        if (links.length === 2) {
            if (links[0].contentSetId === '100' && links[1].contentSetId === '31') {
                links[1].shouldSkipInDepthCall = true
            } else if (links[0].contentSetId === '31' && links[1].contentSetId === '100') {
                links[0].shouldSkipInDepthCall = true
            }
        }
        mdx_links = <ul key="t3" className="ul-mdx-link">
            {links.map((element, index) => composeQuickLink(
                element,
                index,
                subscriptionInfo,
                clientConfig,
                credential,
                onInDepthLinkForm,
                quickLinksSections.quickLinkDocumentId,
                onQuickAnsLinkForm,
                quickLinksSections.inDepthLinkDocumentId,
                onLaunchExternalNavigation,
                gateway_login_page,
                onDisplayDrugInteractionsWithDefaultState,
                props,
                onClearSearchTerm)
            )}
        </ul>
    }

    return <div key={nextKey()} className="mdx-turn-group">
        {user_html}
        {chatbot_html}
        {mdx_links}
    </div>;
}
