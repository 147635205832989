/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */


import { get, getArray} from "../../../../utils";

export function generate_drug_id_lookup(iv_response_json={}) {
    let drug_id_lookup = {}
    const drug_list = get(iv_response_json, 'syringe.formDisplay',[])
    for (let i=0;i<drug_list.length;i++) {
        const { name, productDocId} = drug_list[i]
        if (productDocId!==undefined) {
            drug_id_lookup[name] = productDocId;
        }
    }
    return drug_id_lookup
}


export function generate_solution_id_lookup(iv_response_json = {}) {
    let solution_id_lookup = {};
    const top_level_solution_list = getArray(iv_response_json, 'solution', []);
    for (let i = 0; i < top_level_solution_list.length; i++) {
        const drug_solution_item = top_level_solution_list[i];
        getSolutionsFromList(drug_solution_item, 'ivCompatibility.solutionList.commonSolutions', solution_id_lookup);
        getSolutionsFromList(drug_solution_item, 'ivCompatibility.solutionList.otherSolutions', solution_id_lookup);
        getSolutionsFromList(drug_solution_item, 'ivCompatibility.solutionList.searchedSolutions', solution_id_lookup);
    }
    return solution_id_lookup;
}

const getSolutionsFromList = (drug_solution_item, solution_array_path, solution_id_lookup) => {
    const solutions_array = getArray(drug_solution_item, solution_array_path);
    for (let index = 0; index < solutions_array.length; index++) {
        const { solution: { name, moreSolutionDocumentId }} = solutions_array[index];
        if (moreSolutionDocumentId) {
            solution_id_lookup[name] = moreSolutionDocumentId;
        }
    }
}