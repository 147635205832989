/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {
    useState,
    useEffect
} from "react"
import {
    toggleFlag,
    ALL_FLAGS_OFF,
    initializeFlags,
    testFlag,
    countFlags
} from "./flag-set-utils";

import { getArray} from "../../utils";
import {Checkbox} from "@carbon/react"
import { CheckboxComponent } from "./CheckboxComponent";

// Define one checkbox filter group with optional title,
// a summary checkbox, and children checkboxes
// A checkbox filter panel will have one or more of these checkbox filter groups
export const INITIAL_CHECKBOX_GROUP_STATE= {
    group_title: '',    // optional
    summary_title: '',  // required
    checkbox_items: [], // required; array of strings; may be empty
    min_checked: 0      // optional; defaults to 0
}

/**
 * A custom Hook that keeps track of the state of each of the children checkboxes
 * in a single checkbox filter group.
 *
 * @param initial_checkbox_flags the initial checked/unchecked state of each child checkbox;
 * defaults to all checkboxes checked
 * @param checkbox_count number of children checkboxes in the checkbox filter group
 * @param update_checkbox_panel callback to the checkbox filter panel in order for it to
 * track the dynamic state of this checkbox filter group
 * @returns {{set_checkbox_flags: (value: unknown) => void, check_all_checkboxes: check_all_checkboxes, toggle_checkbox: toggle_checkbox, uncheck_all_checkboxes: uncheck_all_checkboxes, new_checkbox_flags: unknown}}
 */
export const useCheckboxGroupHook = (
    initial_checkbox_flags , // if undefined, then all checkboxes enabled
    checkbox_count=0, // number of children checkboxes
    update_checkbox_panel=f=>f  // callback that allows parent container to track this group's dynamic state
) => {
    let temp_initial_checkbox_flags = initial_checkbox_flags
    // if initial_checkox_flags is undefined
    if (temp_initial_checkbox_flags===undefined) {
        // then use all checkboxes enabled ...
        temp_initial_checkbox_flags = initializeFlags(checkbox_count)
    }

    // new_checkbox_flags is an integer; temp_initial_checkbox_flags is also an integer
    // see useState() docs: https://reactjs.org/docs/hooks-reference.html#usestate
    const [new_checkbox_flags, set_checkbox_flags] = useState(temp_initial_checkbox_flags)

    /**
     * Update all of the child checkbox state flags
     * @param updated_flags integer with flag bits for each child checkbox
     */
    const update_checkbox_flags = updated_flags => {
        // update this hook's internal state
        // ... will trigger a checkbox filter group re-render if updated_flags!==new_checkbox_flags
        set_checkbox_flags(updated_flags)

        // notify the checkbox filter panel parent that this group's state has changed
        update_checkbox_panel(updated_flags)
    }

    /**
     * Toggle the state of an individual child checkbox at a given index
     * @param index identifies the child checkbox being checked/unchecked
     */
    const toggle_checkbox = (index=0) => {
        update_checkbox_flags(toggleFlag(new_checkbox_flags, index))
    }

    /**
     * Check all of the child checkboxes
     */
    const check_all_checkboxes = () => {
        update_checkbox_flags(initializeFlags(checkbox_count))
    }

    /**
     * Uncheck all child checkboxes
     */
    const uncheck_all_checkboxes = () => {
        update_checkbox_flags(ALL_FLAGS_OFF)
    }

    return {
        // integer; updated child checkbox checked/unchecked state
        new_checkbox_flags,

        // functions used by the checkbox filter group to update the group state
        toggle_checkbox,
        check_all_checkboxes,
        uncheck_all_checkboxes,
        set_checkbox_flags
    }
}

/**
 * Error message displayed when the minimum number of required child checkboxes are not checked
 * @param min_checked
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxGroupError = ({min_checked}) => <h4 className='checkbox-filter-group-error-message'>
    {`Please select at least ${min_checked} items.`}
</h4>

/**
 * A stateless presentation component that renders a checkbox filter group
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxFilterGroupPure = props => {
    // destructure variables from props object
    const  {
        checkbox_group_state = INITIAL_CHECKBOX_GROUP_STATE, // definition of checkbox filter group; see
        checkbox_group_index=999, // index of this checkbox filter group within parent checkbox filter panel

        // from the useCheckboxGroupHook() return object
        new_checkbox_flags,
        toggle_checkbox,
        check_all_checkboxes,
        uncheck_all_checkboxes,
        show_min_checked_error_message = false
    } = props

    // destructure variables from the checkbox group state
    const {
        group_label,   // optional
        summary_title, // required
        checkbox_items = [], // required, but may be empty
    } = checkbox_group_state

    // number of child checkboxes
    const max_checkbox_count = checkbox_items.length

    const min_checked = checkbox_group_state.min_checked
    const actual_checked_count = countFlags(new_checkbox_flags)
    const error_flag = actual_checked_count < min_checked
    // are all child checkboxes checked?
    const all_checked_state = new_checkbox_flags===initializeFlags(max_checkbox_count)
    // are all child checkboxes unchecked?
    const all_unchecked_state = new_checkbox_flags===ALL_FLAGS_OFF
    // are there a mix of checked and unchecked checkboxes; if so, the summary checkbox is in the "indeterminate" state
    const indeterminate_checked_state = !(all_checked_state | all_unchecked_state)

    // onClick handler for summary checkbox
    const toggle_summary_checkbox = () => {
        if (all_checked_state) {
            uncheck_all_checkboxes()
        } else {
            check_all_checkboxes()
        }
    }

    const checkbox_group_id = `checkbox-${checkbox_group_index}`

    // render the checkbox filter group
    return <div className="checkbox-group-top-container">
        {
            group_label && <div className="checkbox-filter-group-title">{group_label}</div>
        }
        {
            show_min_checked_error_message && error_flag && <CheckboxGroupError min_checked={min_checked} />
        }
        <CheckboxComponent
            id={checkbox_group_id}
            labelText={summary_title}
            onClick={toggle_summary_checkbox}
            indeterminate={indeterminate_checked_state}
            checked={all_checked_state}
        />
        <div className="checkbox-group-inner-container" >
            {
                checkbox_items.map((checkbox_label, checkbox_index) => {
                    const checkbox_id = `${checkbox_group_id}-${checkbox_index}`
                    return <Checkbox
                        id={checkbox_id}
                        key={checkbox_id}
                        labelText={getCheckboxLabel(checkbox_label)}
                        checked={testFlag(new_checkbox_flags, checkbox_index)}
                        onClick={() => toggle_checkbox(checkbox_index)}
                    />
                })
            }
        </div>
    </div>
}

const checkboxImages = new Map([
    ['Compatible', 'images/iv_compat_compatible.png'],
    ['Caution:Variable', 'images/iv_compat_cautionvariable.png'],
    ['Uncertain', 'images/iv_compat_uncertain.png'],
    ['Incompatible', 'images/iv_compat_incompatible.png'],
    ['Not Tested', 'images/iv_compat_nottested.png']
]);

const getCheckboxLabel = (checkbox_label) => {
    let image_src = '';
    for (const [key, value] of checkboxImages.entries()) {
        if (checkbox_label === key) {
            image_src = value;
        }
    }
    return image_src.length > 0
        ? <div className='checkbox-label-with-image'>
            <img className='checkbox-label-image' src={image_src} alt=''/>
            {checkbox_label}
        </div>
        : checkbox_label
}

/**
 * A stateful checkbox filter group; uses the useCheckboxGroupHook() custom
 * hook to manage the state of the children checkboxes
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxFilterGroup = props => {
    // destructure props
    const {
        checkbox_group_state = {},
        update_checkbox_panel_fn = f => f,
        initial_checkbox_flags, // optional, if undefined, then all checkboxes will be selected
        checkbox_group_index=888
    } = props

    const checkbox_items = getArray(checkbox_group_state, 'checkbox_items')
    const checkbox_count = checkbox_items.length

    // invoke custom hook that handles checkbox state changes ...
    const checkbox_group_hook = useCheckboxGroupHook(
            initial_checkbox_flags,
            checkbox_count,
            update_checkbox_panel_fn
    )

    // monitor changes to the props.initial_checkout_flags value; whenever the value changes,
    // update the custom hook's current checkbox state flags
    // see useEffect() docs: https://reactjs.org/docs/hooks-reference.html#useeffect
    useEffect(() => {
        checkbox_group_hook.set_checkbox_flags(initial_checkbox_flags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initial_checkbox_flags])

    // render the checkbox filter group using the stateless presentation component
    return <CheckboxFilterGroupPure
        checkbox_group_state={checkbox_group_state}
        checkbox_group_index={checkbox_group_index}
        {...checkbox_group_hook}
    />
}
